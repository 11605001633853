import { RiskLevel } from '../../declarations/common/enums/RiskLevel';
import { Option } from '../../declarations/common/option';

const RiskLevelOptions: Option<RiskLevel>[] = [
  { value: RiskLevel.HighRisk, label: 'High' },
  { value: RiskLevel.MediumRisk, label: 'Medium' },
  { value: RiskLevel.LowRisk, label: 'Low' },
];

export default RiskLevelOptions;
