import { Box, Tab, Tabs, styled } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type Props = {
  active: '' | 'provider' | 'clinical-review' | 'legal-review';
  prefix: string;
};

const Background = styled('div')`
  position: fixed;
  height: 48px;
  width: 100%;
  background-color: #FFF;
  z-index: 1000;

  &::before {
    content: '';
    position: relative;
    width: 100%;
    height: 24px;
    top: -24px;
    width: 100%;
    display: block;
    left: -1px;
    background-color: #FFF;
  }
`;

const Placeholder = styled('div')`
  height: 48px;
`;

export default function SubmissionTabs({ active, prefix }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const handleChange = (_event: SyntheticEvent, value: string) => {
    navigate({ pathname: `${prefix}/${value}`, search: `id=${id}` });
  };

  return (
    <>
      <Background />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', zIndex: 1000, position: 'fixed' }}>
        <Tabs value={active} aria-label="basic tabs example" onChange={handleChange}>
          <Tab label="User" value="" />
          <Tab label="Provider" value="provider" disabled={!id} />
          <Tab label="Clinical Review" value="clinical-review" disabled={!id} />
          <Tab label="Legal Review" value="legal-review" disabled={!id} />
        </Tabs>
      </Box>
      <Placeholder />
    </>
  );
}
