import { Option } from '@declarations/common/option';

function getOptionLabel<T>(options: Option<T>[], value: T, defaultValue = 'N/A'): string {
  const foundOption = options.find((option) => option.value === value);

  if (!foundOption) {
    return defaultValue;
  }

  return foundOption.label;
}

export default getOptionLabel;
