import React from 'react';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import PageTitle from './PageTitle';

interface Props {
  children: React.ReactNode
  mb?: number
  variant?: Variant
}

function PageSubTitle(props: Props) {
  const { children, mb = 1, variant = 'h5' } = props;

  return (
    <PageTitle variant={variant} mb={mb}>
      {children}
    </PageTitle>
  );
}

export default PageSubTitle;
