import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '@components/page/PageTitle';
import { Box, Grid, Stack } from '@mui/material';
import StatBox from '@components/StatBox';
import MedicalRRService from '@api/MedicalRRService';
import MRRStatusByRiskLevel from '@components/charts/MRRStatusByRiskLevel';
import MRRHighRiskRequesters from '@components/charts/MRRHighRiskRequesters';
import MedicalRiskLevelByMonth from '@components/charts/MRRRiskLevelByMonth';
import MedicalReportsGrid from '../components/MedicalReportsGrid';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { Totals } from '@api/MedicalRRService/getFacilityTotals';
import { MedicalCountsByHighRiskRequesters } from '@api/MedicalRRService/getCountsByHighRiskRequesters';
import { MRRRiskLevelCountsByMonth } from '@api/MedicalRRService/getRiskLevelByMonth';
import { MRRStatusCountsByRiskLevel } from '@api/MedicalRRService/getMRRStatusByRiskLevel';

function FacilityMedicalReports() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();

  const [medicalTotals, setMedicalTotals] =
    useState<Totals>({medical: NaN, incomplete: NaN, highRisk: NaN});
  const fetchMedicalTotals = () => {
    setMedicalTotals({medical: NaN, incomplete: NaN, highRisk: NaN});
    MedicalRRService.getFacilityTotals(facility!.id, selectedYear)
      .then(({data}) => setMedicalTotals(data))
      .catch((e) => {
        console.error(e);
        setMedicalTotals({medical: 0, highRisk: 0, incomplete: 0});
        throw e;
      });
  };


  const [medicalStatusByRiskLevel, setMedicalStatusByRiskLevel] = useState<MRRStatusCountsByRiskLevel[] | null>(null);
  const fetchMedicalStatusByRiskLevel = () => {
    setMedicalStatusByRiskLevel(null);
    MedicalRRService.getStatusByRiskLevel(facility!.id, selectedYear)
      .then(({data}) => {
        setMedicalStatusByRiskLevel(data);
      })
      .catch((e) => {
        setMedicalStatusByRiskLevel([]);
        throw e;
      });
  };


  const [medicalHighRiskRequesters, setMedicalHighRiskRequesters] = useState<MedicalCountsByHighRiskRequesters[] | null>(null);
  const fetchMedicalHighRiskRequesters = () => {
    setMedicalHighRiskRequesters(null);
    MedicalRRService.getCountsByHighRiskRequesters(facility!.id, selectedYear)
      .then(({data}) => {
        setMedicalHighRiskRequesters(data);
      })
      .catch((e) => {
        setMedicalHighRiskRequesters([]);
        throw e;
      });
  };


  const [medicalRiskLevelByMonth, setMedicalRiskLevelByMonth] = useState<MRRRiskLevelCountsByMonth[] | null>(null);
  const fetchMedicalRiskLevelByMonth = () => {
    setMedicalRiskLevelByMonth(null);
    MedicalRRService.getRiskLevelByMonth(facility!.id, selectedYear)
      .then(({data}) => {
        setMedicalRiskLevelByMonth(data);
      })
      .catch((e) => {
        setMedicalRiskLevelByMonth([]);
        throw e;
      });
  };

  const fetchData = async () => {
    fetchMedicalTotals();
    fetchMedicalStatusByRiskLevel();
    fetchMedicalHighRiskRequesters();
    fetchMedicalRiskLevelByMonth();
  };

  useEffect(() => {
    fetchData().catch(console.error);
    setGridFilterItem({
      id: facility?.id,
      value: facility?.name,
      field: 'facility',
      operator: 'equals',
    });
  }, [facility?.id, selectedYear]);

  return (
    <Box component="main">
      <PageTitle>
        {facility?.name}
        : Medical Record Request Reports
      </PageTitle>

      <Stack direction="row" spacing={2} sx={{marginBottom: 5}}>
        <StatBox
          title="Total MRR"
          loading={Number.isNaN(medicalTotals.medical)}
          count={medicalTotals.medical}
        />

        <StatBox
          title="Total Incomplete"
          loading={Number.isNaN(medicalTotals.incomplete)}
          count={medicalTotals.incomplete}
        />

        <StatBox
          title="Total High Risk"
          loading={Number.isNaN(medicalTotals.highRisk)}
          count={medicalTotals.highRisk}
        />
      </Stack>

      <Grid container sx={{marginBottom: 5}} spacing={3}>
        <Grid item xs={12} sm={4}>
          <MRRStatusByRiskLevel data={medicalStatusByRiskLevel}/>
        </Grid>

        <Grid item xs={12} sm={4}>
          <MRRHighRiskRequesters data={medicalHighRiskRequesters}/>
        </Grid>

        <Grid item xs={12} sm={4}>
          <MedicalRiskLevelByMonth data={medicalRiskLevelByMonth}/>
        </Grid>
      </Grid>

      <MedicalReportsGrid medicalsReportGridKey={'facility-medicals'} gridFilterItem={gridFilterItem}/>
    </Box>
  );
}

export default FacilityMedicalReports;
