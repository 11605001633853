import { AxiosInstance } from 'axios';

type Totals = {
  lawsuit: number;
  incomplete: number;
  closed: number;
};

function getFacilityTotals(api: AxiosInstance) {
  return (facilityId: number, yearOfLawsuits?: number) => api.get<Totals>(`/totals/facility/${facilityId}`, {
    params: { year_of_lawsuits: yearOfLawsuits }
  });
}

export default getFacilityTotals;
