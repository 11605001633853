import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { Typography } from '@mui/material';

interface Props {
  to: string
  children: React.ReactNode
}

function GoToLink(props: Props) {
  const { to, children } = props;

  return (
    <div style={{ marginTop: 12 }}>
      <RouterLink to={to} style={{ color: '#1976d2' }}>
        <Typography variant="body1" align="right">
          {children}
        </Typography>
      </RouterLink>
    </div>
  );
}

export default React.memo(GoToLink);
