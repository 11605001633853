import React from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Chart as ChartJS } from 'chart.js';
import _ from 'lodash';

type ChartContainerProps = BoxProps & {
  loading?: boolean;
};

const ChartContainerStyled = styled(Box)(
  ({theme}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: theme.spacing(45),
  }),
);

function ChartContainer({loading, sx, children, ...props}: ChartContainerProps) {
  return (
    <ChartContainerStyled {...props} sx={{ boxShadow: 3, padding: 2 }}>
      {loading ? <CircularProgress/> : children}
    </ChartContainerStyled>
  );
}

export default ChartContainer;
