import { Incident } from '@declarations/models/reports/Incident';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { UserType } from '@declarations/common/enums/UserType';
import getAbuseOrNeglectFields from './abuseOrNeglect';
import getBurnsFields from './burns';
import getChokingFields from './choking';
import getElopementFields from './elopement';
import getEntrapmentFields from './entrapment';
import getFallMajorInjuryFields from './fallMajorInjury';
import getMedicationErrorFields from './medicationError';
import getOtherFields from './other';
import getSelfInflictedInjuryFields from './selfInflictedInjury';
import getUnplannedDeathFields from './unplannedDeath';
import getWoundFields from './wound';

function getFieldsByIncidentType(
  form: UseFormReturn<Incident>,
  mode: FormMode,
  userType: UserType,
) {
  const fields: Fields<Incident> = [
    // ...getFallMajorInjuryFields(),
    // ...getWoundFields(),
    // ...getMedicationErrorFields(),
    // ...getAbuseOrNeglectFields(),
    // ...getElopementFields(),
    // ...getChokingFields(),
    // ...getEntrapmentFields(),
    // ...getBurnsFields(),
    // ...getSelfInflictedInjuryFields(),
    // ...getUnplannedDeathFields(),
    // ...getOtherFields(),
  ];

  const fileFields: Fields<Incident> = [];

  const params = { form, mode, userType };

  const fallMajorInjuryFields = getFallMajorInjuryFields(params);
  fields.push(...fallMajorInjuryFields.fields as any[]);

  const woundFields = getWoundFields(params);
  fields.push(...woundFields.fields as any[]);
  fileFields.push(...woundFields.fileFields as any[]);

  const medicationErrorFields = getMedicationErrorFields(params);
  fields.push(...medicationErrorFields.fields as any[]);
  fileFields.push(...medicationErrorFields.fileFields as any[]);

  const abuseOrNeglectFields = getAbuseOrNeglectFields(params);
  fields.push(...abuseOrNeglectFields.fields as any[]);
  fileFields.push(...abuseOrNeglectFields.fileFields as any[]);

  const elopementFields = getElopementFields(params);
  fields.push(...elopementFields.fields as any[]);
  fileFields.push(...elopementFields.fileFields as any[]);

  const chokingFields = getChokingFields(params);
  fields.push(...chokingFields.fields as any[]);
  fileFields.push(...chokingFields.fileFields as any[]);

  const entrapmentFields = getEntrapmentFields(params);
  fields.push(...entrapmentFields.fields as any[]);
  fileFields.push(...entrapmentFields.fileFields as any[]);

  const burnsFields = getBurnsFields(params);
  fields.push(...burnsFields.fields as any[]);
  fileFields.push(...burnsFields.fileFields as any[]);

  const selfInflictedInjuryFields = getSelfInflictedInjuryFields(params);
  fields.push(...selfInflictedInjuryFields.fields as any[]);
  fileFields.push(...selfInflictedInjuryFields.fileFields as any[]);

  const unplannedDeathFields = getUnplannedDeathFields(params);
  fields.push(...unplannedDeathFields.fields as any[]);
  fileFields.push(...unplannedDeathFields.fileFields as any[]);

  fields.push(...getOtherFields(params) as any[]);

  return { fields, fileFields };
}

export default getFieldsByIncidentType;
