import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { FieldError } from 'react-hook-form';
import { useDisableFormProvider } from '../../providers/DisableFormProvider';

type CheckboxProps = MuiCheckboxProps & {
  label: FormControlLabelProps['label']
  error?: FieldError | boolean
};

function Checkbox(props: CheckboxProps) {
  const { disabled } = useDisableFormProvider();
  const { label, ...restProps } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={<MuiCheckbox {...restProps} />}
        label={label}
        disabled={disabled || restProps.disabled}
      />
    </FormGroup>
  );
}

export default React.memo(Checkbox);
