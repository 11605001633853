import _ from 'lodash';

const calculateAxisMaxValue = (data: { [key: string]: number | any }[], key: string): number => {
  return data.reduce((total: number, current): number => {
    const _currentSum: number = _.sum(Object.values(current[key]));
    if (_currentSum > total) {
      return _currentSum;
    }
    return total;
  }, 0);
}

export default calculateAxisMaxValue;