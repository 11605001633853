/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';
import { UserType } from '@declarations/common/enums/UserType';
import tryParseJSONObject from '@utils/tryParseJSONObject';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';

export interface UserState {
  id: number;
  firstname: string;
  lastname: string;
  position: string;
  organizations: OrganizationModel[];
  facilities: FacilityModel[];
  email: string;
  contactNumber: string;
  isAuthorized: boolean;
  type: UserType;
  expertRole?: ExpertRole;
}

type OrganizationModel = {
  id: number;
  name: string;
};

type FacilityModel = {
  id: number;
  name: string;
  organizationId: number;
};

function getUserFromStorage(): UserState | null {
  return tryParseJSONObject(localStorage.getItem('user'));
}

const initialState: UserState = {
  id: 0,
  firstname: '',
  lastname: '',
  position: '',
  organizations: [],
  facilities: [],
  email: '',
  contactNumber: '',
  type: UserType.General,
  isAuthorized: !!localStorage.getItem('accessToken'),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state: UserState, action: PayloadAction<Partial<UserState>>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        if ((state as any)[key] !== value) {
          (state as any)[key] = value;
        }
      });

      localStorage.setItem('user', JSON.stringify(state));
    },

    setIsAuthorized(state, action: PayloadAction<boolean>) {
      state.isAuthorized = action.payload;
    },

    logout(state) {
      state.id = 0;
      state.firstname = '';
      state.lastname = '';
      state.position = '';
      state.organizations = [];
      state.facilities = [];
      state.email = '';
      state.contactNumber = '';
      state.type = UserType.General;
      localStorage.removeItem('user');
    },
  },
});

export const UserActions = userSlice.actions;

const UserSelector = (state: RootState): UserState => state.user;
// const UserSelector = (state: RootState): UserState => ({...state.user, type: UserType.External});

export const UserSelectors = {
  user: createSelector(UserSelector, (user) => user),
  type: createSelector(UserSelector, (user) => user.type),
  isAuthorized: createSelector(UserSelector, (user) => user.isAuthorized),
  facilities: createSelector(UserSelector, (user) => user.facilities),
};

export default userSlice.reducer;
