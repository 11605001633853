import React, { useEffect, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartData } from 'chart.js/dist/types';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import { FacilitiesMMRCountsByRequester } from '@api/MedicalRRService/getFacilitiesCountsByRequester';
import {
  RequestingPersonColors,
  RequestingPersonOptions,
} from '@constants/options/RequestingPersonOptions';
import getFacilityLabels from '@utils/getFacilityLabels';

type MRRsCountByTypeProps = {
  data: FacilitiesMMRCountsByRequester[] | null;
};

const options = (maxXValue: number): ChartProps<'bar'>['options'] => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(maxXValue),
    },
    y: {
      ticks: {autoSkip: false, padding: 10},
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'MRR Requested By',
      font: {
        size: 20,
      },
    },
  },
});

function MRRsCountByType({data}: MRRsCountByTypeProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: RequestingPersonOptions.map((mrrType) => ({
          label: mrrType.label,
          data: data.map(d => {
            const reportsByStatus = d.reports.find(r => r.requester === mrrType.value);
            return reportsByStatus?.count || 0;
          }),
          backgroundColor: RequestingPersonColors[mrrType.value],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);
  
  const flattenSum = data?.map(d => _.sumBy(d.reports, 'count'));
  const maxXValue: number = _.max(flattenSum) || 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxXValue)}/>
    </ChartContainer>
  );
}

export default React.memo(MRRsCountByType);
