import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import isHighRisk from '@utils/isHighRisk';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import { yesNoOptions } from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.Elopement], undefined>;

function getElopementFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = <T extends keyof RequiredFields>(field: T) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.Elopement}.${field}` as T;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.Elopement;
    }

    return form.watch('typeOfIncident') === IncidentType.Elopement;
  };

  return {
    fields: [
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('isTheFacilityEquippedWithDoorAlarms'),
          label: 'Is the facility equipped with door alarms or door security meant to prevent elopement?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didResidentHaveHistoryOfExitSeekingBehavior'),
          label: 'Did the resident have a history of exit seeking behavior?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasTherePlanOfCareFollowedWithExitSeeking'),
          label: 'Was there a plan of care followed with exit seeking interventions or safety protocols in place?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            result = result && conditionParams.form.watch(getFieldName('didResidentHaveHistoryOfExitSeekingBehavior')) === true;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didElopementRequireHospitalization'),
          label: 'Did the elopement require hospitalization?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const didResidentHaveHistoryOfExitSeekingBehavior = conditionParams.form.watch(
              getFieldName('didResidentHaveHistoryOfExitSeekingBehavior'),
            );

            result = result && (
              didResidentHaveHistoryOfExitSeekingBehavior === true
              || didResidentHaveHistoryOfExitSeekingBehavior === false
            );

            if (didResidentHaveHistoryOfExitSeekingBehavior === true) {
              result = result && conditionParams.form.watch(getFieldName('wasTherePlanOfCareFollowedWithExitSeeking')) === true;
            }

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didElopementResultInPermanentInjuryOrDeath'),
          label: 'Did the elopement result in permanent injury or death?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            result = result && conditionParams.form.watch(getFieldName('didResidentHaveHistoryOfExitSeekingBehavior')) === true;

            const wasTherePlanOfCareFollowedWithExitSeeking = conditionParams.form.watch(
              getFieldName('wasTherePlanOfCareFollowedWithExitSeeking'),
            );

            result = result && (
              wasTherePlanOfCareFollowedWithExitSeeking === true
              || wasTherePlanOfCareFollowedWithExitSeeking === false
            );

            if (wasTherePlanOfCareFollowedWithExitSeeking) {
              result = result && conditionParams.form.watch(getFieldName('didElopementRequireHospitalization')) === true;
            }

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
    ],
    fileFields: [
      [
        {
          type: 'file',
          required: false,
          name: getFieldName('bimsFiles'),
          label: 'BIMS',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
    ],
  };
}

export default getElopementFields;
