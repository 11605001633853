import { ListWithPagination, PaginationParams } from '@declarations/common/pagination';
import { Organization } from '@declarations/models/Organization';
import initInstance from './axios';
import { FetchListParams } from '@declarations/common/fetchListParams';

const api = initInstance('/organization');

const paths = {
  upsert: '/',
  getById: (id: number) => `/${id}`,
  list: '/list',
  remove: '/remove',
  getNames: '/names',
  listCSV: '/list/csv',
};

const OrganizationService = {
  upsert: async (organization: Organization | FormData) => api.post(paths.upsert, organization),
  getById: async (id: number) => api.get(paths.getById(id)),
  list: async (params: PaginationParams) => api.post<ListWithPagination<Organization>>(paths.list, params),
  remove: async (ids: number[]) => api.post(paths.remove, ids),
  getNames: async () => api.get<{ id: number, name: string }[]>(paths.getNames),
  exportCSV: async (params: Omit<FetchListParams, 'pagination'>) => api.post<File>(paths.listCSV, params),
};

export default OrganizationService;
