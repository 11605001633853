import { BaseReport } from '@declarations/models/reports/base';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Button, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import FileList from '@components/filesUpload/FileList';

const attachmentsRender = ({ value }: GridRenderCellParams<BaseReport>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!value?.length) {
    return null;
  }

  const files = value.map((f: any) => ({ ...f, name: f.filename }));

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        <DownloadIcon /> Download
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <FileList disabled={false} files={files} removeFile={fileName => console.log(fileName)} />
        </Typography>
      </Popover>
    </div>
  );
};

export default attachmentsRender;
