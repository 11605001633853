import { BaseReport } from '@declarations/models/reports/base';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { UserType } from '@declarations/common/enums/UserType';
import React from 'react';
import SingleDropDownContainer from '@components/DataGrid/renders/singleDropDownRender';

const expertUserRender = (inlineEditEnabled: boolean) => (params: GridRenderCellParams<BaseReport>) => {
  // return params.value && JSON.parse(params.value)?.name;
  const value = params.value && JSON.parse(params.value)?.name;

  return inlineEditEnabled ?
    <SingleDropDownContainer displayValue={<span>{value}</span>}></SingleDropDownContainer> :
    <span>{value}</span>;
};

export default expertUserRender;