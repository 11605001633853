import { BaseReport } from "@declarations/models/reports/base";
import { AxiosResponse } from "axios";

export default function setUploadedFiles<T extends BaseReport>(res: AxiosResponse<T>): AxiosResponse<T> {
  const { data } = res;

  for (const file of data.files) {
    let pathItems = file.type.split('.');
    const field = pathItems.reduce((prevValue: any, pathItem: string) => prevValue[pathItem], data);
    if (Array.isArray(field)) {
      field.push({ hash: file.file.hash, name: file.file.filename });
    } else {
      const lastItem = pathItems.pop() as string;
      const parentField = pathItems.reduce((prevValue: any, pathItem: string) => prevValue[pathItem], data);
      parentField[lastItem] = [{ hash: file.file.hash, name: file.file.filename }];
    }
  }

  return res;
}