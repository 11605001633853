import { useState } from 'react';
import { usePaginationModel } from '@components/DataGrid/hooks/usePaginationModel';
import { useSelectionModel } from '@components/DataGrid/hooks/useSelectionModel';
import { useFilterModel } from '@components/DataGrid/hooks/useFilterModel';
import { useSortModel } from '@components/DataGrid/hooks/useSortModel';
import { FetchListParams } from '@declarations/common/fetchListParams';
import { DefaultRowsPerPageOptions } from '../../../consts';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';

export const useGridModels = (sort?: GridSortModel, pagination?: GridPaginationModel) => {
  const paginationModel = usePaginationModel(pagination || { pageSize: DefaultRowsPerPageOptions[0] });
  const selectionModel = useSelectionModel();
  const filterModel = useFilterModel();
  const sortModel = useSortModel(sort);
  const [rowCount, setRowCount] = useState<number>(0);
  const [stateLoaded, setStateLoaded] = useState<boolean>(false);

  const getFetchListParams = (): FetchListParams => {
    const pagination: FetchListParams['pagination'] = {
      skip: paginationModel.paginationModel.pageSize * paginationModel.paginationModel.page,
      take: paginationModel.paginationModel.pageSize,
    };
    const filter = filterModel.filterModel;
    const order = sortModel.sortModel as FetchListParams['order'];

    return {
      pagination,
      filter,
      order,
    };
  };

  return {
    ...paginationModel,
    ...selectionModel,
    ...filterModel,
    ...sortModel,

    rowCount,
    setRowCount,

    stateLoaded,
    setStateLoaded,

    getFetchListParams,
  };
};
