import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';

type RequiredFields = Exclude<IncidentTypes[IncidentType.Other], undefined>;

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

function getOtherFields(params: Params): Fields<RequiredFields> {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;
  const getFieldName = (field: keyof RequiredFields) => `${IncidentType.Other}.${field}`;
  const makeCondition = ({ form }: any) => form.watch('typeOfIncident') === IncidentType.Other;

  return [
    [
      {
        type: 'text',
        fullWidth: true,
        name: getFieldName('narrativeDescription'),
        label: 'Narrative description of event',
        condition: makeCondition,
        props: {
          multiline: true,
          disabled: disabledField,
        }
      }
    ]
  ];
}

export default getOtherFields;
