import { useState } from 'react';
import { DataGridProProps, GridSortModel } from '@mui/x-data-grid-pro';

type UseSortModelReturnType = Pick<DataGridProProps, 'sortModel' | 'onSortModelChange'>;

export function useSortModel(initialState?: GridSortModel): UseSortModelReturnType {
  const [sortModel, setSortModel] = useState<GridSortModel>(initialState ?? []);

  return {
    sortModel,
    onSortModelChange: (sort: GridSortModel) => setSortModel(sort),
  };
}
