import { FormField } from '@components/CRUDForm/declarations/FormField';
import { styled, useTheme } from '@mui/system';
import { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileList from './FileList';
import { Typography } from '@mui/material';

type Props<FormValues extends FieldValues = any> = {
  field: FormField<FormValues>;
  form: UseFormReturn<FormValues>;
  maxFiles?: number;
  disabled?: boolean;
  text?: string;
};

const applicationAccept = {
  'application/x-abiword': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.oasis.opendocument.presentation': [],
  'application/vnd.oasis.opendocument.spreadsheet': [],
  'application/vnd.oasis.opendocument.text': [],
  'application/pdf': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'application/rtf': [],
  'application/xhtml+xml': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/xml': [],
};

const Container = styled('div')`
  ${({ theme, isDragActive, disabled }: any) => `
    padding: 8px;
    border: 2px dashed ${isDragActive ? theme.palette.grey[900] : theme.palette.grey[500]};
    color: ${isDragActive ? theme.palette.grey[900] : theme.palette.grey[500]};
    background-color: ${isDragActive ? theme.palette.grey[100] : 'none'};
    max-width: 600px;
    margin: 8px 0;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    ${disabled ? 'pointer-events: none; opacity: 0.5;' : ''}
  `}
`;

export default function Dropzone({ field, form, maxFiles, disabled, text }: Props) {
  const theme = useTheme();
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (disabled) {
      return;
    }
    const selectedFiles = form.watch(field.name as string) || [];
    if (maxFiles === 1 && selectedFiles?.length > 0) {
      return;
    }
    form.setValue(field.name as string, [...selectedFiles, ...acceptedFiles]);
    // TODO: display fileRejections
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 50 * 1024 * 1024,
    maxFiles,
    accept: {
      ...applicationAccept,
      'text/plain': [],
      'image/*': [],
    }
  });

  const removeFile = (filename: string) => {
    form.setValue(
      field.name as any,
      (form.watch(field.name as any) as any).filter((file: File) => file.name !== filename),
    );
  };

  const files = form.watch(field.name as string);
  const fileLabel = maxFiles === 1 ? 'file' : 'files';

  return (
    <div>
      <label htmlFor={field.name} style={{ color: disabled ? theme.palette.grey[500] : 'inherit' }}>
        {field.label}
      </label>
      <Container {...getRootProps({ isDragActive, disabled })}>
        <input {...getInputProps({ id: field.name, disabled })} />
        {isDragActive ? (
          <p>Drop the {fileLabel} here ...</p>
        ) : (
          <p>
            {text || `Drag and drop some ${fileLabel} here, or click to select ${fileLabel}`}
          </p>
        )}
        <CloudUploadIcon />
      </Container>
      {!files?.length ? (
        <Typography variant="body1" color="gray" align="left">
          No {fileLabel} selected.
        </Typography>
      ) : (
        <FileList disabled={!!disabled} files={files} removeFile={removeFile} />
      )}
    </div>
  );
}
