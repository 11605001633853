import { createTheme } from '@mui/material/styles';
import { presetPalettes } from '@ant-design/colors';
import { getGreyPalette } from './getGreyPalette';

export const getPalette = () => {
  const colors = presetPalettes;

  const greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000',
  ];
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  const greyConstant = ['#fafafb', '#e6ebf1'];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const greyPalette = getGreyPalette(colors);

  return createTheme({
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      text: {
        primary: greyPalette[700],
        secondary: greyPalette[500],
        disabled: greyPalette[400],
      },
      action: {
        disabled: greyPalette[300],
      },
    },
  });
};
