import { AxiosInstance } from 'axios';
import { LawsuitStatus } from '@declarations/common/enums/LawsuitStatus';

export type LawsuitsCountsByLawsuitStatus = {
  status: LawsuitStatus; // 0-11
  count: number;
};

export default function getCountsByLawsuitStatus(api: AxiosInstance) {
  return (facilityId: number, yearOfLawsuits?: number) => api.get<LawsuitsCountsByLawsuitStatus[]>('/counts-by-lawsuit-status', {
    params: { facility_id: facilityId, year_of_lawsuits: yearOfLawsuits },
  });
}
