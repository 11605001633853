import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Stack,
} from '@mui/material';
import PageTitle from '@components/page/PageTitle';
import StatBox from '@components/StatBox';
import IncidentsByStatus from '@components/charts/IncidentsByTypeAndStatus';
import IncidentService from '@api/IncidentService';
import LawsuitService from '@api/LawsuitService';
import LawsuitsByMonth from '@components/charts/LawsuitsByMonth';
import MedicalRRService from '@api/MedicalRRService';
import MRRRiskLevelByRequester from '@components/charts/MRRRiskLevelByRequester';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import ReportService from '@api/ReportService';
import { Link } from 'react-router-dom';
import routes from '@constants/routes';
import PageSubTitle from '@components/page/PageSubTitle';
import { ReportTypeEnum } from '@declarations/common/reportType';
import GoToLink from '@components/GoToLink';
import ReportsGrid from '../components/ReportsGrid';
import incidentsColumns from '../../IncidentReports/components/IncidentReportsGrid/columns';
import medicalRRColumns from '../../MedicalRRRecords/components/MedicalReportsGrid/columns';
import lawsuitColumns from '../../LawsuitReports/components/LawsuitReportsGrid/columns';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { Totals } from '@api/ReportService/getFacilityTotals';
import { IncidentByTypeAndStatus } from '@api/IncidentService/getFacilityIncidentsByTypeAndStatus';
import { LawsuitsCountsByMonth } from '@api/LawsuitService/getCountsByMonth';
import { MRRRiskLevelCountsByRequester } from '@api/MedicalRRService/getMRRRiskLevelByRequester';

const facilityTotalsDefault = {
  incident: NaN,
  incidentHighRisk: NaN,
  medical: NaN,
  medicalHighRisk: NaN,
  lawsuit: NaN,
  lawsuitOpen: NaN,
};

function DashboardFacility() {
  const userType = useSelector(UserSelectors.type);
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();

  const [facilityTotals, setFacilityTotals] = useState<Totals>(facilityTotalsDefault);
  const fetchFacilityTotals = () => {
    setFacilityTotals(facilityTotalsDefault);
    ReportService.getFacilityTotals(facility!.id, selectedYear).then(({data}) => setFacilityTotals(data))
      .catch((e) => {
        setFacilityTotals({
          incident: 0,
          incidentHighRisk: 0,
          medical: 0,
          medicalHighRisk: 0,
          lawsuit: 0,
          lawsuitOpen: 0,
        });
        throw e;
      });
  };

  const [incidentsByTypeAndStatus, setIncidentsByTypeAndStatus] = useState<IncidentByTypeAndStatus[] | null>(null);
  const fetchIncidentsByTypeAndStatus = () => {
    setIncidentsByTypeAndStatus(null);
    IncidentService.getFacilityIncidentsByTypeAndStatus(facility!.id, undefined, selectedYear)
      .then(({data}) => {
        setIncidentsByTypeAndStatus(data);
      })
      .catch((e) => {
        setIncidentsByTypeAndStatus([]);
        throw e;
      });
  };

  const [lawsuitCountsByMonth, setLawsuitCountsByMonth] = useState<LawsuitsCountsByMonth[] | null>(null);
  const fetchLawsuitCountsByMonth = () => {
    setLawsuitCountsByMonth(null);
    LawsuitService.countsByMonth(facility!.id, selectedYear)
      .then(({data}) => {
        setLawsuitCountsByMonth(data);
      })
      .catch((e) => {
        setLawsuitCountsByMonth([]);
        throw e;
      });
  };

  const [medicalRiskLevelsByRequester, setMedicalRiskLevelsByRequester] = useState<MRRRiskLevelCountsByRequester[] | null>(null);
  const fetchMedicalRiskLevelsByRequester = () => {
    setMedicalRiskLevelsByRequester(null);
    MedicalRRService.getRiskLevelByRequester(facility!.id, selectedYear)
      .then(({data}) => {
        setMedicalRiskLevelsByRequester(data);
      })
      .catch((e) => {
        setMedicalRiskLevelsByRequester([]);
        throw e;
      });
  };

  const fetchData = async () => {
    fetchFacilityTotals();
    fetchIncidentsByTypeAndStatus();
    fetchLawsuitCountsByMonth();
    fetchMedicalRiskLevelsByRequester();
  };

  useEffect(() => {
    fetchData().catch(console.error);
    setGridFilterItem({
      id: facility?.id,
      value: facility?.name,
      field: 'facility',
      operator: 'equals',
    });

  }, [facility?.id, selectedYear]);

  const areColumnsEditable = false;
  const incidentsColumnsDef = useMemo(() => incidentsColumns({
    userType,
    inlineEditEnabled: areColumnsEditable,
  }), [userType]);
  const medicalRRColumnsDef = useMemo(() => medicalRRColumns({
    userType,
    inlineEditEnabled: areColumnsEditable,
  }), [userType]);
  const lawsuitColumnsDef = useMemo(() => lawsuitColumns({
    userType,
    inlineEditEnabled: areColumnsEditable,
  }), [userType]);

  return (
    <Box component="main">
      <PageTitle>{facility?.name}</PageTitle>
      <Grid container spacing={5}>
        <Grid
          component="section"
          item
          xs={12}
          sm={3}
          sx={{height: '100%'}}
        >
          <Stack gap={2}>
            <Stack direction="row" justifyContent="space-around" gap={2}>
              <StatBox
                title="Total Incidents"
                loading={Number.isNaN(facilityTotals.incident)}
                count={facilityTotals.incident}
              />
              <StatBox
                title="Total High Risk Incidents"
                loading={Number.isNaN(facilityTotals.incidentHighRisk)}
                count={facilityTotals.incidentHighRisk}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-around" gap={2}>
              <StatBox
                title="Total MRR"
                loading={Number.isNaN(facilityTotals.medical)}
                count={facilityTotals.medical}
              />
              <StatBox
                title="Total High Risk MRR"
                loading={Number.isNaN(facilityTotals.medicalHighRisk)}
                count={facilityTotals.medicalHighRisk}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-around" gap={2}>
              <StatBox
                title="Total Lawsuit"
                loading={Number.isNaN(facilityTotals.lawsuit)}
                count={facilityTotals.lawsuit}
              />
              <StatBox
                title="Lawsuit Open"
                loading={Number.isNaN(facilityTotals.lawsuitOpen)}
                count={facilityTotals.lawsuitOpen}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={9} xl={8} display="flex" flexGrow={1} minHeight={300}>
          <IncidentsByStatus
            title="Incident Completion Status"
            data={incidentsByTypeAndStatus}
          />
        </Grid>

        <Grid item xs={12} xl={11}>
          <Grid container sx={{marginBottom: 1}} spacing={3}>
            <Grid item xs={12} sm={6}>
              <MRRRiskLevelByRequester
                data={medicalRiskLevelsByRequester}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LawsuitsByMonth
                data={lawsuitCountsByMonth}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} direction="row" flexWrap="wrap">
            <Grid item>
              <Button component={Link} variant="contained" to={routes.incidentReportForm} sx={{width: 272}}>
                New Incident
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                variant="contained"
                to={routes.medicalRecordRequestForm}
                sx={{width: 272, maxWidth: 272}}
              >
                New Medical Record Request
              </Button>
            </Grid>
            <Grid item>
              <Button component={Link} variant="contained" to={routes.lawsuitForm} sx={{width: 272}}>
                New Lawsuit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{mt: 3}}>
        <PageSubTitle>Recent Incident Reports</PageSubTitle>
        <ReportsGrid
          gridFilterItem={gridFilterItem}
          columns={incidentsColumnsDef}
          fetchRowsFn={IncidentService.list}
          patchFn={IncidentService.patch}
          reportKeyType={`facility-${ReportTypeEnum.Incident}`}
        />
        <GoToLink to={routes.incidents}>Go to all Incidents Reports</GoToLink>
      </Box>

      <Box sx={{mt: 3}}>
        <PageSubTitle>Recent Medical Record Requests Reports</PageSubTitle>
        <ReportsGrid
          gridFilterItem={gridFilterItem}
          columns={medicalRRColumnsDef}
          fetchRowsFn={MedicalRRService.list}
          patchFn={MedicalRRService.patch}
          reportKeyType={`facility-${ReportTypeEnum.MedicalRR}`}
        />

        <GoToLink to={routes.medicalRecordRequests}>
          Go to all Medical Record Requests Reports
        </GoToLink>
      </Box>

      <Box sx={{mt: 3}}>
        <PageSubTitle>Recent Lawsuit Notification Reports</PageSubTitle>
        <ReportsGrid
          gridFilterItem={gridFilterItem}
          columns={lawsuitColumnsDef}
          fetchRowsFn={LawsuitService.list}
          patchFn={LawsuitService.patch}
          reportKeyType={`facility-${ReportTypeEnum.Lawsuit}`}
        />
        <GoToLink to={routes.lawsuits}>Go to all Lawsuit Notification Reports</GoToLink>
      </Box>
    </Box>
  );
}

export default DashboardFacility;
