import { FetchListParams } from '@declarations/common/fetchListParams';
import { ListWithPagination, PaginationParams } from '../declarations/common/pagination';
import { Facility } from '../declarations/models/Facility';
import initInstance from './axios';

const api = initInstance('/facility');

const paths = {
  upsert: '/',
  getById: (id: number) => `/${id}`,
  list: '/list',
  getNames: '/names',
  remove: '/remove',
  listCSV: '/list/csv',
};

export const FacilityService = {
  upsert: async (facility: Facility | FormData) => api.post(paths.upsert, facility),
  getById: async (id: number) => api.get(paths.getById(id)),
  list: async (params: PaginationParams) => api.post<ListWithPagination<Facility>>(paths.list, params),
  getNames: async () => api.get<{ id: number, name: string, organization: number }[]>(paths.getNames),
  remove: async (ids: number[]) => api.post(paths.remove, ids),
  exportCSV: async (params: Omit<FetchListParams, 'pagination'>) => api.post<File>(paths.listCSV, params),
};

export default FacilityService;
