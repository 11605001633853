import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';
import { Option } from '@declarations/common/option';

export const RequestingPersonLabels = {
  [RequestedMedicalRecordsPerson.Attorney]: 'Attorney',
  [RequestedMedicalRecordsPerson.FamilyPOA]: 'Family (POA)',
  [RequestedMedicalRecordsPerson.Guardian]: 'Guardian',
  [RequestedMedicalRecordsPerson.RegulatoryAgency]: 'Regulatory Agency',
  [RequestedMedicalRecordsPerson.Resident]: 'Resident',
};

export const RequestingPersonOptions: Option<RequestedMedicalRecordsPerson>[] = [
  {label: RequestingPersonLabels[RequestedMedicalRecordsPerson.Attorney], value: RequestedMedicalRecordsPerson.Attorney},
  {label: RequestingPersonLabels[RequestedMedicalRecordsPerson.FamilyPOA], value: RequestedMedicalRecordsPerson.FamilyPOA},
  {label: RequestingPersonLabels[RequestedMedicalRecordsPerson.Guardian], value: RequestedMedicalRecordsPerson.Guardian},
  {label: RequestingPersonLabels[RequestedMedicalRecordsPerson.RegulatoryAgency], value: RequestedMedicalRecordsPerson.RegulatoryAgency},
  {label: RequestingPersonLabels[RequestedMedicalRecordsPerson.Resident], value: RequestedMedicalRecordsPerson.Resident},
];

export const RequestingPersonColors = {
  [RequestedMedicalRecordsPerson.Attorney]: '#1570A4',
  [RequestedMedicalRecordsPerson.FamilyPOA]: '#1B92D4',
  [RequestedMedicalRecordsPerson.Guardian]: '#69BDEC',
  [RequestedMedicalRecordsPerson.RegulatoryAgency]: '#A5D7F4',
  [RequestedMedicalRecordsPerson.Resident]: '#E1F2FB'
}