import React, { useEffect, useRef, useState } from 'react';
import {
  DefaultValues, FieldValues, useForm, UseFormReturn,
} from 'react-hook-form';
import { serialize } from 'object-to-formdata';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { FormMode } from '@hooks/useFormEdit';
import MoreActions, { MoreActionsProps } from '@components/CRUDForm/MoreActions';
import DisableFormProvider from '../../providers/DisableFormProvider';
import PageTitle from '../page/PageTitle';
import { FORM_MAX_WIDTH, FORM_SPACING } from '../../consts';
import FormButtonBox from '../FormButtonBox';
import FieldStack from './FieldStack';
import { Fields } from './declarations/FormField';
import Field from './Field';
import Loader from '../Loader';

type CRUDFormProps<T extends FieldValues = any> = {
  useFormData?: boolean,
  defaultValues: DefaultValues<T>,
  validationSchema: any,
  fetchEntityMethod: (id: number) => Promise<AxiosResponse<T>>,
  renderTitle: (form: UseFormReturn<T>, mode: FormMode) => React.ReactNode,
  onSubmit: (data: T | FormData, form: UseFormReturn<T>) => Promise<unknown>,
  fields: ((form: UseFormReturn<T>, mode: FormMode) => Fields<T>) | Fields<T>,
  backRoute?: string
  editDisabled?: ((form: UseFormReturn<T>) => boolean) | boolean
  actions?: ((form: UseFormReturn<T>, mode: FormMode) => React.ReactNode)
  moreActions?: ((form: UseFormReturn<T>, mode: FormMode) => MoreActionsProps['actions'])
};

const defineFormMode = (searchParams: URLSearchParams): FormMode => {
  if (searchParams.has('id')) {
    const mode = searchParams.get('mode') as FormMode | string;

    if (mode === FormMode.Edit) return FormMode.Edit;
    if (mode === FormMode.View) return FormMode.View;

    return FormMode.View;
  }

  return FormMode.Create;
};

function CRUDForm<T extends FieldValues = any>({
                                                 useFormData = false,
                                                 defaultValues,
                                                 validationSchema,
                                                 fetchEntityMethod,
                                                 renderTitle,
                                                 onSubmit,
                                                 fields,
                                                 backRoute,
                                                 editDisabled,
                                                 actions,
                                                 moreActions,
                                               }: CRUDFormProps<T>) {
  const [searchParams] = useSearchParams();
  const form = useForm({
    defaultValues,
    resolver: validationSchema && yupResolver(validationSchema, {}, {mode: 'sync'}),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const [mode, setMode] = useState(defineFormMode(searchParams));
  const [fetchEntityLoading, setFetchEntityLoading] = useState(false);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const formRef = useRef<HTMLElement>(null);

  const fetchEntity = async () => {
    const id = searchParams.get('id');
    if (!id) return;

    try {
      setFetchEntityLoading(true);

      const response = await fetchEntityMethod(+id);
      form.reset(response.data || {});
    } catch (error) {
      toast.error('Unable to fetch entity');
    } finally {
      setFetchEntityLoading(false);
    }
  };

  useEffect(() => {
    setMode(defineFormMode(searchParams));
  }, [searchParams]);

  useEffect(() => {
    fetchEntity();
  }, [searchParams]);

  useEffect(() => {
    if (mode === FormMode.Edit && formRef.current) {
      window.scroll({
        top: formRef.current.offsetHeight,
        behavior: 'smooth',
      });
    }
  }, [fetchEntityLoading]);

  const onFormSubmit = form.handleSubmit(async (data) => {
    try {
      setOnSubmitLoading(true);

      if (useFormData) {
        const formData = serialize(data, {
          dotsForObjectNotation: false,
          nullsAsUndefineds: false,
        });
        await onSubmit(formData, form);
      } else {
        await onSubmit(data, form);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const {response} = error;

        if (response && response.status === 400) {
          if ('message' in response.data) {
            toast.error(response.data.message);
          }

          if ('fields' in response.data) {
            response.data.fields.forEach(({field}: { field: string, errors: string[] }) => {
              form.setError(field as any, {});
            });
          }

          return;
        }
      }

      toast.error('Unable to save entity');
    } finally {
      setOnSubmitLoading(false);
    }
  });

  const gridFields = () => {
    if (Array.isArray(fields)) {
      return fields;
    }

    return fields(form, mode);
  };

  if (fetchEntityLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <DisableFormProvider disabled={mode === FormMode.View || onSubmitLoading}>
      <Box component="form" onSubmit={onFormSubmit} mb={4} maxWidth={FORM_MAX_WIDTH} ref={formRef}>
        <Box display="flex" justifyContent="space-between">
          {
            renderTitle && <PageTitle>{renderTitle(form, mode)}</PageTitle>
          }

          <MoreActions actions={moreActions?.(form, mode)}/>
        </Box>

        <Grid container direction="column" spacing={FORM_SPACING}>
          {
            gridFields().map((field, idx) => {
              if (Array.isArray(field)) {
                return <FieldStack fields={field} key={idx} form={form}/>;
              }

              return <Field key={field.name} field={field} form={form}/>;
            })
          }
        </Grid>
      </Box>

      <FormButtonBox
        mode={mode}
        startEditHandle={() => setMode(FormMode.Edit)}
        saveHandle={onFormSubmit}
        loading={onSubmitLoading}
        backRoute={backRoute}
        editDisabled={typeof editDisabled === 'function' ? editDisabled(form) : editDisabled}
        actions={actions?.(form, mode)}
      />
    </DisableFormProvider>
  );
}

export default CRUDForm;
