import { AxiosInstance } from 'axios';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type FacilitiesLawsuitsByStatus =
  {
    facilityId: number;
    facilityName: string;
    reports: [{
      status: ReportStatus;
      count: number;
    }]
  };

export default function getFacilitiesLawsuitsCountsByStatus(api: AxiosInstance) {
  return (organizationId?: number, yearOfLawsuits?: number) => api.get<FacilitiesLawsuitsByStatus[]>('/facilities-counts-by-status', {
    params: {organization_id: organizationId, year_of_lawsuits: yearOfLawsuits},
  });
}
