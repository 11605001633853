import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from '@components/page/PageTitle';
import MedicalRecordsGrid from '../components/MedicalReportsGrid';
import { useSelector } from 'react-redux';
import { MRRTotalsByFacilities } from '@api/MedicalRRService/getTotalsByFacilities';
import MedicalRRService from '@api/MedicalRRService';
import MedicalRRTotalChips from './mrrsTotalChips';
import { Box, Grid } from '@mui/material';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import { MRRTotalByFacilities } from '@api/MedicalRRService/getCountsByFacilities';
import { MRRRiskLevelCountsByFacility } from '@api/MedicalRRService/getRiskLevelCountsByFacility';
import { FacilitiesMMRCountsByRequester } from '@api/MedicalRRService/getFacilitiesCountsByRequester';
import { FacilitiesMMRSByStatus } from '@api/MedicalRRService/getMMRSByFacilityAndStatus';
import HighRiskMRRCountByFacilities from '@components/charts/adminsCharts/mrrs/HighRiskMRRCountByFacilities';
import MMRsCountByRiskLevel from '@components/charts/adminsCharts/dashboard/MMRsCountByRiskLevel';
import MRRsCountByType from '@components/charts/adminsCharts/mrrs/MRRsCountByType';
import MMRsCountByStatus from '@components/charts/adminsCharts/mrrs/MMRsCountByStatus';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { MedicalRecordRequest } from '@declarations/models/reports/MedicalRecordRequest';

function TotalMedicalReports() {
  const selectedFacilities = useSelector(SettingsSelectors.facilitiesFromSelectedOrganization);
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();

  const pageTitle = useMemo<string>(() => {
    if (!selectedFacilities) {
      return 'Facilities';
    }
    return selectedFacilities.length === 1 ? '1 Facility' : `${selectedFacilities.length} Facilities`;
  }, [selectedFacilities?.length]);

  const [mrrTotalsByFacilities, setMrrTotalsByFacilities] =
    useState<MRRTotalsByFacilities>({medical: NaN, incomplete: NaN, highRisk: NaN});

  const [countByFacilities, setCountByFacilities] = useState<MRRTotalByFacilities[] | null>(null);
  const [mrrRiskLevelCountsByFacility, setMrrRiskLevelByFacility] = useState<MRRRiskLevelCountsByFacility[] | null>(null);
  const [facilitiesMMRCountsByRequester, setFacilitiesMMRCountsByRequester] = useState<FacilitiesMMRCountsByRequester [] | null>(null);
  const [facilitiesMMRSByStatus, setFacilitiesMMRSByStatus] = useState<FacilitiesMMRSByStatus[] | null>(null);

  const fetchData = async () => {
    fetchMRRTotals();
    fetchCountsByFacilities();
    fetchRiskLevelCountsByFacility();
    getFacilitiesCountsByRequester();
    fetchFacilitiesMedicalsByStatus();
  };

  useEffect(() => {
    fetchData().catch(console.error);
    setGridFilterItem({
      id: selectedOrganization?.id,
      value: selectedOrganization?.name,
      field: 'operator',
      operator: 'equals',
    });
  }, [selectedOrganization?.id, selectedYear]);

  const onMRRsRemoved = async (ids: number[]) => {
    fetchData().catch(console.error);
  };

  const onMRRPatched = async (newValues: Partial<MedicalRecordRequest>) => {
    if (newValues.calculatedStatus !== undefined) {
      fetchData().catch(console.error);
    }
  };

  const fetchMRRTotals = () => {
    setMrrTotalsByFacilities({medical: NaN, incomplete: NaN, highRisk: NaN});
    MedicalRRService.getTotalsByFacilities(selectedOrganization?.id, selectedYear)
      .then(({data}) => setMrrTotalsByFacilities(data))
      .catch((e) => {
        setMrrTotalsByFacilities({medical: 0, highRisk: 0, incomplete: 0});
        throw e;
      });
  };

  const fetchCountsByFacilities = () => {
    setCountByFacilities(null);
    MedicalRRService.getCountsByFacilities(RiskLevel.HighRisk, selectedOrganization?.id, selectedYear)
      .then(({data}) => setCountByFacilities(data))
      .catch((e) => {
        setCountByFacilities([]);
        throw e;
      });
  };

  const fetchRiskLevelCountsByFacility = () => {
    setMrrRiskLevelByFacility(null);
    MedicalRRService.getRiskLevelCountsByFacility(selectedOrganization?.id, selectedYear)
      .then(({data}) => setMrrRiskLevelByFacility(data))
      .catch((e) => {
        setMrrRiskLevelByFacility([]);
        throw e;
      });
  };

  const getFacilitiesCountsByRequester = () => {
    setFacilitiesMMRCountsByRequester(null);
    MedicalRRService.getFacilitiesCountsByRequester(undefined, selectedOrganization?.id, selectedYear)
      .then(({data}) => setFacilitiesMMRCountsByRequester(data))
      .catch((e) => {
        setFacilitiesMMRCountsByRequester([]);
        throw e;
      });
  };

  const fetchFacilitiesMedicalsByStatus = () => {
    setFacilitiesMMRSByStatus(null);
    MedicalRRService.getFacilitiesMedicalsByStatus(RiskLevel.HighRisk, selectedOrganization?.id, selectedYear)
      .then(({data}) => setFacilitiesMMRSByStatus(data))
      .catch((e) => {
        setFacilitiesMMRSByStatus([]);
        throw e;
      });
  };

  return (
    <Box component="main">
      <PageTitle>{pageTitle}</PageTitle>
      <MedicalRRTotalChips totals={mrrTotalsByFacilities}></MedicalRRTotalChips>
      <Grid container sx={{marginBottom: 5}} spacing={3}>
        <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
          <HighRiskMRRCountByFacilities data={countByFacilities}/>
        </Grid>
        <Grid item xs={12} md={7} lg={7} xl={7}>
          <MMRsCountByRiskLevel data={mrrRiskLevelCountsByFacility}/>
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
          <MMRsCountByStatus data={facilitiesMMRSByStatus}/>
        </Grid>
        <Grid item xs={12} md={7} lg={7} xl={7}>
          <MRRsCountByType data={facilitiesMMRCountsByRequester}/>
        </Grid>
      </Grid>
      <MedicalRecordsGrid medicalsReportGridKey={'total-medicals'} gridFilterItem={gridFilterItem} onRemove={onMRRsRemoved} onMRRPatched={onMRRPatched}/>
    </Box>
  );
}

export default TotalMedicalReports;
