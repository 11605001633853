import React from 'react';
import { UserType } from '@declarations/common/enums/UserType';
import useAccess from '@hooks/useAccess';

type ProtectedProps = {
  types?: UserType[];
  children: JSX.Element;
};

function Protected({ types = [], children }: ProtectedProps) {
  const { hasAccess } = useAccess({ types });

  return hasAccess
    ? children || null
    : null;
}

export default Protected;
