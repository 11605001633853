import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import { Bar, ChartProps } from 'react-chartjs-2';
import ChartContainer from '@components/charts/ChartContainer';
import { MRRTotalByFacilities } from '@api/MedicalRRService/getCountsByFacilities';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'High Risk per Facility',
      font: {
        size: 20,
      },
    },
    legend: {
      display: false,
    },
  },
});

type HighRiskMRRCountByFacilitiesProps = {
  data: MRRTotalByFacilities[] | null;
};

function HighRiskMRRCountByFacilities({data}: HighRiskMRRCountByFacilitiesProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: [{
          data: data.map(({count}) => count),
          backgroundColor: '#197CB6',
          barPercentage: 0.5
        }],
      });
    } else {
      setChartData(null);
    }
  }, [data]);
  const maxXValue: number = _.max(data?.map(d => d.count)) || 0;
  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxXValue)}/>
    </ChartContainer>
  );
}

export default React.memo(HighRiskMRRCountByFacilities);
