import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function Toast() {
  return (
    <ToastContainer
      autoClose={10000}
    />
  );
}

export default Toast;
