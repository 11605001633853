import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import ChartContainer from '@components/charts/ChartContainer';
import { Bar, ChartProps } from 'react-chartjs-2';
import { RiskLevel, RiskLevelColors } from '@declarations/common/enums/RiskLevel';
import getOptionLabel from '@utils/getOptionLabel';
import RiskLevelOptions from '@constants/options/RiskLeveOptions';
import { MRRRiskLevelCountsByRequester } from '@api/MedicalRRService/getMRRRiskLevelByRequester';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import calculateAxisMaxValue from '@utils/calculateAxisMaxValue';
import { RequestingPersonOptions } from '@constants/options/RequestingPersonOptions';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'MRR Completion Status',
      font: {
        size: 20,
      },
    },
  },
});

type MRRRiskLevelByRequesterProps = {
  data: MRRRiskLevelCountsByRequester[] | null;
};


function MRRRiskLevelByRequester({data}: MRRRiskLevelByRequesterProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  useEffect(() => {
    if (data) {
      setChartData({
        labels: RequestingPersonOptions.map(ito => ito.label),
        datasets: Object.values(RiskLevel).map((value) => ({
          label: getOptionLabel(RiskLevelOptions, value),
          data: RequestingPersonOptions.map(ito => {
            const requestingPersonOption = data.find(mrlcr => mrlcr.requester === ito.value);
            return requestingPersonOption?.countsByRiskLevel[value] || 0;
          }),
          backgroundColor: RiskLevelColors[value],
          stack: 'y',
          barPercentage: 0.45,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const maxYValue: number = data ? calculateAxisMaxValue(data, 'countsByRiskLevel') : 0;
  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default MRRRiskLevelByRequester;
