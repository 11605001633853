import React, { useCallback, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SpeedIcon from '@mui/icons-material/Speed';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Link, useLocation } from 'react-router-dom';
import { UserType } from '@declarations/common/enums/UserType';
import Protected from '@components/Protected';
import { NAV_DRAWER_WIDTH } from '../../consts';
import routes from '../../constants/routes';
import BrandLogoBox from '../BrandLogoBox/BrandLogoBox';
import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { groupBy } from 'lodash';
import { SettingsActions, SettingsSelectors } from '@store/slices/settings';
import { useDispatch, useSelector } from 'react-redux';
import user, { UserSelectors } from '@store/slices/user';
import './NavigationDrawer.css';
import ReplicaLogoBox from '@components/ReplicaLogoBox/ReplicaLogoBox';
import ReportService from '@api/ReportService';
import { CorporateFare, Gavel, Home, MedicalInformation, Report } from '@mui/icons-material';


const iconContainerWidth = '40px';

interface Props {
  isOpen: boolean;
}

interface NavItemProps {
  text: string;
  path: string;
  icon: React.ReactNode;
  external?: boolean;
  protectedTypes?: UserType[];
}

const disableLinkStyles = {textDecoration: 'none', color: 'black'};

function NavItem(props: NavItemProps) {
  const {
    text, path, icon, external = false, protectedTypes,
  } = props;

  const location = useLocation();
  const isSelected = path === location.pathname;

  const itemText = <Typography variant="body2">{text}</Typography>;

  const node = (
    <Protected types={protectedTypes}>
      <ListItem disablePadding>
        <ListItemButton selected={isSelected}>
          <ListItemIcon sx={{minWidth: iconContainerWidth}}>{icon}</ListItemIcon>
          <ListItemText primary={itemText}/>
        </ListItemButton>
      </ListItem>
    </Protected>
  );

  if (external) {
    return (
      <a href={path} style={disableLinkStyles}>
        {node}
      </a>
    );
  }

  return (
    <Link to={path} style={disableLinkStyles}>
      {node}
    </Link>
  );
}

const drawerNav = {
  dashboard: [
    {
      text: 'Home',
      path: routes.dashboard,
    },
  ],
  reports: [
    {
      text: 'Incidents',
      path: routes.incidents,
      icon: <Report/>,
    },
    {
      text: 'Medical Record Requests',
      path: routes.medicalRecordRequests,
      icon: <MedicalInformation/>,
    },
    {
      text: 'Lawsuits',
      path: routes.lawsuits,
      icon: <Gavel/>,
    },
  ],
  users: [
    {
      text: 'Organizations',
      path: routes.organizations,
      protectedTypes: [UserType.SuperAdmin, UserType.OrganizationAdmin],
      icon: <CorporateFare/>,
    },
    {
      text: 'Facilities',
      path: routes.facilities,
      protectedTypes: [UserType.SuperAdmin, UserType.OrganizationAdmin],
      icon: <Home/>,
    },
    {
      text: 'User Management',
      path: routes.userManagement,
      protectedTypes: [UserType.SuperAdmin, UserType.OrganizationAdmin],
      icon: <AccountCircle/>,
    },
  ],
  ltcRiskAndManagement: [
    {
      text: 'LTC Legal Risk & Management',
      path: routes.ltcRiskAndManagement,
    },
  ],
};

const YearSelect = () => {
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    ReportService.getYearsForCharts().then(response => {
      setYears(response.data.map(y => y.toString()));
    });
  }, []);

  const selectedYear = useSelector(SettingsSelectors.selectedYear);
  const dispatch = useDispatch();

  const changeHandler = (e: SelectChangeEvent) => {
    dispatch(SettingsActions.setSelectedYear(e.target.value));
  };

  return (<div className="year-select">
    <FormControl style={{width: '100%'}}>
      <InputLabel shrink htmlFor="year">Year for Charts</InputLabel>
      <Select value={selectedYear} id="year" onChange={changeHandler}>
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {years.map(year => (<MenuItem value={year} key={year}>{year}</MenuItem>))}
      </Select>
    </FormControl>
  </div>);
};

const OrganizationSelect = () => {
  const user = useSelector(UserSelectors.user);
  const selectedOrganizationId = useSelector(SettingsSelectors.selectedOrganization)?.id;
  const dispatch = useDispatch();

  const changeHandler = (e: SelectChangeEvent) => {
    const organization = user.organizations?.find(facility => facility.id === Number(e.target.value));
    dispatch(SettingsActions.setOrganization(organization || null));
    dispatch(SettingsActions.setFacility(null));
  };

  const renderOrganizationOptions = useCallback(() => {
    const organizations = user.organizations || [];
    return organizations.map(organization => (
      <MenuItem value={organization.id} key={organization.id}>{organization.name}</MenuItem>),
    );
  }, [user?.organizations]);

  let value = selectedOrganizationId?.toString() || '';
  let disabled = false;
  if (user?.organizations?.length === 1) {
    value = user.organizations[0].id.toString();
    disabled = true;
  }
  const className = disabled ? 'organization-select disabled' : 'organization-select';

  return (<div className={className}>
    <FormControl style={{width: '100%'}} disabled={disabled}>
      <InputLabel shrink htmlFor="organizations">Organization</InputLabel>
      <Select value={value} id="organizations" onChange={changeHandler}>
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {renderOrganizationOptions()}
      </Select>
    </FormControl>
  </div>);
};

const FacilitySelect = () => {
  const user = useSelector(UserSelectors.user);
  const selectedFacilityId = useSelector(SettingsSelectors.selectedFacility)?.id || '';
  const selectedOrganizationId = useSelector(SettingsSelectors.selectedOrganization)?.id;
  const dispatch = useDispatch();

  const renderFacilityOptions = useCallback(() => {
    let facilityNames = user.facilities || [];
    const organizations = user.organizations || [];
    if (selectedOrganizationId) {
      facilityNames = facilityNames.filter(f => (f as any).organizationId === Number(selectedOrganizationId));
    }
    let groups = groupBy(facilityNames, 'organizationId');
    if (Object.keys(groups).length < 2) {
      return facilityNames.map(facility => (
        <MenuItem value={facility.id} key={facility.id}>{facility.name}</MenuItem>
      ));
    }
    const options: JSX.Element[] = [];

    for (const key in groups) {
      const organization = organizations.find(o => o.id === Number(key));
      options.push(<ListSubheader key={organization?.name}>{organization?.name}</ListSubheader>);

      for (const facility of groups[key]) {
        options.push(<MenuItem value={facility.id} key={facility.id}
                               style={{marginLeft: '8px'}}>{facility.name}</MenuItem>);
      }
    }

    return options;
  }, [user?.facilities, user?.organizations, selectedOrganizationId]);

  const changeHandler = (e: SelectChangeEvent) => {
    const facility = user.facilities?.find(facility => facility.id === Number(e.target.value));
    dispatch(SettingsActions.setFacility(facility || null));
  };

  let value = selectedFacilityId?.toString() || '';
  let disabled = false;
  if (user?.facilities?.length === 1) {
    value = user.facilities[0].id.toString();
    disabled = true;
  }
  const className = disabled ? 'facility-select disabled' : 'facility-select';

  return (<div className={className}>
    <FormControl style={{width: '100%'}} disabled={disabled}>
      <InputLabel shrink htmlFor="facilities">Facility</InputLabel>
      <Select value={value} id="facilities" onChange={changeHandler}>
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {renderFacilityOptions()}
      </Select>
    </FormControl>
  </div>);
};

function NavigationDrawer(props: Props) {
  const {isOpen} = props;
  const user = useSelector(UserSelectors.user);

  return (
    <Drawer
      sx={{
        width: NAV_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: NAV_DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={isOpen}
    >
      <ReplicaLogoBox></ReplicaLogoBox>
      {!user.expertRole && <YearSelect/>}
      {!user.expertRole && <OrganizationSelect/>}
      <List>
        {drawerNav.dashboard.map(({text, path}, index) => (
          <NavItem key={text} icon={<SpeedIcon/>} text={text} path={path}/>
        ))}
      </List>
      <Typography variant="subtitle1" color="gray" sx={{ml: 2, fontWeight: 400}}>
        Reports
      </Typography>

      {!user.expertRole && <FacilitySelect/>}

      <List>
        {drawerNav.reports.map(({text, path, icon}, index) => (
          <NavItem key={text} text={text} path={path} icon={icon}/>
        ))}
      </List>
      <Divider/>
      <List>
        {drawerNav.users.map(({text, path, icon, protectedTypes}, index) => (
          <NavItem
            key={text}
            text={text}
            path={path}
            icon={icon}
            protectedTypes={protectedTypes}
          />
        ))}
      </List>
      <Protected types={[UserType.SuperAdmin, UserType.OrganizationAdmin]}>
        <Divider/>
      </Protected>
      <List>
        {drawerNav.ltcRiskAndManagement.map(({text, path}, index) => (
          <NavItem key={text} text={text} path={path} icon={<InfoIcon/>} external/>
        ))}
      </List>
      <BrandLogoBox/>
    </Drawer>
  );
}

export default NavigationDrawer;
