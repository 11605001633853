import ProviderLogRecord from '@components/SubmissionTabs/ProviderLogRecord';
import SubmissionTabs from '@components/SubmissionTabs/SubmissionTabs';
import routes from '@constants/routes';
import { ReportTypeEnum } from '@declarations/common/reportType';
import { memo, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProviderLogRecordForm from '@components/SubmissionTabs/ProviderLogRecordForm';
import ReportHeader from '@components/SubmissionTabs/ReportHeader';
import useApi from '@hooks/useApi';
import IncidentService from '@api/IncidentService';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';

function IncidentProviderTab() {
  const [searchParams] = useSearchParams();
  const user = useSelector(UserSelectors.user);
  const logRecordRef = useRef<{ refresh(): void }>(null);

  const { data, isLoading, fetch } = useApi({
    method: IncidentService.getById,
    initialData: {} as any,
  });

  useEffect(() => {
    fetch(Number(searchParams.get('id')));
  }, []);

  const handleSave = () => {
    logRecordRef.current?.refresh();
  }

  return (
    <>
      <SubmissionTabs active="provider" prefix={routes.incidentReportForm} />
      <ReportHeader
        name="Incident"
        createdAt={data.createdAt}
        reportNumber={data.reportNumber}
        riskPoints={data.riskPoints}
        loading={isLoading}
      />
      {!user.expertRole && <ProviderLogRecordForm reportType={ReportTypeEnum.Incident} onSave={handleSave} />}
      <ProviderLogRecord reportType={ReportTypeEnum.Incident} ref={logRecordRef} />
    </>
  );
}

export default memo(IncidentProviderTab);
