import { ReportType } from '@declarations/common/reportType';
import initInstance from './axios';

const api = initInstance('/legal-log-record');

type CreateLegalLogRecord = {
  reportId: number;
  reportType: ReportType;
  note?: string;
}

type QueryLegalLogRecords = {
  reportId: number;
  reportType: ReportType;
}

const LegalLogRecordService = {
  createRecord: (data: CreateLegalLogRecord | FormData) => api.post('/create-record', data),
  get: (params: QueryLegalLogRecords) =>
    api.get<any[]>('', { params }),
};

export default LegalLogRecordService;