import { Box, CircularProgress } from '@mui/material';
import React from 'react';

function Loader() {
  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 16 }}>
      <CircularProgress sx={{ minWidth: 64, minHeight: 64 }} />
    </Box>
  );
}

export default React.memo(Loader);
