import React, { useEffect, useImperativeHandle, useState } from 'react';
import {
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import IncidentService from '@api/IncidentService';
import IncidentTypeOptions, { IncidentTypeColors } from '@constants/options/IncidentTypeOptions';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import calculateAxisMaxValue from '@utils/calculateAxisMaxValue';
import { useSelector } from 'react-redux';
import { SettingsSelectors } from '@store/slices/settings';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number) => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Incidents per Facility',
      font: {
        size: 20,
      },
    },
  },
});

type IncidentsCountByFacilityAndTypeHandle = {
  refresh: () => Promise<void>;
};

function IncidentsCountByFacilityAndType({}, ref: React.Ref<IncidentsCountByFacilityAndTypeHandle>) {
  const [data, setData] = useState<ChartData<'bar'>>();
  const [maxYValue, setMaxYValue] = useState<number>(0);
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);

  const fetchData = async () => {
    setData(undefined);
    try {
      const {data} = await IncidentService.getIncidentsByFacilityAndType(undefined, selectedOrganization?.id, selectedYear);
      setMaxYValue(calculateAxisMaxValue(data, 'countsByStatus'));
      setData({
        labels: getFacilityLabels(data),
        datasets: IncidentTypeOptions.map((incidentType, i) => ({
          label: incidentType.label,
          backgroundColor: IncidentTypeColors[incidentType.value],
          data: data.map((incident) => {
            return incident.countsByStatus[incidentType.value] ?? 0;
          }),
          stack: 'x',
          barPercentage: 0.4,
        })),
      });
    }
    catch (e) {
      console.log(e);
      setData({datasets: []});
    }
  };

  useEffect(() => {
    fetchData()
  }, [selectedOrganization, selectedYear]);

  useImperativeHandle(ref, () => ({
    refresh: fetchData,
  }));

  return (
    <ChartContainer loading={!data}>
      <Bar data={data!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.forwardRef(IncidentsCountByFacilityAndType);
