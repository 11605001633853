/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';
import { UserState } from '@store/slices/user';

export interface SettingsState {
  selectedFacility: { id: number, name: string, organizationId: number } | null;
  selectedOrganization: { id: number, name: string } | null;
  facilitiesFromSelectedOrganization: { id: number, name: string, organizationId: number }[] | null;
  selectedYear: string;
}

const SETTINGS = 'settings';

const initialState: SettingsState = localStorage.getItem(SETTINGS) 
  ? JSON.parse(localStorage.getItem(SETTINGS) || '')
  : {selectedFacility: null, selectedOrganization: null, selectedYear: '2023'};

export const settingsSlice = createSlice({
  name: SETTINGS,
  initialState,
  reducers: {
    setFacility(state: SettingsState, action: PayloadAction<{ id: number, name: string, organizationId: number } | null>) {
      state.selectedFacility = action.payload;
      localStorage.setItem(SETTINGS, JSON.stringify(state));
    },
    setOrganization(state: SettingsState, action: PayloadAction<{ id: number, name: string } | null>) {
      state.selectedOrganization = action.payload;
      localStorage.setItem(SETTINGS, JSON.stringify(state));
    },
    setSelectedYear(state: SettingsState, action: PayloadAction<string>) {
      state.selectedYear = action.payload;
      localStorage.setItem(SETTINGS, JSON.stringify(state));
    },
  },
});

export const SettingsActions = settingsSlice.actions;

const SettingsSelector = (state: RootState): SettingsState => state.settings;
const UserSelector = (state: RootState): UserState => state.user;

export const SettingsSelectors = {
  selectedFacility: createSelector(SettingsSelector, (settings) => settings.selectedFacility),
  selectedOrganization: createSelector(SettingsSelector, (settings) => settings.selectedOrganization),
  facilitiesFromSelectedOrganization: createSelector([UserSelector, SettingsSelector], (user, settings) => settings.selectedOrganization 
    ? user.facilities.filter(f => f.organizationId === settings.selectedOrganization!.id)
    : user.facilities
  ),
  selectedYear: createSelector(SettingsSelector, (settings) => settings.selectedYear),
  selectedYearNumber: createSelector(SettingsSelector, (settings) => settings.selectedYear ? Number(settings.selectedYear) : undefined),
};

export default settingsSlice.reducer;
