import {
  array, boolean, object, SchemaOf, string,
} from 'yup';
import Errors from '@constants/locale/errors';
import { User } from '@declarations/models/User';
import { UserType } from '@declarations/common/enums/UserType';
import 'yup-phone';

type UpsertUserDTO = Omit<User, 'id' | 'disabled' | 'type'>;

type SchemaParams = {
  userType: UserType
};

const userManagementFormValidationSchema = ({ userType }: SchemaParams): SchemaOf<UpsertUserDTO> => object().shape({
  email: string().required(Errors.REQUIRED_FIELD).email(Errors.VALID_EMAIL),
  firstname: string().required(Errors.REQUIRED_FIELD),
  lastname: string().required(Errors.REQUIRED_FIELD),
  position: string().required(Errors.REQUIRED_FIELD),
  organizations: userType === UserType.OrganizationAdmin
    ? array().min(1, Errors.REQUIRED_FIELD)
    : array(),
  facilities: userType === UserType.OrganizationAdmin
    ? array().min(1, Errors.REQUIRED_FIELD)
    : array(),
  preferredContactNumber: string().when('preferredContactNumber', {
    is: (value: string) => value?.length > 0,
    then: string().phone('US', false, Errors.VALID_PHONE),
    otherwise: string().notRequired().nullable(),
  }),
  additionalInfo: string().notRequired().nullable(),
  expertUser: boolean(),
  expertRole: string().nullable(),
}, [['preferredContactNumber', 'preferredContactNumber']]);

export default userManagementFormValidationSchema;
