import { ReportTypeEnum } from "@declarations/common/reportType";
import { AxiosInstance, AxiosResponse } from "axios";

export type UpsertLinkedReportsDto = {
  sourceReport: ReportTypeEnum;
  incidentId: number | null;
  mrrId: number | null;
  lawsuitId: number | null;
}

const setFacilityReportsRelation = (api: AxiosInstance) => (data: UpsertLinkedReportsDto): Promise<AxiosResponse<any>> => {
  return api.put('/set-facility-reports-relation', data);
}

export default setFacilityReportsRelation;