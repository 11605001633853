import React, { useEffect, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import { LawsuitsCountsByFacility } from '@api/LawsuitService/getLawsuitCountsByFacility';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
    y: {
      ticks: {autoSkip: false, padding: 10},
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Total Lawsuits',
      font: {
        size: 20,
      },
    },
    legend: {
      display: false,
    },
  },
});

type LawsuitsCountProps = {
  data: LawsuitsCountsByFacility[] | null;
};

function LawsuitsCount({data}: LawsuitsCountProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: [{
          data: data.map(d => d.count),
          backgroundColor: '#197CB6',
          barPercentage: 0.5
        }],
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const maxYValue: number = _.max(data?.map(d => d.count)) || 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.memo(LawsuitsCount);
