import { useMemo } from "react";
import { Option } from '@declarations/common/option';
import { useSelector } from "react-redux";
import { UserSelectors } from "@store/slices/user";
import { SettingsSelectors } from "@store/slices/settings";

export default function useFacilityOptions() {
  const userFacilities = useSelector(UserSelectors.user)?.facilities;
  const selectedFacilityId = useSelector(SettingsSelectors.selectedFacility)?.id;

  const facilities = useMemo<Option[]>(() => userFacilities.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  })), [userFacilities, selectedFacilityId])

  return { facilities, selectedFacilityId };
}