import React, { useEffect, useState } from 'react';
import { MRRRiskLevelCountsByMonth } from '@api/MedicalRRService/getRiskLevelByMonth';
import { ChartData } from 'chart.js';
import ChartContainer from '@components/charts/ChartContainer';
import { Bar, ChartProps } from 'react-chartjs-2';
import { months } from '@components/charts/common';
import { RiskLevel, RiskLevelColors } from '@declarations/common/enums/RiskLevel';
import getOptionLabel from '@utils/getOptionLabel';
import RiskLevelOptions from '@constants/options/RiskLeveOptions';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import calculateAxisMaxValue from '@utils/calculateAxisMaxValue';
import moment from 'moment/moment';

const options = (dataLength: number): ChartProps<'bar'>['options'] =>
  ({
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {stepSize: 1},
        max: getAxisMaxValue(dataLength),
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'MRR by Month',
        font: {
          size: 20,
        },
      },
      legend: {
        display: true,
      },
    },
  });

type MedicalRiskLevelByMonthProps = {
  data: MRRRiskLevelCountsByMonth[] | null;
};

function MRRRiskLevelByMonth({data}: MedicalRiskLevelByMonthProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  useEffect(() => {
    if (data) {
      setChartData({
        labels: months,
        datasets: [
          RiskLevel.HighRisk,
          RiskLevel.MediumRisk,
          RiskLevel.LowRisk,
        ].map((riskLevel) => ({
          label: getOptionLabel(RiskLevelOptions, riskLevel),
          data: months.map(m => {
            const currentMonthData: MRRRiskLevelCountsByMonth | undefined =
              data.find(d => moment(m, 'MMM').isSame(moment(d.month, 'M')));
            return currentMonthData ? currentMonthData.countsByRiskLevel[riskLevel] ?? 0 : null;
          }),
          backgroundColor: RiskLevelColors[riskLevel],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const maxYValue: number = data ? calculateAxisMaxValue(data, 'countsByRiskLevel') : 0;
  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.memo(MRRRiskLevelByMonth);
