import React from 'react';

interface Props {
  children: React.ReactNode
}

const AuthProvider = (props: Props) => {
  const { children } = props;

  return children;
};

export default AuthProvider;
