import { Facility } from '@declarations/models/Facility';
import {
  number, object, SchemaOf, string, date, mixed, boolean,
} from 'yup';
import { FacilityType } from '@declarations/common/enums/FacilityType';
import Errors from '@constants/locale/errors';
import '@utils/yup-methods';

type UpsertFacilityDTO = Omit<Facility, 'id' | 'createdAt'> & Pick<Partial<Facility>, 'id' | 'createdAt'>;

const facilityFormValidationSchema: SchemaOf<UpsertFacilityDTO> = object({
  id: number(),
  createdAt: date(),
  name: string().required(Errors.REQUIRED_FIELD),
  type: mixed().required(Errors.REQUIRED_FIELD).oneOf(Object.values(FacilityType), 'Please select the type of Facility'),
  organization: number().transform((value, originalValue) => originalValue == '' ? undefined : +originalValue).required('Please select a Provider'),
  primaryContact: string().required(Errors.REQUIRED_FIELD),
  primaryContactEmail: string().email(Errors.VALID_EMAIL).required(Errors.REQUIRED_FIELD),
  contactNumber: string().phone('US', false, Errors.VALID_PHONE).required(Errors.REQUIRED_FIELD),
  physicalAddress: string().required(Errors.REQUIRED_FIELD),
  city: string().required(Errors.REQUIRED_FIELD),
  state: string().required(Errors.REQUIRED_FIELD),
  zipCode: string().zipCode('US').required(Errors.REQUIRED_FIELD),
  isPhysicalAndMailingAddressEqual: boolean(),
  mailingAddress: string().when('isPhysicalAndMailingAddressEqual', {
    is: (value: boolean) => !value,
    then: string().required(Errors.REQUIRED_FIELD),
    otherwise: string().nullable(),
  }),
  mailingAddressCity: string().when('isPhysicalAndMailingAddressEqual', {
    is: (value: boolean) => !value,
    then: string().required(Errors.REQUIRED_FIELD),
    otherwise: string().nullable(),
  }),
  mailingAddressState: string().when('isPhysicalAndMailingAddressEqual', {
    is: (value: boolean) => !value,
    then: string().required(Errors.REQUIRED_FIELD),
    otherwise: string().nullable(),
  }),
  mailingAddressZipCode: string().when('isPhysicalAndMailingAddressEqual', {
    is: (value: boolean) => !value,
    then: string().zipCode('US').required(Errors.REQUIRED_FIELD),
    otherwise: string().zipCode('US').nullable(),
  }),
  licensedBedCount: number().positive(Errors.POSITIVE_NUMBER).integer(Errors.POSITIVE_NUMBER).typeError(Errors.POSITIVE_NUMBER),
  averageOccupiedBeds: number().positive(Errors.POSITIVE_NUMBER).integer(Errors.POSITIVE_NUMBER).typeError(Errors.POSITIVE_NUMBER),
  additionalInfo: string().notRequired().nullable(),
});

export default facilityFormValidationSchema;
