import { ReportStatus as ReportStatusType } from '@declarations/common/enums/ReportStatus';
import { Typography } from '@mui/material';
import React from 'react';
import SingleDropDownContainer from '@components/DataGrid/renders/singleDropDownRender';
import { AutoFixHighOutlined } from '@mui/icons-material';

type ReportStatusProps = {
  calculatedStatus: ReportStatusType;
  forcedStatus: ReportStatusType | null | undefined;
  editable?: boolean;
};

function ReportStatus({calculatedStatus, forcedStatus, editable}: ReportStatusProps) {
  let displayText = <Typography fontSize={14}>{calculatedStatus}</Typography>;
  switch (calculatedStatus) {
    case ReportStatusType.Completed:
      displayText =
        <Typography color="green" fontSize={14} display={'flex'}>Completed{forcedStatus &&
            <AutoFixHighOutlined style={{marginLeft: 8}}/>}</Typography>;
      break;
    case ReportStatusType.InProgress:
      displayText =
        <Typography color="#c08d30" fontSize={14} display={'flex'}>In Progress{forcedStatus &&
            <AutoFixHighOutlined style={{marginLeft: 6}}/>}</Typography>;
      break;
    case ReportStatusType.Incomplete:
      displayText =
        <Typography color="red" fontSize={14} display={'flex'}>Incomplete{forcedStatus &&
            <AutoFixHighOutlined style={{marginLeft: 7}}/>}</Typography>;
      break;
  }
  return editable ? <SingleDropDownContainer displayValue={displayText}></SingleDropDownContainer> : displayText;
}

export default React.memo(ReportStatus);
