import React from 'react';
import { useSelector } from 'react-redux';
import TotalLawsuitReports from './TotalLawsuitReports';
import FacilityLawsuitReports from './FacilityLawsuitReports';
import { SettingsSelectors } from '@store/slices/settings';
import LawsuitReportsGrid from './components/LawsuitReportsGrid';
import { UserSelectors } from '@store/slices/user';

function LawsuitReports() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const user = useSelector(UserSelectors.user);
  if (user.expertRole) {
    return <LawsuitReportsGrid lawsuitsReportGridKey={'facility-lawsuits'} gridFilterItem={undefined}/>
  }
  return facility ? <FacilityLawsuitReports/> : <TotalLawsuitReports/>;
}

export default LawsuitReports;
