import React, { useEffect, useImperativeHandle, useState } from 'react';
import IncidentService from '@api/IncidentService';
import { useSelector } from 'react-redux';
import { SettingsSelectors } from '@store/slices/settings';
import { Bar, ChartProps } from 'react-chartjs-2';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import ChartContainer from '@components/charts/ChartContainer';
import getOptionLabel from '@utils/getOptionLabel';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import { ChartData } from 'chart.js';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (maxXValue: number): ChartProps<'bar'>['options'] => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(maxXValue),
    },
    y: {
      ticks: {autoSkip: false, padding: 10},
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Incident Completion Status',
      font: {
        size: 20,
      },
    },
  },
});


type IncidentsCountByStatusHandle = {
  refresh: () => Promise<void>;
};

function IncidentsCountByStatus({}, ref: React.Ref<IncidentsCountByStatusHandle>) {
  const [data, setData] = useState<ChartData<'bar'>>();
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [maxYValue, setMaxYValue] = useState<number>(0);
  const fetchData = async () => {
    setData(undefined);
    try {
      const {data} = await IncidentService.getFacilitiesIncidentsByStatus(undefined, selectedOrganization?.id, selectedYear);
      const flattenSum = data.map(d => _.sumBy(d.reports, 'count'));
      setMaxYValue(_.max(flattenSum) || 0);
      setData({
        labels: getFacilityLabels(data),
        datasets: Object.values(ReportStatus).map((repostStatus) => ({
          label: getOptionLabel(ReportStatusOptions, repostStatus),
          data: data.map(d => {
            const reportsByStatus = d.reports.find(r => r.status === repostStatus);
            return reportsByStatus?.count || 0;
          }),
          backgroundColor: ReportStatusColors[repostStatus],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } catch (e) {
      setData({datasets: []});
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOrganization, selectedYear]);

  useImperativeHandle(ref, () => ({
    refresh: fetchData,
  }));


  return (
    <ChartContainer loading={!data}>
      <Bar data={data!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.forwardRef(IncidentsCountByStatus);
