import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import StateStorage from '@utils/stateStorage';
import tryParseJSONObject from '@utils/tryParseJSONObject';
import { UserState } from '@store/slices/user';

type CachedState = GridInitialStatePro;

class DataGridStorage {
  static _dataGridStorage: StateStorage<unknown> | null = null;

  static getInstance = (): StateStorage<unknown> => {
    if (!this._dataGridStorage) {
      this._dataGridStorage = new StateStorage(this.getStorageName());
    }
    return this._dataGridStorage;
  }
  
  static getStorageName = (): string => {
    const user = localStorage.getItem('user');
    const userState: UserState | null = tryParseJSONObject(user);
    const { id, type, expertRole } = userState || {};
    return id ? `${id}-${type}-${expertRole}-data-grid-state` : 'data-grid-state';
  }
}

export async function saveInitialState(stateName: string, state: CachedState) {
  const initialStateName = `${stateName}/initial`;

  const savedState = await DataGridStorage.getInstance().loadState(initialStateName);

  if (!savedState) {
    await DataGridStorage.getInstance().saveState(initialStateName, state);
  }
}

export async function loadInitialState(stateName: string) {
  const initialStateName = `${stateName}/initial`;

  return (await DataGridStorage.getInstance().loadState(initialStateName)) as GridInitialStatePro;
}

export const saveState = async (stateName: string, state: CachedState) => {
  await DataGridStorage.getInstance().saveState(stateName, state);
  await saveInitialState(stateName, state);
};

export async function loadState(stateName: string) {
  return DataGridStorage.getInstance().loadState(stateName);
}

export async function clearState(stateName: string) {
  return DataGridStorage.getInstance().clearState(stateName);
}

export async function clearInitialState(stateName: string) {
  return DataGridStorage.getInstance().clearState(`${stateName}/initial`);
}

export async function clearAllStates() {
  return DataGridStorage.getInstance().clear();
}
