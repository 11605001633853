import React from 'react';
import { useNavigate } from 'react-router-dom';
import OrganizationService from '@api/organizationService';
import CRUDForm from '@components/CRUDForm';
import { Organization } from '@declarations/models/Organization';
import routes from '@constants/routes';
import { toast } from 'react-toastify';
import { FormMode } from '@hooks/useFormEdit';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import generateFields from './OrganizationForm.fields';
import organizationFormValidationSchema from './OrganizationForm.validation-schema';
import UserService from '@api/userService';
import { useDispatch } from 'react-redux';
import { UserActions } from '@store/slices/user';

function OrganizationForm() {
  const { hasAccess: hasSuperAdminAccess } = useAccess({ types: [UserType.SuperAdmin] });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data: Organization | FormData) => {
    await OrganizationService.upsert(data);
    UserService.me().then(({ data: me }) => dispatch(UserActions.setUser(me)));
    const organizationName = (data as Organization).name || 'Organization';
    navigate(routes.organizations);
    toast.success(`${organizationName} has been saved successfully`);
  };

  return (
    <CRUDForm
      defaultValues={{
        primaryContact: '',
        primaryContactEmail: '',
        contactNumber: '',
        city: '',
        state: '',
        zipCode: '',
        physicalAddress: '',
        isPhysicalAndMailingAddressEqual: false,
        mailingAddress: '',
        mailingAddressCity: '',
        mailingAddressState: '',
        mailingAddressZipCode: '',
        additionalInfo: '',
      }}
      validationSchema={organizationFormValidationSchema}
      fetchEntityMethod={OrganizationService.getById}
      renderTitle={
        (form, mode) => (mode === FormMode.Create
          ? <>New Organization</>
          : (
            <>
              Organization:
              {' '}
              {form.watch('name')}
            </>
          ))
      }
      onSubmit={onSubmit}
      fields={generateFields()}
      backRoute={routes.organizations}
      editDisabled={!hasSuperAdminAccess}
    />
  );
}

export default OrganizationForm;
