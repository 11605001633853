import localforage from 'localforage';

class StateStorage<State> {
  private storageInstance: LocalForage;

  constructor(storageName = 'common') {
    this.storageInstance = localforage.createInstance({
      name: storageName,
    });
  }

  async saveState(stateName: string, state: State) {
    return this.storageInstance.setItem(stateName, JSON.stringify(state));
  }

  async loadState(stateName: string) {
    const state = await this.storageInstance.getItem<string>(stateName);

    return state ? JSON.parse(state) as State : undefined;
  }

  async clearState(stateName: string) {
    return this.storageInstance.removeItem(stateName);
  }

  async clear() {
    return this.storageInstance.clear();
  }
}

export default StateStorage;
export const commonStorage = new StateStorage();
