import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import isHighRisk from '@utils/isHighRisk';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import { yesNoOptions } from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.MedicationError], undefined>;

function getMedicationErrorFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = (field: keyof RequiredFields) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.MedicationError}.${field}`;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.MedicationError;
    }

    return form.watch('typeOfIncident') === IncidentType.MedicationError;
  };

  return {
    fields: [
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wereAppropriateMonitoringInterventions'),
          label: 'Were appropriate monitoring and/or interventions put in place timely?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didMedErrorResultInHospitalization'),
          label: 'Did the med error result in hospitalization?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didMedErrorResultInResidentDeath'),
          label: 'Did the med error result in resident death and/or permanent injury?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
    ],
    fileFields: [
      [
        {
          fullWidth: true,
          type: 'file',
          required: false,
          name: getFieldName('medicanTreatmentRecord'),
          label: 'Medication Treatment Record',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
    ],
  };
}

export default getMedicationErrorFields;
