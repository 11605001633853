import { AxiosInstance } from 'axios';

export type LawsuitTotalsByFacilities = {
  lawsuit: number;
  incomplete: number;
  closed: number;
};

function getTotalsByFacilities(api: AxiosInstance) {
  return (organizationId?: number, yearOfLawsuits?: number) => api.get<LawsuitTotalsByFacilities>(`/totals-by-facilities`, {
    params: {organization_id: organizationId, year_of_lawsuits: yearOfLawsuits},
  });
}

export default getTotalsByFacilities;
