import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '@components/page/PageTitle';
import { Box, Grid, Stack } from '@mui/material';
import StatBox from '@components/StatBox';
import IncidentService from '@api/IncidentService';
import IncidentsHighRiskTypes from '@components/charts/IncidentsHighRiskTypes';
import IncidentsByStatus from '@components/charts/IncidentsByTypeAndStatus';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import IncidentStatusByMonth from '@components/charts/IncidentStatusByMonth';
import IncidentReportsGrid from '../components/IncidentReportsGrid';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { Totals } from '@api/IncidentService/getFacilityTotals';
import { IncidentCountsByHighRiskTypes } from '@api/IncidentService/getCountsByHighRiskTypes';
import { IncidentByTypeAndStatus } from '@api/IncidentService/getFacilityIncidentsByTypeAndStatus';
import { IncidentStatusCountsByMonth } from '@api/IncidentService/getStatusByMonth';

function FacilityIncidentReports() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();
  
  const [incidentTotals, setIncidentTotals] =
    useState<Totals>({incident: NaN, incomplete: NaN, highRisk: NaN});
  
  const [incidentHighRiskTypes, setIncidentHighRiskTypes] =
    useState<IncidentCountsByHighRiskTypes[] | null>(null);
  
  const [facilityIncidentsByTypeAndStatus, setFacilityIncidentsByTypeAndStatus] =
    useState<IncidentByTypeAndStatus[] | null>(null);
  
  const [facilityHighRiskIncidentsByTypeAndStatus, setFacilityHighRiskIncidentsByTypeAndStatus] =
    useState<IncidentByTypeAndStatus[] | null>(null);
  
  const [incidentStatusByMonth, setIncidentStatusByMonth] =
    useState<IncidentStatusCountsByMonth[] | null>(null);

  const fetchIncidentTotals = () => {
    setIncidentTotals({incident: NaN, incomplete: NaN, highRisk: NaN});
    IncidentService.getFacilityTotals(facility!.id, selectedYear)
      .then(({data}) => setIncidentTotals(data))
      .catch((e) => {
        console.error(e);
        setIncidentTotals({incident: 0, highRisk: 0, incomplete: 0});
        throw e;
      });
  };

  const fetchIncidentHighRiskTypes = () => {
    setIncidentHighRiskTypes(null);
    IncidentService.getCountsByHighRiskTypes(facility!.id, selectedYear)
      .then(({data}) => setIncidentHighRiskTypes(data))
      .catch((e) => {
        console.error(e);
        setIncidentHighRiskTypes([]);
        throw e;
      });
  };

  const fetchFacilityIncidentsByTypeAndStatus = () => {
    setFacilityIncidentsByTypeAndStatus(null);
    IncidentService.getFacilityIncidentsByTypeAndStatus(facility!.id, undefined, selectedYear)
      .then(({data}) => setFacilityIncidentsByTypeAndStatus(data))
      .catch((e) => {
        console.error(e);
        setFacilityIncidentsByTypeAndStatus([]);
        throw e;
      });
  };

  const fetchFacilityHighRiskIncidentsByTypeAndStatus = () => {
    setFacilityHighRiskIncidentsByTypeAndStatus(null);
    IncidentService.getFacilityIncidentsByTypeAndStatus(facility!.id, RiskLevel.HighRisk, selectedYear)
      .then(({data}) => setFacilityHighRiskIncidentsByTypeAndStatus(data))
      .catch((e) => {
        console.error(e);
        setFacilityHighRiskIncidentsByTypeAndStatus([]);
        throw e;
      });
  };

  const fetchIncidentStatusByMonth = () => {
    setIncidentStatusByMonth(null);
    IncidentService.getStatusByMonth(facility!.id, selectedYear)
      .then(({data}) => setIncidentStatusByMonth(data))
      .catch((e) => {
        console.error(e);
        setIncidentStatusByMonth([]);
        throw e;
      });
  };

  const fetchData = async () => {
    fetchIncidentTotals();
    fetchIncidentHighRiskTypes();
    fetchFacilityIncidentsByTypeAndStatus();
    fetchFacilityHighRiskIncidentsByTypeAndStatus();
    fetchIncidentStatusByMonth();
  };
  
  useEffect(() => {
    fetchData().catch(console.error);
    setGridFilterItem({
      id: facility?.id,
      value: facility?.name,
      field: 'facility',
      operator: 'equals',
    })
  }, [facility?.id, selectedYear]);

  return (
    <Box component="main">
      <PageTitle>
        {facility?.name}
        : Incident Reports
      </PageTitle>

      <Grid container sx={{marginBottom: 3}} spacing={3}>
        <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
          <Stack direction="column" spacing={3}>
            <StatBox
              title="Total Incidents"
              loading={Number.isNaN(incidentTotals.incident)}
              count={incidentTotals.incident}
            />
            <StatBox
              title="Total Incomplete"
              loading={Number.isNaN(incidentTotals.incomplete)}
              count={incidentTotals.incomplete}
            />
            <StatBox
              title="Total High Risk Incident"
              loading={Number.isNaN(incidentTotals.highRisk)}
              count={incidentTotals.highRisk}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={8} md={4} lg={5} xl={5}>
          <IncidentsHighRiskTypes
            data={incidentHighRiskTypes}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={5} xl={5}>
          <IncidentsByStatus
            title={'Incident Completion Status'}
            data={facilityIncidentsByTypeAndStatus}
          />
        </Grid>

        <Grid item xs={0} sm={0} md={0} lg={0} xl={2} sx={{padding: '0 !important'}}></Grid>

        <Grid item xs={12} md={6} lg={6} xl={5}>
          <IncidentsByStatus title="High Risk Completion Status"
                             data={facilityHighRiskIncidentsByTypeAndStatus}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6} xl={5}>
          <IncidentStatusByMonth
            data={incidentStatusByMonth}
          />
        </Grid>
      </Grid>

      <IncidentReportsGrid incidentReportGridKey={'facility-incidents'} gridFilterItem={gridFilterItem}/>
    </Box>
  );
}

export default FacilityIncidentReports;
