import React, { ChangeEvent } from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FileList from './FileList';
import { useDisableFormProvider } from '../../providers/DisableFormProvider';

interface Props {
  label: string;
  fileUploadIdentifier: string;
  files: File[];
  uploadHandler: (event: ChangeEvent<object>) => void;
  removeFile: (filename: string, fileHash?: string, isUploadedFile?: boolean) => void;
  required?: boolean;
}

function UploadComponent(props: Props) {
  const theme = useTheme();
  const {disabled} = useDisableFormProvider();
  const {
    fileUploadIdentifier, label, files, uploadHandler, removeFile,
  } = props;

  return (
    <Stack spacing={0.5}>
      <div style={{color: disabled ? '#757575' : 'inherit'}}>
        <input
          onChange={uploadHandler}
          id={fileUploadIdentifier}
          type="file"
          multiple
          disabled={disabled}
          accept="application/*, image/*"
          style={{display: 'none'}}
        />
        <label htmlFor={fileUploadIdentifier}>
          <a
            className="btn btn-primary"
            style={{
              fontWeight: 600,
              textDecoration: 'underline',
              color: disabled ? '#757575' : theme.palette.primary.main,
              cursor: disabled ? 'default' : 'pointer',
            }}
          >
            {label}
          </a>
        </label>
      </div>
      <FileList disabled={disabled} files={files} removeFile={removeFile}/>

      {/* <Button */}
      {/*  component="label" */}
      {/*  htmlFor={fileUploadIdentifier} */}
      {/*  variant="outlined" */}
      {/*  size="small" */}
      {/*  style={{ */}
      {/*    width: 120, */}
      {/*    padding: 4, */}
      {/*  }} */}
      {/*  disabled={disabled} */}
      {/* > */}
      {/*  + Attach file */}
      {/* </Button> */}
    </Stack>
  );
}

export default UploadComponent;
