import { FacilityType } from '@declarations/common/enums/FacilityType';
import { Option } from '@declarations/common/option';

const facilityTypeOptions: Option<FacilityType>[] = [
  {
    value: FacilityType.SkilledNursing,
    label: 'Skilled Nursing',
  },
  {
    value: FacilityType.AssistedLiving,
    label: 'Assisted Living',
  },
  {
    value: FacilityType.CCRC,
    label: 'CCRC',
  },
  {
    value: FacilityType.IndependentLiving,
    label: 'Independent Living',
  },
  {
    value: FacilityType.MemoryCare,
    label: 'Memory Care / Alzheimer’s Specialty',
  },
];

export default facilityTypeOptions;
