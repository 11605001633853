import { Theme, useTheme as useMuiTheme } from '@mui/material/styles';
import { CustomShadows } from './shadows';

interface MyTheme extends Theme {
  customShadows: CustomShadows
}

const useTheme = () => useMuiTheme<MyTheme>();

export default useTheme;
