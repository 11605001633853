const routes = {
  loginPage: '/',
  login: '/login',
  thanks: '/thanks',
  forgot: '/forgot',
  recovery: '/recovery',

  dashboard: '/dashboard',
  // Reports
  incidents: '/incidents',
  medicalRecordRequests: '/medical-record-requests',
  lawsuits: '/lawsuits',
  //
  organizations: '/operators',
  facilities: '/facilities',
  userManagement: '/user-management',

  // redirect to site
  ltcRiskAndManagement: 'https://www.ltclegalrisk.com/',

  // User profile
  userProfile: '/profile',

  lawsuitForm: '/lawsuit-form',
  lawsuitProviderTab: '/lawsuit-form/provider',
  lawsuitClinicalTab: '/lawsuit-form/clinical-review',
  lawsuitLegalTab: '/lawsuit-form/legal-review',
  incidentReportForm: '/incident-report-form',
  incidentReportProviderTab: '/incident-report-form/provider',
  incidentReportClinicalTab: '/incident-report-form/clinical-review',
  incidentReportLegalTab: '/incident-report-form/legal-review',
  medicalRecordRequestForm: '/medical-record-request-form',
  medicalRecordRequestProviderTab: '/medical-record-request-form/provider',
  medicalRecordRequestClinicalTab: '/medical-record-request-form/clinical-review',
  medicalRecordRequestLegalTab: '/medical-record-request-form/legal-review',
  userForm: '/user-form',
  facilityForm: '/facility-form',
  organizationForm: '/organization-form',

  notFound: '/not-found',
};

export default routes;
