import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DataGridPro,
  DataGridProProps,
  GridValidRowModel,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFilterItem,
  GridCallbackDetails,
  GridFilterModel,
  MuiEvent,
  GridLogicOperator,
  GridToolbarQuickFilter, 
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import GridContextProvider from '@components/DataGrid/providers/GridContext';
import GridRow from './components/GridRow/GridRow';
import ColumnMenu from './components/ColumnMenu/ColumnMenu';
import * as cache from './utils/cache';
import { DefaultRowsPerPageOptions } from '../../consts';
import './DataGrid.css';
import { useGridModels } from '@components/DataGrid/hooks/useGridModels';
import { GridState } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import { getScrollBarWidth } from './utils/getScrollBarWidth';

type Props = {
  stateName?: string;
  gridFilterItem?: GridFilterItem;
  showQuickFilter?: boolean;
  gridHeight?: number;
  pinnedColumns?: string[];
  usersLoading?: boolean;
} & Partial<ReturnType<typeof useGridModels>>;

type DataGridProps<T extends GridValidRowModel> = Omit<DataGridProProps<T>, keyof Props> & Props;

const getToolbar = (showQuickFilter?: boolean) => () => {
  if (!showQuickFilter) {
    return null;
  }
  return (
    <Box p="8px 16px" display="flex" justifyContent="end">
      <GridToolbarQuickFilter debounceMs={600}/>
    </Box>
  );
};

export function DataGrid<T extends GridValidRowModel>({pinnedColumns, ...props}: DataGridProps<T>) {
  const {stateName, gridFilterItem, showQuickFilter, columns, usersLoading} = props;
  const apiRef = useGridApiRef();

  const updateState = useCallback(debounce(async (gridState: GridState, event: MuiEvent<{}>, details: GridCallbackDetails) => {
    if (!stateName || !props.stateLoaded || !apiRef.current) {
      return;
    }
    const state = apiRef.current.exportState();
    await cache.saveState(stateName, state);
  }, 600), [stateName, props.stateLoaded]);

  const filterModelChange = (gridFilterModel: GridFilterModel, gridCallbackDetails: GridCallbackDetails<'filter'>) => {
    if (gridFilterItem?.id && !gridFilterModel.items.some(i => i.field === gridFilterItem.field)) {
      gridFilterModel.items.unshift(gridFilterItem);
    }
    props.onFilterModelChange?.(gridFilterModel, gridCallbackDetails);
  };

  useEffect(() => {
    (async () => {
      if (stateName) {
        const state = await cache.loadState(stateName);
        if (state) {
          apiRef.current.restoreState(state);
        }
        if (!props.stateLoaded && !usersLoading) {
          props.setStateLoaded?.(true);
        }
      }
    })();
  }, [columns, usersLoading]);
  useEffect(() => {
    const resultFilterItems: GridFilterItem[] = [];
    if (gridFilterItem && gridFilterItem.id) {
      resultFilterItems.push(gridFilterItem);
    }
    const currentFilterItems = apiRef.current.state?.filter?.filterModel?.items;
    if (currentFilterItems && currentFilterItems.length) {
      if (gridFilterItem && gridFilterItem.field) {
        resultFilterItems.push(...currentFilterItems.filter(cfi => cfi.field !== gridFilterItem.field));
      } else {
        resultFilterItems.push(...currentFilterItems);
      }
    }
    apiRef.current.upsertFilterItems(resultFilterItems);
  }, [gridFilterItem, props.stateLoaded]);

  const height = useMemo(() => {
    if (props.gridHeight) {
      return props.gridHeight + getScrollBarWidth();
    } else {
      return props.gridHeight;
    }
  }, [props.gridHeight]);

  const Toolbar = useMemo(() => getToolbar(showQuickFilter), [showQuickFilter]);

  return (
    <Box sx={{maxWidth: '100%', overflow: 'auto', height}}>
      <GridContextProvider api={apiRef.current} stateName={stateName}>
        <DataGridPro
          // The solution explained here: https://github.com/mui/mui-x/issues/898#issuecomment-1498361362
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
          }}
          apiRef={apiRef}
          autoHeight={!props.gridHeight || (props.rows && props.rows.length < 10)}
          disableRowSelectionOnClick
          pagination
          pageSizeOptions={DefaultRowsPerPageOptions}
          paginationMode="server"
          sortingMode="server"
          // The callback when the data is loaded
          onRowsScrollEnd={(params, event, details) => {
            //Autosize columns on data load
            // (details as any).api.autosizeColumns({
            //   includeHeaders: false, includeOutliers: true,
            // });
          }}
          filterMode="server"
          autosizeOptions={{includeHeaders: false, includeOutliers: true}}
          slots={{
            row: GridRow,
            columnMenu: ColumnMenu,
            toolbar: Toolbar,
          }}
          slotProps={{
            filterPanel: {
              logicOperators: [GridLogicOperator.And],
            },
          }}
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...pinnedColumns || []],
            },
          }}
          onStateChange={updateState}
          {...props}
          onFilterModelChange={filterModelChange}
        />
      </GridContextProvider>
    </Box>
  );
}
