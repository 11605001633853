import { Option } from '@declarations/common/option';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { MedicalRecordRequest } from '@declarations/models/reports/MedicalRecordRequest';
import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { DEFAULT_BIRTHDAY } from '@constants/index';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { UserType } from '@declarations/common/enums/UserType';
import {
  primaryComplaintETCOptions, sexOptions, yesNoOptions,
} from '../const';
import isHighRisk from '../../utils/isHighRisk';
import LawsuitService from '@api/LawsuitService';
import IncidentService from '@api/IncidentService';
import { RequestingPersonOptions } from '@constants/options/RequestingPersonOptions';
import { IncidentTypeLabels } from '@constants/options/IncidentTypeOptions';
import { IncidentType } from '@declarations/common/enums/IncidentType';

type GenerateFieldParams = {
  facilities: Option[];
  userType: UserType;
};

function generateFields({ facilities, userType }: GenerateFieldParams) {
  return (
    form: UseFormReturn<MedicalRecordRequest>,
    mode: FormMode,
  ): Fields<MedicalRecordRequest> => {
    const calculatedStatus = form.getValues('calculatedStatus');
    const disabledField = calculatedStatus === ReportStatus.Completed && userType !== UserType.SuperAdmin;
    const disabledLinkedLawsuit = !form.watch('facility') || !form.watch('residentMedicalRecordNumber');
    const disabledLinkedIncident = disabledLinkedLawsuit || !form.watch('isRelatedToIncident');
    const MMRHintText = (disabledLinkedIncident && !disabledField)
      ? 'Please fill out Facility, Resident Medical Record Number, and answer "yes" to the question above to link an incident.'
      : undefined;
    
    return [
      [
        {
          type: 'select',
          required: true,
          name: 'facility',
          label: 'Facility',
          options: facilities,
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          required: true,
          name: 'residentMedicalRecordNumber',
          label: 'Resident Medical Record Number',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          name: 'isRelatedToIncident',
          label: 'Is this request for medical records related to an incident?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'selectWithDialog',
          name: 'linkedReports.incident',
          label: 'Link Incident',
          props: {
            disabled: disabledField || disabledLinkedIncident,
            id: form.watch('id'),
            reportType: 'medicalReport',
            facility: form.watch('facility'),
            residentMedicalRecordNumber: form.watch('residentMedicalRecordNumber'),
            linkPrefix: '/incident-report-form/?id=',
            dateField: 'incidentDate',
            hintText: MMRHintText,
            getReports: IncidentService.getRelations,
            getAdditionalField: (report: any) => IncidentTypeLabels[report.typeOfIncident as IncidentType],
            onChange: (e: any) => {
              if (e) {
                form.setValue('residentFirstName', e.residentFirstName);
                form.setValue('residentLastName', e.residentLastName);
                form.setValue('residentBirthdate', e.residentBirthdate);
                form.setValue('residentGender', e.residentGender);
                form.setValue('linkedReports.incident', {
                  id: e.id,
                  reportNumber: e.reportNumber,
                  incidentDate: e.incidentDate,
                });
                if (e.linkedReports?.lawsuit) {
                  form.setValue('linkedReports.lawsuit', {
                    id: e.linkedReports.lawsuit.id,
                    reportNumber: e.linkedReports.lawsuit.reportNumber,
                    noticeDate: e.linkedReports.lawsuit.noticeDate,
                  });
                }
              } else {
                form.setValue('linkedReports.incident', '');
              }
            },
          },
        },
        {
          type: 'selectWithDialog',
          name: 'linkedReports.lawsuit',
          label: 'Link Lawsuit',
          props: {
            disabled: disabledField || disabledLinkedLawsuit,
            id: form.watch('id'),
            reportType: 'medicalReport',
            facility: form.watch('facility'),
            residentMedicalRecordNumber: form.watch('residentMedicalRecordNumber'),
            linkPrefix: '/lawsuit-form/?id=',
            dateField: 'noticeDate',
            getReports: LawsuitService.getRelations,
            getAdditionalField: (report: any) => report.caseName,
            onChange: (e: any) => {
              if (e) {
                form.setValue('residentFirstName', e.residentFirstName);
                form.setValue('residentLastName', e.residentLastName);
                form.setValue('residentBirthdate', e.residentBirthdate);
                form.setValue('residentGender', e.residentGender);
                form.setValue('linkedReports.lawsuit', {
                  id: e.id,
                  reportNumber: e.reportNumber,
                  noticeDate: e.noticeDate,
                });
                if (e.linkedReports?.incident) {
                  form.setValue('linkedReports.incident', {
                    id: e.linkedReports.incident.id,
                    reportNumber: e.linkedReports.incident.reportNumber,
                    incidentDate: e.linkedReports.incident.incidentDate,
                  });
                }
              } else {
                form.setValue('linkedReports.lawsuit', '');
              }
            },
          },
        },
      ],
      [
        {
          type: 'text',
          required: true,
          name: 'residentFirstName',
          label: 'Resident First Name',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          required: true,
          name: 'residentLastName',
          label: 'Resident Last Name',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'residentBirthdate',
          label: 'Resident Date of Birth',
          props: {
            defaultCalendarMonth: DEFAULT_BIRTHDAY,
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          required: true,
          name: 'residentGender',
          label: 'Resident sex',
          options: sexOptions,
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'radio',
          required: true,
          name: 'isCurrentResident',
          label: 'Current Resident?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'admitDate',
          label: 'Admit Date',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'date',
          required: false,
          name: 'dischargeDate',
          label: 'Discharge Date',
          condition: () => form.watch('isCurrentResident') === false,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'requestDate',
          label: 'Date of Medical Record Request',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          required: true,
          name: 'requestedRecordsPerson',
          label: 'Person Requesting Medical Records',
          options: RequestingPersonOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          name: 'isRelatedToDeath',
          label: 'Is this request for medical records related to a recent or unplanned death?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          name: 'hasAnyVoicedComplaints',
          label: 'Have there been any voiced complaints, concerns, grievances, etc. expressed by the resident, family, or POA in the last 90 days?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          name: 'primaryComplain',
          label: 'What is the primary complaint, concern, grievance, etc.?',
          options: primaryComplaintETCOptions,
          condition: () => form.watch('hasAnyVoicedComplaints') === true,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          name: 'hasReleasedPursuant',
          label: 'Have the resident’s medical records been released pursuant to this request?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          name: 'releasedPursuantDate',
          required: false,
          label: 'Date Released',
          condition: () => form.watch('hasReleasedPursuant') === true,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'medicalRRCopyFiles',
          label: 'Letter of Request for Medical Records',
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'releasedPursuantFiles',
          label: 'Current Medical Records',
          condition: () => form.watch('hasReleasedPursuant') === true,
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
    ];
  };
}

export default generateFields;
