import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

type InferredArgs<F> = F extends (...args: infer A) => any ? A : never;
type InferredAxiosResponseData<F> = F extends (...args: any[]) => Promise<AxiosResponse<infer D>> ? D : never;

type UseApiParams<M> = {
  method: M;
  initialData: InferredAxiosResponseData<M>;
};

type UseApiReturn<M extends (...args: any[]) => Promise<AxiosResponse>> = {
  isLoading: boolean;
  error: Error | null;
  data: InferredAxiosResponseData<M>;
  fetch: (...args: InferredArgs<M>) => Promise<void>;
};

function useApi<M extends (...args: any[]) => Promise<AxiosResponse>>(
  params: UseApiParams<M>): {
  isLoading: boolean;
  data: M extends ((...args: any[]) => Promise<AxiosResponse<infer D>>) ? D : never;
  fetch: (...args: InferredArgs<M>) => Promise<AxiosResponse>;
  error: Error | null
} {
  const [data, setData] = useState<InferredAxiosResponseData<M>>(params.initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async (...args: InferredArgs<M>) => {
    try {
      setIsLoading(true);
      const response = await params.method(...args);
      setData(response.data as InferredAxiosResponseData<M>);
      return response;
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  }, [params.method]);

  return {
    isLoading,
    data,
    error,
    fetch: fetchData as (...args: InferredArgs<M>) => Promise<AxiosResponse>,
  };
}

export default useApi;
