import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import debounce from 'lodash/debounce';
import StateStorage, { commonStorage } from '@utils/stateStorage';

export default function useStateStorage<State>(
  defaultValue: State,
  stateName: string,
  storageName?: string,
) {
  const [initialStateLoaded, setInitialStateLoaded] = useState(false);
  const [state, setState] = useState<State>(defaultValue);
  const stateStorage = useRef(storageName ? new StateStorage<State>(storageName) : commonStorage);

  const loadState = async () => {
    const loadedState = await stateStorage.current.loadState(stateName) as State;

    if (loadedState !== undefined) {
      setState(loadedState);
    }
  };

  const saveState = useCallback(
    debounce(async (_stateName: string, _state: State) => {
      await stateStorage.current.saveState(_stateName, _state);
    }, 300),
    [stateStorage],
  );

  useEffect(() => {
    loadState().then(() => {
      setInitialStateLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (initialStateLoaded) {
      saveState(stateName, state);
    }
  }, [state]);

  return [state, setState];
}
