import React, { ReactNode } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Box, TextField } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { useDisableFormProvider } from '../providers/DisableFormProvider';

export type Props = Omit<TextFieldProps, 'error'> & {
  error?: FieldError | boolean
};

const HelperText = ({text, length, maxLength}: {text?: ReactNode, length?: number, maxLength: number}) => {
  return <Box display="flex" justifyContent="space-between">
    <span>{text}</span>
    <span>{length} / {maxLength}</span>
  </Box>
};

const TextFieldItem = React.forwardRef((props: Props, ref) => {
  const { disabled } = useDisableFormProvider();

  const renderError = () => {
    if (typeof props.error === 'object' && 'message' in props.error) return props.error.message;

    return undefined;
  };

  const text = props.helperText || renderError();
  const helperText = props.inputProps?.maxLength
    ? <HelperText text={text} length={(props.value as string)?.length || 0} maxLength={props.inputProps.maxLength} />
    : text;

  return (
    <TextField
      {...props}
      disabled={disabled || props.disabled}
      error={!!props.error && !disabled}
      helperText={helperText}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
});

TextFieldItem.displayName = 'TextFieldItem';

export default React.memo(TextFieldItem);
