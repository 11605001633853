import initInstance from '@api/axios';

const api = initInstance('/file');

const FileService = {
  links: {
    file: (hash: string) => api.getUri({ url: `/${hash}` }),
  },
  handleDownload: async (hash: string, onProgress: ((value: (((prevState: number) => number) | number)) => void)) => {
    const response = await api.get(`/${hash}`, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
        onProgress(percentCompleted);
      }
    });
    const contentDisposition = response.headers['content-disposition'];
    let filename = new Date().toDateString();
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }
};

export default FileService;
