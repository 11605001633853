import HeaderBar from './HederBar';
import { useSearchParams } from 'react-router-dom';
import { memo, useState } from 'react';
import { ReportType } from '@declarations/common/reportType';
import { Box, Button } from '@mui/material';
import ProviderLogRecordService from '@api/providerLogRecordService';
import { useForm } from 'react-hook-form';
import { serialize } from 'object-to-formdata';
import Field from '@components/CRUDForm/Field';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

type Props = {
  reportType: ReportType;
  onSave?: () => void;
};

function ProviderLogRecordForm({ reportType, onSave }: Props) {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const fields: Fields<any> = [
    {
      type: 'text',
      required: false,
      name: 'subject',
      label: 'Subject',
      props: {
        disabled: loading,
        inputProps: { maxLength: 56 },
      },
    },
    {
      type: 'text',
      required: false,
      name: 'note',
      label: 'Notes',
      props: {
        disabled: loading,
        multiline: true,
        minRows: 3,
        inputProps: { maxLength: 600 },
      },
    },
    {
      fullWidth: true,
      type: 'dropzone',
      name: 'attachments',
      label: 'Attachments',
      props: {
        disabled: loading,
        text: 'Drag and drop documents or images here, or click to choose files, each not exceeding 50MB in size.',
      },
    },
  ];

  const form = useForm<any>({
    defaultValues: { reportId: searchParams.get('id'), reportType, subject: null },
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = form.handleSubmit(data => {
    setLoading(true);
    const formData = serialize(data, {
      dotsForObjectNotation: false,
      nullsAsUndefineds: true,
    });
    ProviderLogRecordService.createRecord(formData)
      .then(() => {
        onSave && onSave();
        form.reset();
      })
      .catch(error => {
        if (error instanceof AxiosError) {
          const { response } = error;
          if (response && response.status === 400) {
            if ('message' in response.data) {
              toast.error(response.data.message);
            }
            if ('fields' in response.data) {
              response.data.fields.forEach(({ field }: { field: string; errors: string[] }) => {
                form.setError(field as any, {});
              });
            }
            return;
          }
        }
        toast.error('Unable to save entity');
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return (
    <>
      <HeaderBar
        title="Provider"
        description="Add additional notes and/or upload additional documents you would like to capture for this incident. Info such as witness contact info, police reports, family statements, etc."
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', mt: '32px', maxWidth: '600px' }}>
        {fields.map(field => (
          <Field key={field.name} field={field} form={form} />
        ))}
        <Box>
          <Button disabled={loading} variant="contained" onClick={onSubmit}>
            Complete
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default memo(ProviderLogRecordForm);
