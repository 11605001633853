import { Theme } from '@mui/material/styles';

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000',
          transform: 'translate(14px, 12px) scale(1)',
        },
        outlined: {
          fontSize: '1em',
          '&.MuiInputLabel-sizeSmall': {
            lineHeight: '1em',
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
            background: theme.palette.background.paper,
            padding: '0 8px',
            marginLeft: -6,
            lineHeight: '1.4375em',
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500],
          },
        },
        formControl: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(2px, 2px) scale(0.75)',
            background: theme.palette.background.paper,
            padding: '0 8px',
            marginLeft: -6,
            lineHeight: '1.4375em',
          },
        },
      },
    },
    // MuiFormControlLabel: {
    //   styleOverrides: {
    //     disabled: {
    //       color: '#d00',
    //     },
    //   },
    // },
  };
}
