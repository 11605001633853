import { useState } from 'react';
import { GridFilterModel, DataGridProProps } from '@mui/x-data-grid-pro';

type UseFilterModelReturnType = Pick<DataGridProProps, 'filterModel' | 'onFilterModelChange'>;

export function useFilterModel(initialState?: GridFilterModel): UseFilterModelReturnType {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: initialState?.items ?? [],
    quickFilterValues: initialState?.quickFilterValues ?? [],
    quickFilterLogicOperator: initialState?.quickFilterLogicOperator,
  });

  return {
    filterModel,
    onFilterModelChange: (filter: GridFilterModel) => setFilterModel(filter),
  };
}
