import { AxiosInstance } from 'axios';
import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';

export type MedicalCountsByHighRiskRequesters = {
  requester: RequestedMedicalRecordsPerson;
  count: number;
};

function getCountsByHighRiskRequesters(api: AxiosInstance) {
  return (facilityId: number, yearOfMrrs?: number) => api.get<MedicalCountsByHighRiskRequesters[]>('/counts-by-high-risk-requesters', {
    params: { facility_id: facilityId, year_of_mrrs: yearOfMrrs },
  });
}

export default getCountsByHighRiskRequesters;
