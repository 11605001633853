import { Option } from '@declarations/common/option';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { DEFAULT_BIRTHDAY } from '@constants/index';
import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { UserType } from '@declarations/common/enums/UserType';
import { sexOptions, yesNoOptions, yesNoUnknownOptions } from '../const';
import IncidentService from '@api/IncidentService';
import MedicalRRService from '@api/MedicalRRService';
import { RequestingPersonLabels } from '@constants/options/RequestingPersonOptions';
import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';
import { IncidentTypeLabels } from '@constants/options/IncidentTypeOptions';
import { IncidentType } from '@declarations/common/enums/IncidentType';

type GenerateFieldParams = {
  facilities: Option[];
  userType: UserType;
};

function generateFields({ facilities, userType }: GenerateFieldParams) {
  return (
    form: UseFormReturn<Lawsuit>,
    mode: FormMode,
  ): Fields<Lawsuit> => {
    const calculatedStatus = form.getValues('calculatedStatus');
    const disabledField = calculatedStatus === ReportStatus.Completed && userType !== UserType.SuperAdmin;
    const disabledLinkedReports = !form.watch('facility') || !form.watch('residentMedicalRecordNumber');

    return [
      [
        {
          type: 'select',
          required: true,
          name: 'facility',
          label: 'Facility',
          options: facilities,
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          required: true,
          name: 'residentMedicalRecordNumber',
          label: 'Resident Medical Record Number',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'selectWithDialog',
          name: 'linkedReports.incident',
          label: 'Link Incident',
          props: {
            disabled: disabledField || disabledLinkedReports,
            id: form.watch('id'),
            reportType: 'lawsuit',
            facility: form.watch('facility'),
            residentMedicalRecordNumber: form.watch('residentMedicalRecordNumber'),
            linkPrefix: '/incident-report-form/?id=',
            dateField: 'incidentDate',
            getReports: IncidentService.getRelations,
            getAdditionalField: (report: any) => IncidentTypeLabels[report.typeOfIncident as IncidentType],
            onChange: (e: any) => {
              if (e) {
                form.setValue('residentFirstName', e.residentFirstName);
                form.setValue('residentLastName', e.residentLastName);
                form.setValue('residentBirthdate', e.residentBirthdate);
                form.setValue('residentGender', e.residentGender);
                form.setValue('linkedReports.incident', {
                  id: e.id,
                  reportNumber: e.reportNumber,
                  incidentDate: e.incidentDate,
                });
                if (e.linkedReports?.medicalReport) {
                  form.setValue('linkedReports.medicalReport', {
                    id: e.linkedReports.medicalReport.id,
                    reportNumber: e.linkedReports.medicalReport.reportNumber,
                    noticeDate: e.linkedReports.medicalReport.noticeDate,
                  });
                }
              } else {
                form.setValue('linkedReports.incident', '');
              }
            },
          },
        },
        {
          type: 'selectWithDialog',
          name: 'linkedReports.medicalReport',
          label: 'Link Medical Record Request',
          props: {
            disabled: disabledField || disabledLinkedReports,
            id: form.watch('id'),
            reportType: 'lawsuit',
            facility: form.watch('facility'),
            residentMedicalRecordNumber: form.watch('residentMedicalRecordNumber'),
            linkPrefix: '/medical-record-request-form/?id=',
            dateField: 'requestDate',
            getReports: MedicalRRService.getRelations,
            getAdditionalField: (report: any) => RequestingPersonLabels[report.requestedRecordsPerson as RequestedMedicalRecordsPerson],
            onChange: (e: any) => {
              if (e) {
                form.setValue('residentFirstName', e.residentFirstName);
                form.setValue('residentLastName', e.residentLastName);
                form.setValue('residentBirthdate', e.residentBirthdate);
                form.setValue('residentGender', e.residentGender);
                form.setValue('linkedReports.medicalReport', {
                  id: e.id,
                  reportNumber: e.reportNumber,
                  requestDate: e.requestDate,
                });
                if (e.linkedReports?.incident) {
                  form.setValue('linkedReports.incident', {
                    id: e.linkedReports.incident.id,
                    reportNumber: e.linkedReports.incident.reportNumber,
                    noticeDate: e.linkedReports.incident.noticeDate,
                  });
                }
              } else {
                form.setValue('linkedReports.medicalReport', '');
              }
            },
          },
        },
      ],
      [
        {
          type: 'text',
          required: true,
          name: 'caseName',
          label: 'Case Name',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'text',
          required: true,
          name: 'residentFirstName',
          label: 'Resident First Name',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          required: true,
          name: 'residentLastName',
          label: 'Resident Last Name',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'residentBirthdate',
          label: 'Resident Date of Birth',
          props: {
            defaultCalendarMonth: DEFAULT_BIRTHDAY,
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          required: true,
          name: 'residentGender',
          label: 'Resident sex',
          options: sexOptions,
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'radio',
          required: true,
          name: 'isCurrentResident',
          label: 'Current Resident?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'admitDate',
          label: 'Admit Date',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'date',
          required: true,
          name: 'dischargeDate',
          label: 'Discharge Date',
          condition: ({ form }) => form.watch('isCurrentResident') === false,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          name: 'noticeDate',
          label: 'Date of Lawsuit Notice',
          required: true,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'lawsuitCopyFiles',
          label: 'Lawsuit Notification',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          type: 'radio',
          name: 'hasDocumentsReleasedToLawFirm',
          label: 'Have any documents or medical records been released to the attorney/law firm on the Notice of Lawsuit?',
          options: yesNoUnknownOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          condition: ({ form }) => form.watch('hasDocumentsReleasedToLawFirm') === 1,
          type: 'date',
          name: 'releasedDocumentsDate',
          label: 'Date Released',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          fullWidth: true,
          condition: ({ form }) => form.watch('hasDocumentsReleasedToLawFirm') === 1,
          type: 'file',
          name: 'releasedDocuments',
          label: 'Records released pursuant to this request',
        },
      ],
      [
        {
          type: 'text',
          name: 'lawyer',
          label: 'Lawyer',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          name: 'lawFirm',
          label: 'Law firm',
          props: {
            disabled: disabledField,
          },
        },
      ],
    ];
  };
}

export default generateFields;
