import React, { useEffect, useMemo, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartData } from 'chart.js/dist/types';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import getOptionLabel from '@utils/getOptionLabel';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import { IncidentByTypeAndStatus } from '@api/IncidentService/getFacilityIncidentsByTypeAndStatus';
import IncidentTypeOptions from '@constants/options/IncidentTypeOptions';
import ChartContainer from '@components/charts/ChartContainer';
import calculateAxisMaxValue from '@utils/calculateAxisMaxValue';
import getAxisMaxValue from '@utils/getAxisMaxValue';

type IncidentsByStatusProps = {
  data: IncidentByTypeAndStatus[] | null;
  title: string;
};

function IncidentsByTypeAndStatus({data, title}: IncidentsByStatusProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  const maxXValue: number = data ? calculateAxisMaxValue(data, 'countsByStatus') : 0;

  const options = useMemo<ChartProps<'bar'>['options']>(() => ({
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {stepSize: 1},
        max: getAxisMaxValue(maxXValue),
      },
      y: {
        ticks: {autoSkip: false, padding: 10},
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        },
      },
    },
  }), [title, maxXValue]);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: IncidentTypeOptions.map(ito => ito.label),
        datasets: Object.values(ReportStatus).map((value) => ({
          label: getOptionLabel(ReportStatusOptions, value),
          data: IncidentTypeOptions.map(ito => {
            const incidentByTypeAndStatus = data.find(ibtas => ibtas.type === ito.value);
            return !incidentByTypeAndStatus
              ? 0
              : (incidentByTypeAndStatus.countsByStatus[value] ?? 0);
          }),
          backgroundColor: ReportStatusColors[value],
          stack: 'x',
          barPercentage: 0.7,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options}/>
    </ChartContainer>
  );
}

export default React.memo(IncidentsByTypeAndStatus);
