import React from 'react';
import { Container, Typography } from '@mui/material';
import routes from '../constants/routes';

function Page404() {
  return (
    <Container maxWidth={false} sx={{ marginTop: '1rem' }}>
      <Typography variant="h5">Page not found</Typography>
      <a href={routes.loginPage}>Go to login page</a>
    </Container>
  );
}

export default Page404;
