export enum LawsuitStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  ReOpened = 'RE_OPENED',
}

export const LawsuitStatusColors = {
  [LawsuitStatus.Closed]: '#197CB6',
  [LawsuitStatus.Open]: '#A5D7F4',
  [LawsuitStatus.ReOpened]: '#FFDE7A',
}