import React from 'react';
import replicaLogo from '../../assets/images/REPLICA-Logo-no-background.png';
import { Box } from '@mui/material';

const ReplicaLogoBox: () => JSX.Element = () => (
  <Box
    component="img"
    marginY={2}
    paddingRight={17}
    alt="The REPLICA logo"
    src={replicaLogo}
  />
);

export default React.memo(ReplicaLogoBox);
