import { UserType } from '@declarations/common/enums/UserType';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import { useMemo } from 'react';

export type Access = {
  types: UserType[];
};

export default function useAccess(access: Access) {
  const userType = useSelector(UserSelectors.type);

  const hasAccess = useMemo(() => {
    if (access.types?.length) {
      return access.types.includes(userType);
    }

    return true;
  }, [userType, access]);

  return { hasAccess };
}
