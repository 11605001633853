import { Color } from 'chart.js';

export const DefaultRowsPerPageOptions = [10, 25, 50];

export const NAV_DRAWER_WIDTH = 332;

export const isLocalhost = () => window.location.hostname.includes('localhost');

export const minButtonWidth = { minWidth: 100 };

export const defaultModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  py: 4,
  px: 4,
};

export const FORM_SPACING = 3;
export const FORM_MAX_WIDTH = 600;

export const MIN_COLUMN_SIZE = 320;

export const TWO_COLUMNS_SIZE = MIN_COLUMN_SIZE * 2 + 24;

export const statesUSA = [
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'AS',
    label: 'American Samoa',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DC',
    label: 'District of Columbia',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
];

export const graphColorPalette: Array<Color> = ['#406d79',
  '#d07e62',
  '#6dd3ad',
  '#733167',
  '#b3c57e',
  '#59569b',
  '#549539',
  '#c795d7',
  '#99a196',
  '#b22f61',
  '#4c8f6b',
  '#f797b4',
  '#cbdfcb',
  '#e0b781',
  '#433f61',
  '#9d8339',
  '#6c97d4',
  '#843b25',
  '#3f8ebf',
  '#db9d56',
  '#d2b7c3',
  '#693c3f',
  '#84b4aa',
  '#8d7c96',
  '#91896c',
];
