import React from 'react';
import {
  Card, CardContent, CircularProgress, Typography,
} from '@mui/material';

type StatBoxProps = {
  title: string;
  count: number | string;
  fullWidth?: boolean;
  loading?: boolean;
};

function StatBox({
  title, count, fullWidth, loading,
}: StatBoxProps) {
  return (
    <Card variant="outlined" sx={{ width: fullWidth ? '100%' : '180px', minHeight:'103.5px', boxShadow: 3 }}>
      <CardContent sx={{ padding: 1.5, paddingBottom: 1.5, textAlign: 'center' }}>
        {
          loading
            ? <CircularProgress sx={{width: '100px', height: '100px'}} />
            : <Typography sx={{ fontSize: '30px' }}>{count}</Typography>
        }
        <Typography sx={{ fontSize: '14px' }} color="text.secondary">{title}</Typography>
      </CardContent>
    </Card>
  );
}

export default React.memo(StatBox);
