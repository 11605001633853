export default function RiskLevel({ risk }: {risk: number}) {
  let label = 'High-Risk';
  let color = 'red';

  if (risk <= 6) {
    label = 'Low-Risk';
    color = '#00B050';
  } else if (risk <= 9) {
    label = 'Medium-Risk';
    color = 'orange';
  }

  return (
    <span style={{ color, whiteSpace: 'nowrap' }}>{label}</span>
  );
}