import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import React from 'react';
import IdLink from '@components/IdLink';
import routes from '@constants/routes';
import { Organization } from '@declarations/models/Organization';
import { organizationTypes, providerTypes } from '../const';
import { filterRequiredOperators } from '@components/DataGrid/utils/gridOperators';

export const organizationsColumns: GridColDef<Organization>[] = [
  {
    field: 'name',
    align: 'left',
    headerName: 'Organization Name',
    minWidth: 160,
    filterOperators: filterRequiredOperators(getGridStringOperators()),
    renderCell: (params: GridRenderCellParams<Organization>) => (
      <IdLink
        id={params.row.id}
        href={routes.organizationForm}
        value={params.row.name}
      />
    ),
  },
  {
    field: 'prefix',
    align: 'left',
    headerName: 'Organization Prefix',
    minWidth: 150,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'type',
    align: 'left',
    headerName: 'Organization Type',
    minWidth: 160,
    renderCell: (params: GridRenderCellParams<Organization>) => organizationTypes.find((type) => type.value === params.row.type)?.label,
    type: 'singleSelect',
    valueOptions: organizationTypes,
    filterOperators: filterRequiredOperators(getGridSingleSelectOperators()),
  },
  {
    field: 'providerType',
    align: 'left',
    headerName: 'Provider type',
    minWidth: 140,
    renderCell: (params: GridRenderCellParams<Organization>) => providerTypes.find((type) => type.value === params.row.providerType)?.label,
    type: 'singleSelect',
    valueOptions: providerTypes,
    filterOperators: filterRequiredOperators(getGridSingleSelectOperators()),
  },
  {
    field: 'primaryContact',
    align: 'left',
    headerName: 'Primary Contact',
    minWidth: 120,
  },
  {
    field: 'contactNumber',
    align: 'left',
    headerName: 'Contact Number',
    minWidth: 160,
  },
  {
    field: 'city',
    align: 'left',
    headerName: 'City',
    minWidth: 100,
  },
  {
    field: 'state',
    align: 'left',
    headerName: 'State',
    minWidth: 80,
  },
  {
    field: 'additionalInfo',
    align: 'left',
    headerName: 'Additional Information',
    minWidth: 200,
  },
];
