import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { ListWithPagination } from '@declarations/common/pagination';
import { User } from '@declarations/models/User';
import { BaseReport } from '@declarations/models/reports/base';
import { AxiosResponse } from 'axios';

export default function setExpertUsers<T extends BaseReport>(res: AxiosResponse<ListWithPagination<T>>): AxiosResponse<ListWithPagination<T>> {
  res.data.items = res.data.items.map((baseReport: T) => {
    const clinicalReviewer = baseReport.expertUsers.find(
      (u: User) => u.expertRole === ExpertRole.ClinicalReviewer,
    );
    baseReport.clinicalReviewer = clinicalReviewer
      ? JSON.stringify({
          id: clinicalReviewer.id,
          name: `${clinicalReviewer.firstname} ${clinicalReviewer.lastname}`,
        })
      : '';
    const legalReviewer = baseReport.expertUsers.find(
      (u: User) => u.expertRole === ExpertRole.LegalReviewer,
    );
    baseReport.legalReviewer = legalReviewer
      ? JSON.stringify({
          id: legalReviewer.id,
          name: `${legalReviewer.firstname} ${legalReviewer.lastname}`,
        })
      : '';
    return baseReport;
  });

  return res;
}
