import React from 'react';
import { Stack } from '@mui/material';
import StatBox from '@components/StatBox';
import { Totals } from '@api/ReportService/getTotals';

export type DashboardTotalChipsProps = {
  totals: Totals;
}

function DashboardTotalChips({totals}: DashboardTotalChipsProps) {
  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="space-around" gap={2}>
        <StatBox
          title="Total Incidents"
          loading={Number.isNaN(totals.medical)}
          count={totals.incident}
        />
        <StatBox
          title="Total High Risk Incidents"
          loading={Number.isNaN(totals.incidentHighRisk)}
          count={totals.incidentHighRisk}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-around" gap={2}>
        <StatBox
          title="Total MRR"
          loading={Number.isNaN(totals.medical)}
          count={totals.medical}
        />
        <StatBox
          title="Total High Risk MRR"
          loading={Number.isNaN(totals.medicalHighRisk)}
          count={totals.medicalHighRisk}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-around" gap={2}>
        <StatBox
          title="Total Lawsuit"
          loading={Number.isNaN(totals.lawsuit)}
          count={totals.lawsuit}
        />
        <StatBox
          title="Lawsuit Open"
          loading={Number.isNaN(totals.lawsuitOpen)}
          count={totals.lawsuitOpen}
        />
      </Stack>
    </React.Fragment>
  );
}

export default React.memo(DashboardTotalChips);