import { AxiosInstance } from 'axios';
import { IncidentType } from '@declarations/common/enums/IncidentType';

export type IncidentCountsByHighRiskTypes = {
  type: IncidentType;
  count: number;
};

function getCountsByHighRiskTypes(api: AxiosInstance) {
  return (facilityId: number, yearOfIncidents?: number) => api.get<IncidentCountsByHighRiskTypes[]>('/counts-by-high-risk-types', {
    params: { facility_id: facilityId, year_of_incidents: yearOfIncidents },
  });
}

export default getCountsByHighRiskTypes;
