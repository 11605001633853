import { AxiosInstance } from 'axios';

export type LawsuitsCountsByFacility = {
  facilityId: number;
  facilityName: string;
  count: number;
};

export default function getLawsuitCountsByFacility(api: AxiosInstance) {
  return (organizationId?: number, year?: number) => api.get<LawsuitsCountsByFacility[]>('/counts-by-facility', {
    params: {organization_id: organizationId, year_of_lawsuits: year},
  });
}
