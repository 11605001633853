import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/system';
import { Menu, MenuItem, MenuItemProps } from '@mui/material';
import MoreActionsList from '@components/CRUDForm/MoreActions/MoreActionsList';
import MoreActionsButton from './MoreActionsButton';

type ActionType = {
  icon?: React.ReactNode
  title?: React.ReactNode
  onClick?: MenuItemProps['onClick']
};

type Action = ActionType;

export type MoreActionsProps = {
  actions?: Action[]
};

function Index({ actions }: MoreActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!actions?.length) {
    return null;
  }

  return (
    <Box>
      <MoreActionsButton
        variant="outlined"
        size="small"
        disableElevation
        onClick={handleClick}
      >
        <MoreVertIcon />
      </MoreActionsButton>

      <MoreActionsList
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          actions.map((action, index) => (
            <MenuItem key={index} onClick={action.onClick}>
              {action.icon}
              {action.title}
            </MenuItem>
          ))
        }
      </MoreActionsList>
    </Box>
  );
}

export default React.memo(Index);
