import {
  FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, SelectProps, TextField,
} from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useDisableFormProvider } from '../../providers/DisableFormProvider';

type Option = {
  label: string
  value: string
};

type Props = Omit<SelectProps, 'error'> & {
  error?: FieldError | boolean
  options: Option[]
  inputLabel?: boolean
};

const SingleSelect = React.forwardRef(({
  options,
  inputLabel = true,
  error,
  ...props
}: Props, ref) => {
  const { disabled } = useDisableFormProvider();

  const renderError = () => {
    if (typeof error === 'object' && 'message' in error) return error.message;

    return undefined;
  };

  const selectDisabled = disabled || props.disabled;

  return (
    <FormControl fullWidth={props.fullWidth ?? true} error={!!error} disabled={selectDisabled}>
      {
        inputLabel
          ? <InputLabel shrink required={props.required} error={!!error}>{props.label}</InputLabel>
          : <FormLabel sx={{ mb: 1 }}>{props.label}</FormLabel>
      }

      <Select
        fullWidth
        {...props}
        error={!!error}
        disabled={selectDisabled}
        label={inputLabel ? props.label : null}
        ref={ref}
        value={props.value ?? ''}
      >
        {
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        }
      </Select>
      <FormHelperText>{renderError()}</FormHelperText>
    </FormControl>
  );
});

SingleSelect.displayName = 'SingleSelect';

export default SingleSelect;
