import {Lawsuit} from '@declarations/models/reports/Lawsuit';
import moment from 'moment';
import {
  object, date, string,
} from 'yup';
import Errors from '@constants/locale/errors';
import baseReportValidationSchema from '../../../../utils/validation-schemas/baseReportValidationSchema';

// TODO: add SchemaOf<Lawsuit> for all fields
const lawsuitFormValidationSchema = object({
  ...baseReportValidationSchema,
  caseName: string().required(Errors.REQUIRED_FIELD),

  admitDate: date()
    .nullable()
    .test({
      message: 'The Admission date can not be after the Date of Notice',
      test: (value, context) => {
        const parent = context.parent as Lawsuit;

        if (!parent.noticeDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrBefore(parent.noticeDate, 'day');
      },
    })
    .transform(v => (v instanceof Date && !isNaN(v as any) ? v : null))
    .required(Errors.REQUIRED_FIELD)
    .typeError(Errors.VALID_DATE),

  noticeDate: date()
    .test({
      message: 'The Notice date can not be prior to the Admission date',
      test: (value, context) => {
        const parent = context.parent as Lawsuit;

        if (!parent.admitDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrAfter(parent.admitDate, 'day');
      },
    })
    .transform(v => (v instanceof Date && !isNaN(v as any) ? v : null))
    .required(Errors.REQUIRED_FIELD)
    .typeError(Errors.VALID_DATE),

  dischargeDate: date()
    .when('isCurrentResident', (isCurrentResident, schema) => !isCurrentResident ? schema.required(Errors.REQUIRED_FIELD) : schema.nullable())
    .transform(v => (v instanceof Date && !isNaN(v as any) ? v : null))
    .typeError(Errors.VALID_DATE),
});

export default lawsuitFormValidationSchema;
