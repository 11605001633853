import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from '@components/page/PageTitle';
import LawsuitReportsGrid from '../components/LawsuitReportsGrid';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import LawsuitsTotalChips from './lawsuitsTotalChips';
import LawsuitService from '@api/LawsuitService';
import { LawsuitTotalsByFacilities } from '@api/LawsuitService/getTotalsByFacilities';
import { FacilitiesLawsuitsByStatus } from '@api/LawsuitService/getFacilitiesLawsuitsCountsByStatus';
import {
  FacilitiesLawsuitsByLawsuitStatus,
} from '@api/LawsuitService/getFacilitiesLawsuitsCountsByLawsuitStatus';
import LawsuitsCountByStatus from '@components/charts/adminsCharts/lawsuits/LawsuitsCountByStatus';
import LawsuitCountByOwnStatus from '@components/charts/adminsCharts/lawsuits/LawsuitCountByOwnStatusProps';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';

function TotalLawsuitReports() {

  const selectedFacilities = useSelector(SettingsSelectors.facilitiesFromSelectedOrganization);
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();

  const pageTitle = useMemo<string>(() => {
    if (!selectedFacilities) {
      return 'Facilities';
    }
    return selectedFacilities.length === 1 ? '1 Facility' : `${selectedFacilities.length} Facilities`;
  }, [selectedFacilities?.length]);

  const [lawsuitTotals, setLawsuitTotals] =
    useState<LawsuitTotalsByFacilities>({lawsuit: NaN, closed: NaN, incomplete: NaN});

  const [facilitiesLawsuitsCountsByStatus, setFacilitiesLawsuitsCountsByStatus] =
    useState<FacilitiesLawsuitsByStatus[] | null>(null);

  const [facilitiesLawsuitsCountsByLawsuitStatus, setFacilitiesLawsuitsCountsByLawsuitStatus] =
    useState<FacilitiesLawsuitsByLawsuitStatus[] | null>(null);
  const fetchData = async () => {
    fetchLawsuitsTotal();
    fetchFacilitiesLawsuitsCountsByStatus();
    fetchFacilitiesLawsuitsCountsByLawsuitStatus();
  };

  useEffect(() => {
    fetchData().catch(console.error);
    setGridFilterItem({
      id: selectedOrganization?.id,
      value: selectedOrganization?.name,
      field: 'operator',
      operator: 'equals',
    });
  }, [selectedOrganization?.id, selectedYear]);
  const onLawsuitsRemoved = async (ids: number[]) => {
    fetchData().catch(console.error);
  };

  const onLawsuitPatched = async (newValues: Partial<Lawsuit>) => {
    if (newValues.lawsuitStatus || newValues.calculatedStatus !== undefined) {
      fetchData().catch(console.error);
    }
  };

  const fetchLawsuitsTotal = () => {
    setLawsuitTotals({lawsuit: NaN, closed: NaN, incomplete: NaN});
    LawsuitService.getTotalsByFacilities(selectedOrganization?.id, selectedYear)
      .then(({data}) => setLawsuitTotals(data))
      .catch((e) => {
        console.error(e);
        setLawsuitTotals({lawsuit: 0, closed: 0, incomplete: 0});
        throw e;
      });
  };

  const fetchFacilitiesLawsuitsCountsByStatus = () => {
    setFacilitiesLawsuitsCountsByStatus(null);
    LawsuitService.getFacilitiesLawsuitsCountsByStatus(selectedOrganization?.id, selectedYear)
      .then(({data}) => setFacilitiesLawsuitsCountsByStatus(data))
      .catch((e) => {
        console.error(e);
        setFacilitiesLawsuitsCountsByStatus([]);
        throw e;
      });
  };

  const fetchFacilitiesLawsuitsCountsByLawsuitStatus = () => {
    setFacilitiesLawsuitsCountsByLawsuitStatus(null);
    LawsuitService.getFacilitiesLawsuitsCountsByLawsuitStatus(selectedOrganization?.id, selectedYear)
      .then(({data}) => setFacilitiesLawsuitsCountsByLawsuitStatus(data))
      .catch((e) => {
        console.error(e);
        setFacilitiesLawsuitsCountsByStatus([]);
        throw e;
      });
  };

  return (
    <Box component="main">
      <PageTitle>{pageTitle}</PageTitle>
      <LawsuitsTotalChips totals={lawsuitTotals}></LawsuitsTotalChips>
      <Grid container sx={{marginBottom: 5}} spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
          <LawsuitsCountByStatus data={facilitiesLawsuitsCountsByStatus}/>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
          <LawsuitCountByOwnStatus data={facilitiesLawsuitsCountsByLawsuitStatus}/>
        </Grid>
      </Grid>
      <LawsuitReportsGrid lawsuitsReportGridKey={'total-lawsuits'} gridFilterItem={gridFilterItem}
                          onLawsuitsRemoved={onLawsuitsRemoved}
                          onLawsuitPatched={onLawsuitPatched}/>
    </Box>
  );
}

export default TotalLawsuitReports;
