import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Organization } from '@declarations/models/Organization';
import { Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { statesUSA } from '../../consts';
import { organizationTypes, providerTypes } from '../const';

function generateFields() {
  return (
    form: UseFormReturn<Organization>,
  ): Fields<Organization> => [
    [
      {
        type: 'text', required: true, name: 'name', label: 'Organization Name',
      },
      {
        type: 'text', required: true, name: 'prefix', label: 'Organization Prefix',
      },
    ],
    [
      {
        type: 'select',
        required: true,
        name: 'type',
        label: 'Organization Type',
        options: organizationTypes,
      },
      {
        type: 'select',
        required: true,
        name: 'providerType',
        label: 'Provider Type',
        options: providerTypes,
      },
    ],
    [
      {
        type: 'text', name: 'primaryContact', label: 'Primary Contact',
      },
      {
        type: 'text', name: 'primaryContactEmail', label: 'Primary Contact Email',
      },
    ],
    [
      {
        type: 'text', name: 'contactNumber', label: 'Contact Number',
      },
    ],
    [
      {
        type: 'label', label: 'Physical Address',
      },
    ],
    [
      {
        type: 'text', name: 'physicalAddress', label: 'Address Line',
      },
      {
        type: 'text', name: 'city', label: 'City',
      },
      {
        type: 'select', name: 'state', label: 'State', options: statesUSA,
      },
      {
        type: 'text',
        name: 'zipCode',
        label: 'Zip Code',
      },
    ],
    [
      {
        type: 'checkbox',
        name: 'isPhysicalAndMailingAddressEqual',
        label: 'Mailing address is the same as the Physical address',
        fullWidth: true,
      },
    ],
    [
      {
        type: 'label',
        label: 'Mailing Address',
        hidden: form.watch('isPhysicalAndMailingAddressEqual'),
      },
    ],
    [
      {
        type: 'text',
        name: 'mailingAddress',
        label: 'Address Line',
        hidden: form.watch('isPhysicalAndMailingAddressEqual'),
        props: {
          value: form.watch('isPhysicalAndMailingAddressEqual') ? '' : form.watch('mailingAddress'),
        },
      },
      {
        type: 'text',
        name: 'mailingAddressCity',
        label: 'City',
        hidden: form.watch('isPhysicalAndMailingAddressEqual'),
        props: {
          value: form.watch('isPhysicalAndMailingAddressEqual') ? '' : form.watch('mailingAddressCity'),
        },
      },
      {
        type: 'select',
        name: 'mailingAddressState',
        label: 'State',
        options: statesUSA,
        hidden: form.watch('isPhysicalAndMailingAddressEqual'),
        props: {
          value: form.watch('isPhysicalAndMailingAddressEqual') ? '' : form.watch('mailingAddressState'),
        },
      },
      {
        type: 'text',
        name: 'mailingAddressZipCode',
        label: 'Zip Code',
        hidden: form.watch('isPhysicalAndMailingAddressEqual'),
        props: {
          value: form.watch('isPhysicalAndMailingAddressEqual') ? '' : form.watch('mailingAddressZipCode'),
        },
      },
    ],
    [
      {
        type: 'text',
        name: 'licensedBedCount',
        label: 'Licensed Bed Count',
        props: {
          disabled: true,
        },
        condition: ({ form }) => form.watch('id') !== undefined,
      },
    ],
    [
      {
        type: 'text',
        name: 'additionalInfo',
        label: 'Additional Information',
        props: {
          rows: 2,
          multiline: true,
        },
      },
    ],
  ];
}

export default generateFields;
