import initInstance from '../axios';
import getTotals from './getTotals';
import getFacilityTotals from './getFacilityTotals';
import setFacilityReportsRelation from './setFacilityReportsRelation';
import getYearsForCharts from './getYearsForCharts';

const api = initInstance('/report');

const ReportService = {
  getTotals: getTotals(api),
  getFacilityTotals: getFacilityTotals(api),
  setFacilityReportsRelation: setFacilityReportsRelation(api),
  getYearsForCharts: getYearsForCharts(api),
};

export default ReportService;
