
export enum RiskLevel {
  HighRisk = 'HighRisk',
  MediumRisk = 'MediumRisk',
  LowRisk = 'LowRisk',
}

export const RiskLevelColors = {
  [RiskLevel.LowRisk]: '#C3E5F7',
  [RiskLevel.MediumRisk]: '#69BDEC',
  [RiskLevel.HighRisk]: '#1570A4',
}