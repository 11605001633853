import React from 'react';
import {
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  ValueOptions,
} from '@mui/x-data-grid-pro';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { User } from '@declarations/models/User';
import ReportStatus from '@components/ReportStatus';
import { ReportStatus as ReportStatusType } from '@declarations/common/enums/ReportStatus';
import { UserType } from '@declarations/common/enums/UserType';
import { filterRequiredOperators } from '@components/DataGrid/utils/gridOperators';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import { LawsuitStatusOptions } from '@constants/options/LawsuitStatusOptions';
import IdLink from '@components/IdLink';
import routes from '@constants/routes';
import EditableGridHeader from '@components/EditableGridHeader';
import expertUserRender from '@components/DataGrid/renders/expertUserRender';
import expertUserHeaderRender from '@components/DataGrid/renders/expertUserHeaderRender';
import linkedReportRender from '@components/DataGrid/renders/linkedReportRender';
import { RequestingPersonLabels } from '@constants/options/RequestingPersonOptions';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';
import { IncidentTypeLabels } from '@constants/options/IncidentTypeOptions';
import yesNoRender from '@components/DataGrid/renders/yesNoRender';
import YesNoOptions from '@constants/options/YesNoOptions';
import SingleDropDownContainer from '@components/DataGrid/renders/singleDropDownRender';

type GetColumnsProps = {
  userType: UserType;
  clinicalReviewers?: ValueOptions[];
  legalReviewers?: ValueOptions[];
  inlineEditEnabled: boolean;
};

const lawsuitColumns =
  ({
     userType,
     clinicalReviewers,
     legalReviewers,
     inlineEditEnabled,
   }: GetColumnsProps): GridColDef<Lawsuit>[] => {
    const stringFilterOperators = filterRequiredOperators(getGridStringOperators());
    const dateFilterOperators = filterRequiredOperators(getGridDateOperators());

    const columns: GridColDef<Lawsuit>[] = [
      {
        field: 'reportNumber',
        headerName: 'Report number',
        description: 'Report number',
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<Lawsuit>) => (
          <IdLink
            id={params.row.id}
            href={routes.lawsuitForm}
            value={params.row.reportNumber}
          />
        ),
        filterOperators: stringFilterOperators,
      },
      {
        field: 'linkedReports.incident.reportNumber',
        headerName: 'Linked Incident',
        minWidth: 80,
        align: 'center',
        renderCell: linkedReportRender<Lawsuit>({
          href: routes.incidentReportForm,
          getId: row => row.linkedReports?.incident?.id,
          getDate: row => row.linkedReports?.incident?.incidentDate,
          getReportNumber: row => row.linkedReports?.incident?.reportNumber,
          getAdditionalField: row => row.linkedReports?.incident?.typeOfIncident
            && IncidentTypeLabels[row.linkedReports.incident.typeOfIncident as IncidentType],
        }),
        filterOperators: filterRequiredOperators(getGridStringOperators()),
      },
      {
        field: 'linkedReports.medicalReport.reportNumber',
        headerName: 'Linked MRR',
        description: 'Linked Medical Record Request',
        minWidth: 80,
        align: 'center',
        renderCell: linkedReportRender<Lawsuit>({
          href: routes.medicalRecordRequestForm,
          getId: row => row.linkedReports?.medicalReport?.id,
          getDate: row => row.linkedReports?.medicalReport?.requestDate,
          getReportNumber: row => row.linkedReports?.medicalReport?.reportNumber,
          getAdditionalField: row => row.linkedReports?.medicalReport?.requestedRecordsPerson
            && RequestingPersonLabels[row.linkedReports.medicalReport.requestedRecordsPerson as RequestedMedicalRecordsPerson],
        }),
        filterOperators: filterRequiredOperators(getGridStringOperators()),
      },
      {
        field: 'clinicalReviewer',
        headerName: 'Clinical Reviewer',
        editable: inlineEditEnabled,
        type: 'singleSelect',
        minWidth: 160,
        renderCell: expertUserRender(inlineEditEnabled),
        renderHeader: expertUserHeaderRender(inlineEditEnabled),
        valueOptions: clinicalReviewers,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'legalReviewer',
        headerName: 'Legal Reviewer',
        editable: inlineEditEnabled,
        type: 'singleSelect',
        minWidth: 160,
        renderCell: expertUserRender(inlineEditEnabled),
        renderHeader: expertUserHeaderRender(inlineEditEnabled),
        valueOptions: legalReviewers,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        minWidth: 90,
        valueGetter: (params: GridValueGetterParams<Lawsuit>) => new Date(params.row.createdAt),
        renderCell: (params: GridRenderCellParams<Lawsuit>) => new Date(params.row.createdAt).toLocaleDateString('en-US'),
        type: 'date',
        filterOperators: dateFilterOperators,
      },
      {
        field: 'residentFirstName',
        headerName: 'Resident First Name',
        minWidth: 140,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'residentLastName',
        headerName: 'Resident Last Name',
        minWidth: 140,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'residentMedicalRecordNumber',
        headerName: 'Med. Rec. No',
        description: 'Medical Record Request',
        minWidth: 110,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'noticeDate',
        headerName: 'Date of Notice',
        minWidth: 90,
        valueGetter: (params: GridValueGetterParams<Lawsuit>) => new Date(params.row.noticeDate),
        renderCell: (params: GridRenderCellParams<Lawsuit>) => new Date(params.row.noticeDate).toLocaleDateString('en-US'),
        type: 'date',
        filterOperators: dateFilterOperators,
      },
      {
        field: 'userSnapshot',
        headerName: 'Reporter',
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<Lawsuit, User>) => <span>{[params.value?.firstname, params.value?.lastname].join(' ')}</span>,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'userSnapshot.email',
        headerName: 'Email',
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<Lawsuit, User>) => <span>{params.row.userSnapshot?.email}</span>,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'userSnapshot.preferredContactNumber',
        headerName: 'Phone No',
        description: 'Phone Number',
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<Lawsuit, User>) => <span>{params.row.userSnapshot?.preferredContactNumber}</span>,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'facility',
        headerName: 'Facility',
        minWidth: 120,
        filterOperators: undefined,
      },
      {
        field: 'caseName',
        headerName: 'Case Name',
        minWidth: 120,
        filterOperators: stringFilterOperators,
      },
      {
        field: 'operator',
        headerName: 'Operator',
        minWidth: 120,
        filterOperators: undefined,
      },
      {
        field: 'city',
        headerName: 'City',
        minWidth: 100,
      },
      {
        field: 'state',
        headerName: 'State',
        minWidth: 80,
      },
      inlineEditEnabled ?
        ({
          field: 'calculatedStatus',
          headerName: 'Status',
          editable: true,
          renderHeader: (headerParam) => <EditableGridHeader title={headerParam.colDef.headerName!}/>,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<Lawsuit, ReportStatusType>) => (
            <ReportStatus calculatedStatus={params.row.calculatedStatus} forcedStatus={params.row.forcedStatus}
                          editable={true}/>
          ),
          type: 'singleSelect',
          valueOptions: (gridParams) => {
            if (!gridParams.id) {
              return ReportStatusOptions;
            }
            return [{value: null, label: 'AUTO'}, ...ReportStatusOptions];
          },
          filterable: true,
        }) : ({
          field: 'status',
          headerName: 'Status',
          editable: false,
          minWidth: 120,
          renderCell: (params: GridRenderCellParams<Lawsuit, ReportStatusType>) => (
            <ReportStatus calculatedStatus={params.row.calculatedStatus} forcedStatus={params.row.forcedStatus}
                          editable={false}/>
          ),
          type: 'singleSelect',
          valueOptions: ReportStatusOptions,
          filterable: true,
        }),
      {
        field: 'lawsuitStatus',
        headerName: 'Lawsuit Status',
        minWidth: 140,
        type: 'singleSelect',
        renderHeader: (headerParam) => inlineEditEnabled ? <EditableGridHeader title={headerParam.colDef.headerName!}/> : 'Lawsuit Status',
        editable: inlineEditEnabled,
        valueOptions: LawsuitStatusOptions,
        renderCell: (params: GridRenderCellParams<Lawsuit>) => {
          const lawsuitStatusLabel = LawsuitStatusOptions.find(({value}) => params.row.lawsuitStatus === value)?.label || 'UNSUPPORTED';
          return <SingleDropDownContainer displayValue={<span>{lawsuitStatusLabel}</span>}></SingleDropDownContainer>;
        },
      },
      {
        field: 'clinicalReviewCompleted',
        minWidth: 100,
        headerName: 'Clinical Rev. Completed',
        description: 'Clinical Review Completed',
        renderCell: yesNoRender,
        type: 'singleSelect',
        valueOptions: YesNoOptions,
        filterOperators: filterRequiredOperators(getGridSingleSelectOperators()),
      },
      {
        field: 'legalReviewCompleted',
        minWidth: 100,
        headerName: 'Legal Rev. Completed',
        description: 'Legal Review Completed',
        renderCell: yesNoRender,
        type: 'singleSelect',
        valueOptions: YesNoOptions,
        filterOperators: filterRequiredOperators(getGridSingleSelectOperators()),
      },
    ];

    if (userType === UserType.SuperAdmin) {
      columns.push(
        {
          field: 'comment',
          headerName: 'Comment',
          renderHeader: () => inlineEditEnabled ? <EditableGridHeader title="Comment"/> : 'Comment',
          minWidth: 140,
          editable: inlineEditEnabled
        },
      );
    }

    return columns.map((column) => ({
      ...column,
      renderCell: column.renderCell || ((params: GridRenderCellParams<Lawsuit>) => {
        const text = params.row[column.field as keyof Lawsuit] as string;
        return (<span title={text}>{text}</span>);
      }),
    }));
  };

export default lawsuitColumns;
