import { AxiosInstance } from 'axios';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';

export type FacilitiesMMRCountsByRequester =
  {
    facilityId: number;
    facilityName: string;
    reports: [{
      requester: RequestedMedicalRecordsPerson | null;
      count: number;
    }]
  };

export default function getFacilitiesCountsByRequester(api: AxiosInstance) {
  return (riskLevel?: RiskLevel, organizationId?: number, yearOfMrrs?: number) => api.get<FacilitiesMMRCountsByRequester[]>('/facilities-counts-by-requester', {
    params: {risk_level: riskLevel, organization_id: organizationId, year_of_mrrs: yearOfMrrs},
  });
}
