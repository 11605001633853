import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import downloadFile from '@utils/downloadFile';

export default function useDownloader<Args extends unknown[]>(
  fetchMethod?: (...args: Args) => Promise<AxiosResponse<any>>,
  fileName?: string | (() => string),
) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState<Error | null>(null);

  const download = useCallback(async (...args: Args) => {
    if (fetchMethod === undefined) {
      return;
    }

    try {
      setIsDownloading(true);
      const { data, headers } = await fetchMethod(...args);

      const downloadedFileName = typeof fileName === 'string' ? fileName : fileName?.();

      downloadFile(new Blob([data], { type: headers.contentType }), downloadedFileName ?? '');
    } catch (error) {
      console.log(error);
      setDownloadError(error as Error);
    } finally {
      setIsDownloading(false);
    }
  }, [fetchMethod]);

  return {
    isDownloading,
    downloadError,
    download,
  };
}
