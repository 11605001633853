import { AxiosInstance } from 'axios';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type IncidentByFacilityAndRiskLevel =
  {
    facilityId: number;
    facilityName: string;
    reports: {
      riskLevel: RiskLevel;
      count: number;
    }[]
  };

export default function getFacilitiesIncidentsByRiskLevel(api: AxiosInstance) {
  return (organizationId?: number, yearOfIncidents?: number) => api.get<IncidentByFacilityAndRiskLevel[]>('/by-facility-and-risk-level', {
    params: {organization_id: organizationId, year_of_incidents: yearOfIncidents},
  });
}
