import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import routes from '../../constants/routes';

interface Props {
  onClickViewProfile: () => void
  handleLogout: () => void
}

function ProfileList(props: Props) {
  const { onClickViewProfile, handleLogout } = props;
  const theme = useTheme();

  const location = useLocation();
  const isSelected = location.pathname === routes.userProfile;

  const viewProfileText = <Typography variant="body2">View Profile</Typography>;
  const logoutText = <Typography variant="body2">Logout</Typography>;

  return (
    <List
      component="nav"
      sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
    >
      <ListItemButton onClick={onClickViewProfile} selected={isSelected}>
        <ListItemIcon>
          <PersonOutlineOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={viewProfileText} />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={logoutText} />
      </ListItemButton>
    </List>
  );
}

export default ProfileList;
