import React, { useEffect, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartData } from 'chart.js/dist/types';
import getOptionLabel from '@utils/getOptionLabel';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import { RiskLevel, RiskLevelColors } from '@declarations/common/enums/RiskLevel';
import RiskLevelOptions from '@constants/options/RiskLeveOptions';
import { IncidentByFacilityAndRiskLevel } from '@api/IncidentService/getFacilitiesIncidentsByRiskLevel';
import _ from 'lodash';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
    y: {
      ticks: {autoSkip: false, padding: 10},
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Incident Risk Status',
      font: {
        size: 20,
      },
    },
  },
});

type IncidentsCountByRiskLevelProps = {
  data: IncidentByFacilityAndRiskLevel[] | null;
};

function IncidentsCountByRiskLevel({data}: IncidentsCountByRiskLevelProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: Object.values(RiskLevel).map((riskLevel) => ({
          label: getOptionLabel(RiskLevelOptions, riskLevel),
          data: data.map(d => {
            const reportsByRiskLevel = d.reports.find(r => r.riskLevel === riskLevel);
            return reportsByRiskLevel?.count || 0;
          }),
          backgroundColor: RiskLevelColors[riskLevel],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);
  
  const flattenSum = data?.map(d => _.sumBy(d.reports, 'count'));
  const maxXValue = _.max(flattenSum) || 0;
  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxXValue)}/>
    </ChartContainer>
  );
}

export default React.memo(IncidentsCountByRiskLevel);
