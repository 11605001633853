import React, { useEffect, useImperativeHandle } from 'react';
import useApi from '@hooks/useApi';
import IncidentService from '@api/IncidentService';
import { Stack } from '@mui/material';
import StatBox from '@components/StatBox';
import { useSelector } from 'react-redux';
import { SettingsSelectors } from '@store/slices/settings';

type IncidentsTotalChipsHandle = {
  refresh: () => Promise<void>;
};

function IncidentsTotalChips({}, ref: React.Ref<IncidentsTotalChipsHandle>) {
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const year = useSelector(SettingsSelectors.selectedYearNumber);

  useImperativeHandle(ref, () => ({
    refresh: fetchData,
  }));

  const incidentTotals = useApi({
    method: IncidentService.getTotalsByFacilities,
    initialData: {
      incident: NaN,
      incomplete: NaN,
      highRisk: NaN,
    },
  });

  const fetchData = async () => {
    try {
      await incidentTotals.fetch(selectedOrganization?.id, year);
    } catch (e) {
      console.error(e);
      incidentTotals.data = {
        incident: 0,
        highRisk: 0,
        incomplete: 0,
      };
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOrganization, year]);

  return (
    <Stack direction="row" spacing={2} sx={{marginBottom: 5}}>
      <StatBox
        title="Total Incidents"
        loading={incidentTotals.isLoading || Number.isNaN(incidentTotals.data.incident)}
        count={incidentTotals.data.incident}
      />
      <StatBox
        title="Total Incomplete"
        loading={incidentTotals.isLoading || Number.isNaN(incidentTotals.data.incident)}
        count={incidentTotals.data.incomplete}
      />
      <StatBox
        title="Total High Risk Incident"
        loading={incidentTotals.isLoading || Number.isNaN(incidentTotals.data.incident)}
        count={incidentTotals.data.highRisk}
      />
    </Stack>
  );
}

export default React.forwardRef(IncidentsTotalChips);