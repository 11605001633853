import {
  boolean, date, mixed, number, string,
} from 'yup';
import { Gender } from '@declarations/common/enums/Gender';
import Errors from '@constants/locale/errors';

const baseReportValidationSchema = {
  facility: string().required(Errors.REQUIRED_FIELD),
  residentFirstName: string().required(Errors.REQUIRED_FIELD),
  residentLastName: string().required(Errors.REQUIRED_FIELD),
  residentMedicalRecordNumber: string().required(Errors.REQUIRED_FIELD),
  residentBirthdate: date().required(Errors.REQUIRED_FIELD).typeError(Errors.VALID_DATE),
  residentGender: mixed().required(Errors.REQUIRED_FIELD).oneOf(Object.values(Gender)),
  isCurrentResident: boolean().required(Errors.REQUIRED_FIELD),
  admitDate: date().required(Errors.REQUIRED_FIELD).typeError(Errors.VALID_DATE),
  dischargeDate: date().typeError(Errors.VALID_DATE),
};

export default baseReportValidationSchema;
