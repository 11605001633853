import { ReportType } from '@declarations/common/reportType';
import initInstance from './axios';
import setLogRecordExpertUsers from '@utils/setLogRecordExpertUsers';

const api = initInstance('/user-log-record');

export type QueryUserLogRecords = {
  reportId: number;
  reportType: ReportType;
}

const UserLogRecordService = {
  get: (queryUserLogRecords: QueryUserLogRecords) =>
    api.get<any[]>('', { params: queryUserLogRecords }).then(res => setLogRecordExpertUsers(res)),
};

export default UserLogRecordService;