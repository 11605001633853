import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WarningIcon from '@mui/icons-material/Warning';
import { clearState, clearInitialState } from '../../utils/cache';
import { useGridContext } from '../../providers/GridContext';

function ColumnMenuHardResetItem() {
  const gridContext = useGridContext();
  const { stateName } = gridContext;

  const resetTableState = async () => {
    if (stateName) {
      await clearState(stateName);
      await clearInitialState(stateName);
      window.location.reload();
    }
  };

  if (!stateName) {
    return null;
  }

  return (
    <MenuItem onClick={resetTableState}>
      <ListItemIcon>
        <RestartAltIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        Default State
      </ListItemText>
      <ListItemIcon>
        <WarningIcon fontSize="small" color="warning" />
      </ListItemIcon>
    </MenuItem>
  );
}

export default ColumnMenuHardResetItem;
