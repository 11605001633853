import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { DataGrid, useGridModels } from '@components/DataGrid';
import FacilityService from '@api/facilityService';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import useGridData from '@components/DataGrid/hooks/useGridData';
import routes from '../../constants/routes';
import PageTitle from '../../components/page/PageTitle';
import facilitiesColumns from './Facilities.columns';
import TableToolbar from '../../components/TableToolbar';

function FacilitiesPage() {
  const {hasAccess: hasSuperAdminAccess} = useAccess({types: [UserType.SuperAdmin]});
  const gridModels = useGridModels();
  const {data, getData, loading} = useGridData({fetchMethod: FacilityService.list, gridModels});

  const onRemove = async () => {
    const ids = gridModels.rowSelectionModel as number[];
    await FacilityService.remove(ids);
    gridModels.setPage(0);
    gridModels.setSelection([]);

    await getData();
  };
  
  return (
    <React.Fragment>
      <PageTitle>Facilities</PageTitle>
      {
        hasSuperAdminAccess && (
          <TableToolbar
            onRemove={onRemove}
            showRemove={gridModels.getSelectionCount() > 0}
            removalModalTitle="Are you sure you want to delete selected facilities?"
            removalModalContent={`You selected ${gridModels.getSelectionCount()} facility(ies) to delete.`}
            exportCSVFile={{
              fileName: () => `facilities-${new Date().toLocaleDateString('en-US')}.csv`,
              fetchMethod: FacilityService.exportCSV,
              fetchParams: gridModels.getFetchListParams(),
            }}
          >
            <Button component={Link} variant="contained" to={routes.facilityForm}>
              New Facility
            </Button>
          </TableToolbar>
        )
      }

      <DataGrid
        loading={loading}
        autoHeight
        rows={data}
        columns={facilitiesColumns}
        checkboxSelection={hasSuperAdminAccess}
        stateName="facilities"
        {...gridModels}
      />
    </React.Fragment>
  );
}

export default FacilitiesPage;
