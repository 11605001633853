import { useState } from 'react';
import { GridPaginationModel, DataGridProProps } from '@mui/x-data-grid-pro';

type UsePaginationModelReturnType = {
  paginationModel: GridPaginationModel,
  onPaginationModelChange: DataGridProProps['onPaginationModelChange'],
  setPage: (page: number) => void,
};

export function usePaginationModel(initialState?: Partial<GridPaginationModel>): UsePaginationModelReturnType {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: initialState?.page ?? 0,
    pageSize: initialState?.pageSize ?? 10,
  });

  return {
    paginationModel,
    onPaginationModelChange: setPaginationModel,
    setPage: (page: number) => setPaginationModel({ ...paginationModel, page }),
  };
}
