import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js/dist/types';
import ChartContainer from '@components/charts/ChartContainer';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import { LawsuitsCountsByStatus } from '@api/LawsuitService/getCountsByStatus';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import getOptionLabel from '@utils/getOptionLabel';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';

const options: ChartProps<'doughnut'>['options'] = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Lawsuit Completion Status',
      font: {
        size: 20,
      },
    },
    legend: {
      display: true,
    },
  },
};

type LawsuitsByStatusProps = {
  data: LawsuitsCountsByStatus[] | null;
};

function LawsuitsByStatus({data}: LawsuitsByStatusProps) {
  const [chartData, setChartData] = useState<ChartData<'doughnut'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: Object.values(ReportStatus).map((status) => getOptionLabel(ReportStatusOptions, status)),
        datasets: [{
          data: Object.values(ReportStatus).map((status) => {
            const found = data.find((d) => d.status === status);
            return found?.count ?? 0;
          }),
          backgroundColor: Object.values(ReportStatus).map((status) => ReportStatusColors[status]),
        }],
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  return (
    <ChartContainer loading={!chartData}>
      <Doughnut data={chartData!} options={options}/>
    </ChartContainer>
  );
}

export default LawsuitsByStatus;
