import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonBox from './ButtonBox';
import { FormMode } from '../hooks/useFormEdit';

interface Props {
  mode: FormMode
  saveDisabled?: boolean
  startEditHandle: (event: MouseEvent) => unknown
  saveHandle: (event: MouseEvent) => unknown
  loading?: boolean
  backRoute?: string;
  editDisabled?: boolean;
  actions?: React.ReactNode;
}

function FormButtonBox(props: Props) {
  const {
    saveDisabled, saveHandle, startEditHandle, mode, loading, backRoute, editDisabled, actions,
  } = props;

  const navigate = useNavigate();

  const closeHandle = () => {
    if (backRoute) {
      return navigate(backRoute);
    }

    return navigate(-1);
  };

  const okText = mode === FormMode.View ? 'Edit' : 'Save';
  const okCallback = mode === FormMode.View ? startEditHandle : saveHandle;

  return (
    <ButtonBox
      saveDisabled={((mode === FormMode.Create || mode === FormMode.Edit) && saveDisabled) || editDisabled}
      okHandle={okCallback}
      closeHandle={closeHandle}
      okText={okText}
      loading={loading}
      actions={actions}
    />
  );
}

export default React.memo(FormButtonBox);
