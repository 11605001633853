import { styled } from '@mui/material/styles';
import Button from '@components/Button';

const MoreActionsButton = styled(Button)(
  ({ theme }) => ({
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),
    width: theme.spacing(4),
  }),
);

export default MoreActionsButton;
