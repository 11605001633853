import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Stack } from '@mui/material';
import Button from '@components/Button';
import useDownloader from '@hooks/useDownloader';
import { FetchListParams } from '@declarations/common/fetchListParams';
import RemoveRowsModal from './modals/RemoveRowsModal';
import { minButtonWidth } from '../consts';

interface Props {
  children: React.ReactNode;

  removeButtonLabel?: string;
  exportCSVFile?: CSVFileProps;
  onRemove?: () => void;
  showRemove: boolean;

  removalModalTitle: string;
  removalModalContent: string;
}

interface CSVFileProps {
  fetchMethod: (params: Omit<FetchListParams, 'pagination'>) => Promise<AxiosResponse<File>>;
  fetchParams: Omit<FetchListParams, 'pagination'>;
  fileName: string | (() => string);
}

function TableToolbar(props: Props) {
  const {
    onRemove,
    showRemove,
    removeButtonLabel = 'Delete',
    removalModalTitle,
    removalModalContent,
    exportCSVFile,
  } = props;

  const [isOpen, setOpen] = useState(false);
  const csvDownloader = useDownloader(exportCSVFile?.fetchMethod, exportCSVFile?.fileName);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDelete = () => {
    setOpen(true);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mb: 3 }}>
        <Stack direction="row" spacing={2}>
          {props.children}
          {showRemove && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleClickDelete}
              sx={minButtonWidth}
            >
              {removeButtonLabel}
            </Button>
          )}
        </Stack>

        {
          exportCSVFile && (
            <Button
              onClick={() => csvDownloader.download(exportCSVFile?.fetchParams)}
              variant="outlined"
              sx={minButtonWidth}
              loading={csvDownloader.isDownloading}
            >
              Export CSV
            </Button>
          )
        }
      </Stack>

      {!!onRemove && (
        <RemoveRowsModal
          isOpen={isOpen}
          handleClose={handleClose}
          handleConfirm={onRemove}
          title={removalModalTitle}
          content={removalModalContent}
        />
      )}
    </>
  );
}

export default React.memo(TableToolbar);
