export enum ReportStatus {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  InProgress = 'IN_PROGRESS',
}

export const ReportStatusColors = {
  [ReportStatus.Completed]: '#197CB6',
  [ReportStatus.Incomplete]: '#A5D7F4',
  [ReportStatus.InProgress]: '#FFDE7A',
}