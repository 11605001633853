import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '@components/page/PageTitle';
import { Box, Grid, Stack } from '@mui/material';
import LawsuitService from '@api/LawsuitService';
import LawsuitsByStatus from '@components/charts/LawsuitsByStatus';
import LawsuitsByLawsuitStatus from '@components/charts/LawsuitsByLawsuitStatus';
import StatBox from '@components/StatBox';
import LawsuitReportsGrid from '../components/LawsuitReportsGrid';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { LawsuitTotalsByFacilities } from '@api/LawsuitService/getTotalsByFacilities';
import { LawsuitsCountsByStatus } from '@api/LawsuitService/getCountsByStatus';
import { LawsuitsCountsByLawsuitStatus } from '@api/LawsuitService/getCountsByLawsuitStatus';

function FacilityLawsuitReports() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();

  const [lawsuitTotals, setLawsuitTotals] =
    useState<LawsuitTotalsByFacilities>({lawsuit: NaN, closed: NaN, incomplete: NaN});

  const [lawsuitsCountsByStatus, setLawsuitsCountsByStatus] =
    useState<LawsuitsCountsByStatus[] | null>(null);

  const [lawsuitsCountsByLawsuitStatus, setLawsuitsCountsByLawsuitStatus] =
    useState<LawsuitsCountsByLawsuitStatus[] | null>(null);

  const fetchLawsuitsTotal = () => {
    setLawsuitTotals({lawsuit: NaN, closed: NaN, incomplete: NaN});
    LawsuitService.getFacilityTotals(facility!.id, selectedYear)
      .then(({data}) => setLawsuitTotals(data))
      .catch((e) => {
        console.error(e);
        setLawsuitTotals({lawsuit: 0, closed: 0, incomplete: 0});
        throw e;
      });
  };

  const fetchLawsuitsCountsByStatus = () => {
    setLawsuitsCountsByStatus(null);
    LawsuitService.countsByStatus(facility!.id, selectedYear)
      .then(({data}) => setLawsuitsCountsByStatus(data))
      .catch((e) => {
        console.error(e);
        setLawsuitsCountsByStatus([]);
        throw e;
      });
  };

  const fetchLawsuitsCountsByLawsuitStatus = () => {
    setLawsuitsCountsByLawsuitStatus(null);
    LawsuitService.countsByLawsuitStatus(facility!.id, selectedYear)
      .then(({data}) => setLawsuitsCountsByLawsuitStatus(data))
      .catch((e) => {
        console.error(e);
        setLawsuitsCountsByLawsuitStatus([]);
        throw e;
      });
  };
  const fetchData = async () => {
    fetchLawsuitsTotal();
    fetchLawsuitsCountsByStatus();
    fetchLawsuitsCountsByLawsuitStatus();
  };

  useEffect(() => {
    fetchData().catch(console.error);
    setGridFilterItem({
      id: facility?.id,
      value: facility?.name,
      field: 'facility',
      operator: 'equals',
    });
  }, [facility?.id, selectedYear]);

  return (
    <Box component="main">
      <PageTitle>
        {facility?.name}
        : Lawsuit Reports
      </PageTitle>

      <Stack direction="row" spacing={2} sx={{marginBottom: 5}}>
        <StatBox
          title="Total Lawsuits"
          loading={Number.isNaN(lawsuitTotals.lawsuit)}
          count={lawsuitTotals.lawsuit}
        />

        <StatBox
          title="Total Incomplete"
          loading={Number.isNaN(lawsuitTotals.incomplete)}
          count={lawsuitTotals.incomplete}
        />

        <StatBox
          title="Total Closed"
          loading={Number.isNaN(lawsuitTotals.closed)}
          count={lawsuitTotals.closed}
        />
      </Stack>

      <Grid container sx={{marginBottom: 5}} spacing={3}>
        <Grid item xs={12} md={6} xl={4}>
          <LawsuitsByStatus data={lawsuitsCountsByStatus}/>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <LawsuitsByLawsuitStatus data={lawsuitsCountsByLawsuitStatus}/>
        </Grid>
      </Grid>
      <LawsuitReportsGrid lawsuitsReportGridKey={'facility-lawsuits'} gridFilterItem={gridFilterItem}/>
    </Box>
  );
}

export default FacilityLawsuitReports;
