import React, { MouseEvent } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Button from './Button';

interface Props {
  saveDisabled?: boolean
  okHandle: (event: MouseEvent) => unknown
  closeHandle: (event: MouseEvent) => unknown
  okText?: string
  loading?: boolean;
  actions?: React.ReactNode;
}

function ButtonBox(props: Props) {
  const {
    saveDisabled, okHandle, closeHandle, okText = 'Save', loading, actions,
  } = props;

  return (
    <Box display="flex" gap={2}>
      {
        !saveDisabled && (
          <Button
            variant="contained"
            onClick={okHandle}
            disabled={loading}
            sx={{ width: '100px' }}
            loading={loading}
          >
            {okText}
          </Button>
        )
      }

      <Button variant="outlined" onClick={closeHandle} sx={{ width: '100px' }}>
        Close
      </Button>

      {actions}
    </Box>
  );
}

export default ButtonBox;
