import React, { useEffect, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartData } from 'chart.js/dist/types';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import getOptionLabel from '@utils/getOptionLabel';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import { FacilitiesLawsuitsByStatus } from '@api/LawsuitService/getFacilitiesLawsuitsCountsByStatus';
import getFacilityLabels from '@utils/getFacilityLabels';

type LawsuitsCountByStatusProps = {
  data: FacilitiesLawsuitsByStatus[] | null;
};

const options = (maxXValue: number): ChartProps<'bar'>['options'] => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(maxXValue),
    },
    y: {
      ticks: {autoSkip: false, padding: 10},
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Lawsuits Completion Status',
      font: {
        size: 20,
      },
    },
  },
});

function LawsuitsCountByStatus({data}: LawsuitsCountByStatusProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: Object.values(ReportStatus).map((repostStatus) => ({
          label: getOptionLabel(ReportStatusOptions, repostStatus),
          data: data.map(d => {
            const reportsByStatus = d.reports.find(r => r.status === repostStatus);
            return reportsByStatus?.count || 0;
          }),
          backgroundColor: ReportStatusColors[repostStatus],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const flattenSum = data?.map(d => _.sumBy(d.reports, 'count'));
  const maxXValue: number = _.max(flattenSum) || 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxXValue)}/>
    </ChartContainer>
  );
}

export default React.memo(LawsuitsCountByStatus);
