import { AxiosInstance } from 'axios';

type Totals = {
  incident: number;
  incomplete: number;
  highRisk: number;
};

function getTotalsByFacilities(api: AxiosInstance) {
  return (organizationId?: number, yearOfIncidents?: number) => api.get<Totals>(`/totals-by-facilities`, {
    params: {organization_id: organizationId, year_of_incidents: yearOfIncidents},
  });
}

export default getTotalsByFacilities;
