import PageTitle from '@components/page/PageTitle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import IncidentReportsGrid from '../components/IncidentReportsGrid';
import IncidentsCountByType from '@components/charts/adminsCharts/incidents/IncidentsCountByType';
import { useSelector } from 'react-redux';
import IncidentsTotalChips from './incidentsTotalChips';
import IncidentsCountByFacilityAndType from '@components/charts/adminsCharts/incidents/IncidentsCountByFacilityAndType';
import IncidentsCountByStatus from '@components/charts/adminsCharts/incidents/IncidentsCountByStatus';
import HighRiskIncidentsCountByFacilityAndType
  from '@components/charts/adminsCharts/incidents/HighRiskIncidentsCountByFacilityAndType';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { Incident } from '@declarations/models/reports/Incident';
type TotalChipsHandle = React.ElementRef<typeof IncidentsTotalChips>;
type HighRiskIncidentTypeHandle = React.ElementRef<typeof IncidentsCountByType>;
type IncidentsCountByFacilityAndTypeHandle = React.ElementRef<typeof IncidentsCountByType>;
type IncidentsCountByStatusHandle = React.ElementRef<typeof IncidentsCountByStatus>;
type HighRiskIncidentsCountByFacilityAndTypeHandle = React.ElementRef<typeof HighRiskIncidentsCountByFacilityAndType>;

function TotalIncidentReports() {
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedFacilities = useSelector(SettingsSelectors.facilitiesFromSelectedOrganization);
  const totalChipsRef = useRef<TotalChipsHandle>(null);
  const highRiskIncidentRef = useRef<HighRiskIncidentTypeHandle>(null);
  const countByFacilityAndTypeRef = useRef<IncidentsCountByFacilityAndTypeHandle>(null);
  const incidentsTotalRef = useRef<IncidentsCountByStatusHandle>(null);
  const highRiskIncidentsCountByFacilityAndTypeRef = useRef<HighRiskIncidentsCountByFacilityAndTypeHandle>(null);

  const pageTitle = useMemo<string>(() => {
    if (!selectedFacilities) {
      return 'Facilities';
    }
    return selectedFacilities.length === 1 ? '1 Facility' : `${selectedFacilities.length} Facilities`;
  }, [selectedFacilities?.length]);

  useEffect(() => {
    setGridFilterItem({
      id: selectedOrganization?.id,
      value: selectedOrganization?.name,
      field: 'operator',
      operator: 'equals',
    })
  }, [selectedOrganization?.id]);
  
  const onPatchIncident = async (newValues: Partial<Incident>) => {
    if (newValues.calculatedStatus !== undefined) {
      highRiskIncidentRef?.current?.refresh?.();
      countByFacilityAndTypeRef?.current?.refresh?.();
      totalChipsRef?.current?.refresh?.();
      incidentsTotalRef?.current?.refresh?.();
      highRiskIncidentsCountByFacilityAndTypeRef?.current?.refresh?.();
    }
  };

  const onRemoveIncident = () => {
    highRiskIncidentRef?.current?.refresh?.();
    countByFacilityAndTypeRef?.current?.refresh?.();
    totalChipsRef?.current?.refresh?.();
    incidentsTotalRef?.current?.refresh?.();
    highRiskIncidentsCountByFacilityAndTypeRef?.current?.refresh?.();
  };
  
  return (
    <Box component="main">
      <PageTitle>{pageTitle}</PageTitle>
      <IncidentsTotalChips ref={totalChipsRef}></IncidentsTotalChips>
      <Grid container sx={{marginBottom: 5}} spacing={3}>
        <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
          <IncidentsCountByType ref={highRiskIncidentRef}/>
        </Grid>
        <Grid item xs={12} md={7} lg={7} xl={7}>
          <IncidentsCountByFacilityAndType ref={countByFacilityAndTypeRef}/>
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
          <IncidentsCountByStatus ref={incidentsTotalRef}/>
        </Grid>
        <Grid item xs={12} md={7} lg={7} xl={7}>
          <HighRiskIncidentsCountByFacilityAndType ref={highRiskIncidentsCountByFacilityAndTypeRef}/>
        </Grid>
      </Grid>
      <IncidentReportsGrid incidentReportGridKey={'total-incidents'} gridFilterItem={gridFilterItem} onRemove={onRemoveIncident} onIncidentPatched={onPatchIncident}/>
    </Box>
  );
}

export default TotalIncidentReports;
