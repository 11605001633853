import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import isHighRisk from '@utils/isHighRisk';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import { yesNoOptions } from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.Choking], undefined>;

function getChokingFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = <T extends keyof RequiredFields>(field: T) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.Choking}.${field}` as T;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.Choking;
    }

    return form.watch('typeOfIncident') === IncidentType.Choking;
  };

  return {
    fields: [
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didResidentOnModifiedDietPlan'),
          label: 'Was the resident on a modified diet plan?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didResidentRequireAssistanceWithDining'),
          label: 'Did the resident require assistance with dining?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasResidentBeingAssistedByStaffMemberWhenChokingOccurred'),
          label: 'Was the resident being assisted by a staff member when the choking occurred?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            result = result && conditionParams.form.watch(getFieldName('didResidentRequireAssistanceWithDining')) === true;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasResidentMonitoredDuringMealtimes'),
          label: 'Was the resident monitored during mealtimes?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            result = result && conditionParams.form.watch(getFieldName('didResidentRequireAssistanceWithDining')) === false;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didChokingResultInPermanentInjuryOrDeath'),
          label: 'Did the choking result in permanent injury or death?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
    ],
    fileFields: [
      [
        {
          type: 'file',
          required: false,
          name: getFieldName('mostRecentSwallowingStudyFiles'),
          label: 'Recent Swallowing Study/Assessment',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
      [
        {
          type: 'file',
          required: false,
          name: getFieldName('modifiedDietOrdersFiles'),
          label: 'Modified Diet Orders',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
    ],
  };
}

export default getChokingFields;
