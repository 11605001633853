import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import useStateStorage from '@hooks/useStateStorage';
import NavigationDrawer from '../drawer/NavigationDrawer';
import { NAV_DRAWER_WIDTH } from '../../consts';
import Profile from '../profile/Profile';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  maxWidth: '100%',
  overflow: 'auto',
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${NAV_DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,

    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#fafafa',
  borderBottom: '1px solid #e0e0e0',
  color: 'black',
  ...(open && {
    width: `calc(100% - ${NAV_DRAWER_WIDTH}px)`,
    color: 'black',
    backgroundColor: '#fafafa',
    marginLeft: `${NAV_DRAWER_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function PageWithHeader(props) {
  const { children } = props;
  const [isShowDrawer, setShowDrawer] = useStateStorage(false, 'drawer-open');

  const handleDrawerOpen = () => {
    setShowDrawer(!isShowDrawer);
  };

  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={isShowDrawer} elevation={0}>
        <Toolbar>
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            color="secondary"
            sx={{
              color: 'text.primary',
              bgcolor: isShowDrawer ? iconBackColorOpen : iconBackColor,
            }}
          >
            {!isShowDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </IconButton>

          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Profile />
          </Box>
        </Toolbar>
      </AppBar>

      <NavigationDrawer isOpen={isShowDrawer} />

      <Main open={isShowDrawer}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

export default PageWithHeader;
