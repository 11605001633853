import { AxiosInstance } from 'axios';

export type Totals = {
  medical: number;
  incomplete: number;
  highRisk: number;
};

function getFacilityTotals(api: AxiosInstance) {
  return (facilityId: number, yearOfMrrs?: number) => api.get<Totals>(`/totals/facility/${facilityId}`, {
    params: { year_of_mrrs: yearOfMrrs }
  });
}

export default getFacilityTotals;
