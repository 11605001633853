import TableToolbar from '@components/TableToolbar';
import IncidentService from '@api/IncidentService';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import routes from '@constants/routes';
import { DataGrid, useGridModels } from '@components/DataGrid';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import useGridData from '@components/DataGrid/hooks/useGridData';
import { Incident } from '@declarations/models/reports/Incident';
import { toast } from 'react-toastify';
import getDifferent from '@utils/getDifferent';
import pick from 'lodash/pick';
import incidentsColumns from './columns';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { ReportTypeEnum } from '@declarations/common/reportType';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import useExpertUsers from '@hooks/useExpertUsers';

type IncidentReportsGridProps = {
  incidentReportGridKey: string;
  gridFilterItem?: GridFilterItem;
  onRemove?: (ids: number[]) => void;
  onIncidentPatched?: (newValues: Partial<Incident>) => Promise<void>;
};

function IncidentReportsGrid(props: IncidentReportsGridProps) {
  const userType = useSelector(UserSelectors.type);
  const {hasAccess: hasRemovalAccess} = useAccess({types: [UserType.SuperAdmin]});
  const gridModels = useGridModels();
  const {data, getData, loading} = useGridData({fetchMethod: IncidentService.list, gridModels});
  const user = useSelector(UserSelectors.user);
  const {clinicalReviewers, legalReviewers, usersLoading, setPromiseArguments, renderConfirmDialog} = useExpertUsers(user.type);

  const onRemove = async () => {
    const ids = gridModels.rowSelectionModel as number[];
    await IncidentService.remove(ids);
    gridModels.setPage(0);
    gridModels.setSelection([]);

    await getData();

    props?.onRemove?.(ids);
  };

  const onInlineEdit = useCallback(async (newRow: Incident, oldRow: Incident) => {
    const different = getDifferent(oldRow, newRow);
    const newValues = pick(newRow, different);

    if (different[0] === 'clinicalReviewer' || different[0] === 'legalReviewer') {
      return new Promise<Incident>((resolve, reject) => {
        const expertRole = different[0] === 'clinicalReviewer' ? ExpertRole.ClinicalReviewer : ExpertRole.LegalReviewer;
        setPromiseArguments({newRow, oldRow, reject, resolve, expertRole, reportType: ReportTypeEnum.Incident});
      });
    }

    if (Object.keys(newValues).length) {
      await toast.promise(IncidentService.patch(newRow.id, newValues), {
        pending: 'Updating...',
        success: 'Successfully updated',
        error: 'Something went wrong',
      });
      if (newValues.calculatedStatus !== undefined) {
        getData();
      }
      props?.onIncidentPatched?.(newValues);
    }

    return newRow;
  }, [data]);

  const areColumnsEditable = useMemo(() => userType === UserType.SuperAdmin, [userType]);
  const columns = useMemo(() => incidentsColumns(
      {userType, clinicalReviewers, legalReviewers, inlineEditEnabled: areColumnsEditable}),
    [userType, clinicalReviewers, legalReviewers],
  );
  
  const pinnedColumns: string[] | undefined = user.expertRole === ExpertRole.ClinicalReviewer
    ? ['clinicalReviewCompleted']
    : user.expertRole === ExpertRole.LegalReviewer ? ['legalReviewCompleted'] : undefined;

  return (
    <React.Fragment>
      {renderConfirmDialog()}

      <TableToolbar
        onRemove={onRemove}
        showRemove={gridModels.getSelectionCount() > 0}
        exportCSVFile={{
          fileName: () => `incident-reports-${new Date().toLocaleDateString('en-US')}.csv`,
          fetchMethod: IncidentService.exportCSV,
          fetchParams: gridModels.getFetchListParams(),
        }}
        removalModalTitle="Are you sure you want to delete selected reports?"
        removalModalContent={`You selected ${gridModels.getSelectionCount()} report(s) to delete.`}
      >
        {!user.expertRole && <Button component={Link} to={routes.incidentReportForm} variant="contained">
            New incident
        </Button>}
      </TableToolbar>

      <DataGrid
        showQuickFilter
        loading={loading}
        rows={data}
        columns={columns}
        checkboxSelection={hasRemovalAccess}
        processRowUpdate={onInlineEdit}
        stateName={props.incidentReportGridKey}
        gridFilterItem={props.gridFilterItem}
        gridHeight={698}
        pinnedColumns={pinnedColumns}
        usersLoading={usersLoading}
        {...gridModels}
      />
    </React.Fragment>
  );
}

export default IncidentReportsGrid;
