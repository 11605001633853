import { styled } from '@mui/material';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import React, { ReactElement } from 'react';

const Container = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & > .icon {
        color: ${({theme}) => theme.palette.grey[600]};
    }
`;

function SingleDropDownContainer({displayValue}: { displayValue: ReactElement<HTMLSpanElement> }) {
  return (
    <Container>
      {displayValue}
      <ArrowDropDownOutlined className="icon"/>
    </Container>
  );
}

export default React.memo(SingleDropDownContainer);