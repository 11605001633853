import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, UnplannedDeathTypes } from '@declarations/models/reports/Incident';
import { UnplannedDeathType, yesNoOptions } from '../../../const';
import { UserType } from '@declarations/common/enums/UserType';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<UnplannedDeathTypes[UnplannedDeathType.RapidHealthDecline], undefined>;

function getRapidHealthDeclineFields(params: Params): Fields<RequiredFields> {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;
  const getFieldName = (field: keyof RequiredFields) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${UnplannedDeathType.RapidHealthDecline}.${field}`;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === UnplannedDeathType.RapidHealthDecline;
    }

    return form.watch('typeOfIncident') === UnplannedDeathType.RapidHealthDecline;
  };

  return [
    [
      {
        type: 'radio',
        fullWidth: true,
        name: getFieldName('wasTherePhysicianInterventionWithinThe72HoursPrior'),
        label: 'Was there physician intervention within the 72 hours prior to the resident\'s death?',
        options: yesNoOptions,
        condition: makeCondition,
        props: {
          inputLabel: false,
          sx: { maxWidth: 292 },
          disabled: disabledField,
        },
      },
    ],
    [
      {
        type: 'radio',
        fullWidth: true,
        name: getFieldName('wasDeclineRelatedToPrimaryDiagnosisAdmittingCondition'),
        label: 'Was the decline related to the primary diagnosis and/or admitting condition?',
        options: yesNoOptions,
        condition: makeCondition,
        props: {
          inputLabel: false,
          sx: { maxWidth: 292 },
          disabled: disabledField,
        },
      },
    ],
  ];
}

export default getRapidHealthDeclineFields;
