import React, { useCallback } from 'react';
import { DataGrid, useGridModels } from '@components/DataGrid';
import {
  GridColDef, GridFilterItem,
} from '@mui/x-data-grid-pro';
import { AxiosResponse } from 'axios';
import { ListWithPagination } from '@declarations/common/pagination';
import { toast } from 'react-toastify';
import { BaseReport } from '@declarations/models/reports/base';
import { FetchListParams } from '@declarations/common/fetchListParams';
import { ReportType } from '@declarations/common/reportType';
import getDifferent from '@utils/getDifferent';
import pick from 'lodash/pick';
import useGridData from '@components/DataGrid/hooks/useGridData';

type ReportsGridProps<T extends BaseReport> = {
  columns: GridColDef[];
  fetchRowsFn: (params: FetchListParams) => Promise<AxiosResponse<ListWithPagination<T>>>;
  patchFn: (id: number, item: Partial<T>) => Promise<AxiosResponse<any>>;
  reportKeyType: string;
  gridFilterItem?: GridFilterItem;
};

function ReportsGrid<T extends BaseReport>(
  {
    columns,
    fetchRowsFn,
    patchFn,
    reportKeyType,
    gridFilterItem,
  }: ReportsGridProps<T>) {

  const gridModels = useGridModels([{sort: 'desc', field: 'id'}], {page: 0, pageSize: 5});
  const {data, getData, loading} = useGridData({fetchMethod: fetchRowsFn, gridModels});

  const onInlineEdit = useCallback(async (newRow: T, oldRow: T) => {
    const different = getDifferent(oldRow, newRow);
    const newValues = pick(newRow, different);

    if (Object.keys(newValues).length) {
      await toast.promise(patchFn(newRow.id, newValues), {
        pending: 'Updating...',
        success: 'Successfully updated',
        error: 'Something went wrong',
      });
    }
    return newRow;
  }, [data, patchFn]);

  const adaptedColumns = React.useMemo(() => columns.map((column) => ({
    ...column,
    sortable: false,
  })), [columns]);

  return (
    <DataGrid
      hideFooter
      isCellEditable={() => false}
      rows={data}
      columns={adaptedColumns}
      loading={loading}
      processRowUpdate={onInlineEdit}
      gridFilterItem={gridFilterItem}
      stateName={`reportsGrid-${reportKeyType}`}
      {...gridModels}
    />
  );
}

export default ReportsGrid;
