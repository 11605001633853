import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import getOptionLabel from '@utils/getOptionLabel';
import { FacilitiesLawsuitsByLawsuitStatus } from '@api/LawsuitService/getFacilitiesLawsuitsCountsByLawsuitStatus';
import { LawsuitStatus, LawsuitStatusColors } from '@declarations/common/enums/LawsuitStatus';
import { LawsuitStatusOptions } from '@constants/options/LawsuitStatusOptions';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number) => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Lawsuits',
      font: {
        size: 20,
      },
    },
  },
});

type LawsuitCountByOwnStatusProps = {
  data: FacilitiesLawsuitsByLawsuitStatus[] | null;
};

function LawsuitCountByOwnStatus({data}: LawsuitCountByOwnStatusProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: Object.values(LawsuitStatus).map((status) => ({
          label: getOptionLabel(LawsuitStatusOptions, status),
          backgroundColor: LawsuitStatusColors[status],
          data: data.map((lawsuit) => {
            const lawsuitsCount = lawsuit.reports.find(r => r.status === status);
            return lawsuitsCount?.count ?? 0;
          }),
          stack: 'x',
          barPercentage: 0.4,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const flattenSum = data?.map(d => _.sumBy(d.reports, 'count'));
  const maxXValue = _.max(flattenSum) || 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxXValue)}/>
    </ChartContainer>
  );
}

export default React.memo(LawsuitCountByOwnStatus);
