import React from 'react';
import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';

export type CircularProgressWithLabelProps = CircularProgressProps & { value: number, textcolor?: string }

export default function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress variant="determinate" size={36} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!!props.value && <Typography
            variant="caption"
            component="div"
            color={props.textcolor || 'text.secondary'}
            fontSize="0.75rem"
        >{`${Math.round(props.value)}%`}
        </Typography>}
      </Box>
    </Box>
  );
}
