import PageTitle from '@components/page/PageTitle';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ReportTypeEnum } from '@declarations/common/reportType';
import routes from '@constants/routes';
import { Link } from 'react-router-dom';
import PageSubTitle from '@components/page/PageSubTitle';
import IncidentService from '@api/IncidentService';
import GoToLink from '@components/GoToLink';
import MedicalRRService from '@api/MedicalRRService';
import LawsuitService from '@api/LawsuitService';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import ReportService from '@api/ReportService';
import lawsuitColumns from '../../LawsuitReports/components/LawsuitReportsGrid/columns';
import medicalRRColumns from '../../MedicalRRRecords/components/MedicalReportsGrid/columns';
import incidentsColumns from '../../IncidentReports/components/IncidentReportsGrid/columns';
import ReportsGrid from '../components/ReportsGrid';
import MMRsCountByRiskLevel from '@components/charts/adminsCharts/dashboard/MMRsCountByRiskLevel';
import LawsuitsCount from '@components/charts/adminsCharts/dashboard/LawsuitsCount';
import { MRRRiskLevelCountsByFacility } from '@api/MedicalRRService/getRiskLevelCountsByFacility';
import { Totals } from '@api/ReportService/getTotals';
import { LawsuitsCountsByFacility } from '@api/LawsuitService/getLawsuitCountsByFacility';
import DashboardTotalChips from './mrrsTotalChips';
import IncidentsCountByRiskLevel from '@components/charts/adminsCharts/dashboard/IncidentsCountByRiskLevel';
import { IncidentByFacilityAndRiskLevel } from '@api/IncidentService/getFacilitiesIncidentsByRiskLevel';
import { SettingsSelectors } from '@store/slices/settings';
import { GridFilterItem } from '@mui/x-data-grid-pro';


const totalsDefault = {
  incident: NaN,
  incidentHighRisk: NaN,
  medical: NaN,
  medicalHighRisk: NaN,
  lawsuit: NaN,
  lawsuitOpen: NaN,
};

function DashboardTotal() {
  const userType = useSelector(UserSelectors.type);
  const selectedFacilities = useSelector(SettingsSelectors.facilitiesFromSelectedOrganization);
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const [gridFilterItem, setGridFilterItem] = useState<GridFilterItem>();

  const pageTitle = useMemo<string>(() => {
    if (!selectedFacilities) {
      return 'Facilities';
    }
    return selectedFacilities.length === 1 ? '1 Facility' : `${selectedFacilities.length} Facilities`;
  }, [selectedFacilities?.length]);

  const [totalsByFacilities, setTotalsByFacilities] = useState<Totals>(totalsDefault);
  const [incidentsCountByRiskLevel, setIncidentsCountByRiskLevel] = useState<IncidentByFacilityAndRiskLevel[] | null>(null);
  const [mrrsCountByRiskLevel, setMrrsCountByRiskLevel] = useState<MRRRiskLevelCountsByFacility [] | null>(null);
  const [lawsuitsCount, setLawsuitsCount] = useState<LawsuitsCountsByFacility[] | null>(null);

  const fetchTotalsByFacilities = () => {
    setTotalsByFacilities(totalsDefault);
    ReportService.getTotals(selectedOrganization?.id, selectedYear).then(({data}) => setTotalsByFacilities(data))
      .catch((e) => {
        setTotalsByFacilities({
          incident: 0,
          incidentHighRisk: 0,
          medical: 0,
          medicalHighRisk: 0,
          lawsuit: 0,
          lawsuitOpen: 0,
        });
        throw e;
      });
  };
  const fetchIncidentsCountByRiskLevel = () => {
    setIncidentsCountByRiskLevel(null);
    IncidentService.getFacilitiesIncidentsByRiskLevel(selectedOrganization?.id, selectedYear)
      .then(({data}) => {
        setIncidentsCountByRiskLevel(data);
      })
      .catch((e) => {
        setIncidentsCountByRiskLevel([]);
        throw e;
      });
  };
  const fetchMrrsCountByRiskLevel = () => {
    setMrrsCountByRiskLevel(null);
    MedicalRRService.getRiskLevelCountsByFacility(selectedOrganization?.id, selectedYear)
      .then(({data}) => {
        setMrrsCountByRiskLevel(data);
      })
      .catch((e) => {
        setMrrsCountByRiskLevel([]);
        throw e;
      });
  };

  const fetchLawsuitsCount = () => {
    setLawsuitsCount(null);
    LawsuitService.getLawsuitCountsByFacility(selectedOrganization?.id, selectedYear)
      .then(({data}) => {
        setLawsuitsCount(data);
      })
      .catch((e) => {
        setLawsuitsCount([]);
        throw e;
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchTotalsByFacilities();
      fetchIncidentsCountByRiskLevel();
      fetchMrrsCountByRiskLevel();
      fetchLawsuitsCount();
    };
    fetchData().catch(console.error);
    setGridFilterItem({
      id: selectedOrganization?.id,
      value: selectedOrganization?.name,
      field: 'operator',
      operator: 'equals',
    });
  }, [selectedOrganization?.id, selectedYear]);

  const areColumnsEditable = false;
  const incidentsColumnsDef = useMemo(() => incidentsColumns({userType, inlineEditEnabled: areColumnsEditable}), [userType]);
  const medicalRRColumnsDef = useMemo(() => medicalRRColumns({userType, inlineEditEnabled: areColumnsEditable}), [userType]);
  const lawsuitColumnsDef = useMemo(() => lawsuitColumns({userType, inlineEditEnabled: areColumnsEditable}), [userType]);

  return (
    <Box component="main">
      <PageTitle>{pageTitle}</PageTitle>
      <Grid container spacing={5}>
        <Grid
          component="section"
          item
          xs={12}
          sm={3}
          sx={{height: '100%'}}
        >
          <Stack gap={2} justifyContent="space-between">
            <DashboardTotalChips totals={totalsByFacilities}></DashboardTotalChips>
          </Stack>
        </Grid>

        <Grid item xs={9} xl={8} display="flex" flexGrow={1}>
          <IncidentsCountByRiskLevel data={incidentsCountByRiskLevel}/>
        </Grid>

        <Grid item xs={12} xl={11}>
          <Grid container sx={{marginBottom: 1}} spacing={3}>
            <Grid item xs={12} sm={6}>
              <MMRsCountByRiskLevel
                data={mrrsCountByRiskLevel}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LawsuitsCount
                data={lawsuitsCount}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} direction="row" flexWrap="wrap">
            <Grid item>
              <Button component={Link} variant="contained" to={routes.incidentReportForm} sx={{width: 272}}>
                New Incident
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                variant="contained"
                to={routes.medicalRecordRequestForm}
                sx={{width: 272, maxWidth: 272}}
              >
                New Medical Record Request
              </Button>
            </Grid>
            <Grid item>
              <Button component={Link} variant="contained" to={routes.lawsuitForm} sx={{width: 272}}>
                New Lawsuit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{mt: 3}}>
        <PageSubTitle>Recent Incident Reports</PageSubTitle>
        <ReportsGrid
          gridFilterItem={gridFilterItem}
          columns={incidentsColumnsDef}
          fetchRowsFn={IncidentService.list}
          patchFn={IncidentService.patch}
          reportKeyType={`total-${ReportTypeEnum.Incident}`}
        />
        <GoToLink to={routes.incidents}>Go to all Incidents Reports</GoToLink>
      </Box>

      <Box sx={{mt: 3}}>
        <PageSubTitle>Recent Medical Record Requests Reports</PageSubTitle>
        <ReportsGrid
          gridFilterItem={gridFilterItem}
          columns={medicalRRColumnsDef}
          fetchRowsFn={MedicalRRService.list}
          patchFn={MedicalRRService.patch}
          reportKeyType={`total-${ReportTypeEnum.MedicalRR}`}
        />

        <GoToLink to={routes.medicalRecordRequests}>
          Go to all Medical Record Requests Reports
        </GoToLink>
      </Box>

      <Box sx={{mt: 3}}>
        <PageSubTitle>Recent Lawsuit Notification Reports</PageSubTitle>
        <ReportsGrid
          gridFilterItem={gridFilterItem}
          columns={lawsuitColumnsDef}
          fetchRowsFn={LawsuitService.list}
          patchFn={LawsuitService.patch}
          reportKeyType={`total-${ReportTypeEnum.Lawsuit}`}
        />
        <GoToLink to={routes.lawsuits}>Go to all Lawsuit Notification Reports</GoToLink>
      </Box>
    </Box>
  );
}

export default DashboardTotal;
