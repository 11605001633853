import { AxiosInstance } from 'axios';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type IncidentByTypeAndStatus = {
  type: IncidentType;
  countsByStatus: {
    [S in ReportStatus]?: number;
  };
};

export default function getFacilityIncidentsByTypeAndStatus(api: AxiosInstance) {
  return (facilityId: number, riskLevel?: RiskLevel, yearOfIncidents?: number) => api.get<IncidentByTypeAndStatus[]>('/facility-by-type-and-status', {
    params: { facility_id: facilityId, risk_level: riskLevel, year_of_incidents: yearOfIncidents },
  });
}
