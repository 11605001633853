import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import { AxiosInstance } from 'axios';

const path = '/risk-level-by-facility';

export type MRRRiskLevelCountsByFacility = {
  facilityId: number;
  facilityName: string;
  countsByRiskLevel: { [R in RiskLevel]?: number; };
};

export default function getRiskLevelCountsByFacility(api: AxiosInstance) {
  return (organizationId?: number, yearOfMrrs?: number) => api.get<MRRRiskLevelCountsByFacility[]>(path, {
    params: {organization_id: organizationId, year_of_mrrs: yearOfMrrs},
  });
}
