import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import LawsuitService from '@api/LawsuitService';
import CRUDForm from '@components/CRUDForm';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import routes from '@constants/routes';
import { toast } from 'react-toastify';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { FormMode } from '@hooks/useFormEdit';
import { UserType } from '@declarations/common/enums/UserType';
import { UserSelectors } from '@store/slices/user';
import { useSelector } from 'react-redux';
import generateFields from './LawsuitForm.fields';
import lawsuitFormValidationSchema from './utils/validationSchemas/lawsuitForm.validation-schema';
import useFacilityOptions from '@hooks/useFacilityOptions';
import ReportService from '@api/ReportService';
import { ReportTypeEnum } from '@declarations/common/reportType';
import LinkedReportsModal, { LinkedReportsModalProps } from '@components/modals/LinkedReportsModal';
import IncidentService from '@api/IncidentService';
import MedicalRRService from '@api/MedicalRRService';
import UserLogRecord from '@components/SubmissionTabs/UserLogRecord';
import SubmissionTabs from '@components/SubmissionTabs/SubmissionTabs';
import HeaderBar from '@components/SubmissionTabs/HederBar';
import RiskLevel from '@components/SubmissionTabs/RiskLevel';

function LawsuitForm() {
  const userType = useSelector(UserSelectors.type);
  const navigate = useNavigate();
  const { facilities, selectedFacilityId } =  useFacilityOptions();
  const [dialogState, setDialogState] = useState<LinkedReportsModalProps>({open: false});

  const defaultValues = useMemo(() => ({
    facility: selectedFacilityId,
    lawsuitCopyFiles: [],
    releasedDocuments: []
  }), [selectedFacilityId]);

  const onSubmit = async (data: Lawsuit | FormData) => {
    if (data instanceof FormData && !data.get('linkedReports[incident][id]')) {
      const relations = (await IncidentService.getRelations(data.get('facility') as string, data.get('residentMedicalRecordNumber') as string)).data;
      const availableRelations = relations?.filter(r => r.linkedReports?.lawsuit?.id !== Number(data.get('id')));
      if (availableRelations?.length) {
        const dialog = new Promise((resolve) => {
          setDialogState({
            open: true,
            reportType: 'Incidents',
            onResult: resolve
          });
        });
        const result = await dialog;
        setDialogState({open: false});
        if (!result) { return; }
      }
    }

    if (data instanceof FormData && !data.get('linkedReports[medicalReport][id]')) {
      const relations = (await MedicalRRService.getRelations(data.get('facility') as string, data.get('residentMedicalRecordNumber') as string)).data;
      const availableRelations = relations?.filter(r => r.linkedReports?.lawsuit?.id !== Number(data.get('id')));
      if (availableRelations?.length) {
        const dialog = new Promise((resolve) => {
          setDialogState({
            open: true,
            reportType: 'MRRs',
            onResult: resolve
          });
        });
        const result = await dialog;
        setDialogState({open: false});
        if (!result) { return; }
      }
    }

    const { data: responseData } = await LawsuitService.upsert(data);

    if (responseData.id && data instanceof FormData) {
      await ReportService.setFacilityReportsRelation({
        sourceReport: ReportTypeEnum.Lawsuit,
        incidentId: Number(data.get('linkedReports[incident][id]')) || null,
        lawsuitId: responseData.id,
        mrrId: Number(data.get('linkedReports[medicalReport][id]')) || null,
      });
    }

    navigate(routes.lawsuits);

    if (responseData.calculatedStatus === ReportStatus.Completed) {
      toast.success((
        <>
          Lawsuit Report successfully saved.
          <br />
          The report is now completed.
        </>
      ));
    } else {
      toast.warn((
        <>
          Lawsuit Report successfully saved.
          <br />
          Please provide all information to complete the report.
        </>
      ));
    }
  };

  return (
    <>
      <SubmissionTabs active="" prefix={routes.lawsuitForm} />
      <HeaderBar title="Facility User" description="Complete the following" />
      <CRUDForm
        useFormData
        defaultValues={defaultValues}
        validationSchema={lawsuitFormValidationSchema}
        fetchEntityMethod={LawsuitService.getById}
        renderTitle={(form, mode) => (
          mode === FormMode.Create
            ? <>New Lawsuit Notification Report</>
            : (
              <>
                Lawsuit Notification:
                {' '}
                {form.watch('reportNumber')}
                {' '}
                (
                {moment(form.watch('createdAt')).format('MM/DD/YYYY')}
                )
                {' '}
                <RiskLevel risk={10} />
              </>
            )
        )}
        onSubmit={onSubmit}
        fields={generateFields({ facilities, userType })}
        backRoute={routes.lawsuits}
        editDisabled={(form) => moment(form.watch('createdAt')).add(14, 'days').isBefore(moment()) && userType !== UserType.SuperAdmin}
      />
      <UserLogRecord reportType={ReportTypeEnum.Lawsuit} userType={userType} />
      <LinkedReportsModal {...dialogState} />
    </>
  );
}

export default LawsuitForm;
