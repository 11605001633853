import { AxiosInstance, AxiosResponse } from "axios";

export type IncidentPossibleRelation = {
  id: number,
  noticeDate: Date,
  reportNumber: string,
  residentBirthdate: Date,
  residentGender: string,
  residentFirstName: string,
  residentLastName: string,
  isCurrentResident: boolean,
  linkedReports: {
    id: number,
    lawsuit: { id: number, reportNumber: string, noticeDate: Date } | null,
    medicalReport: { id: number, reportNumber: string, requestDate: Date } | null,
  } | null,
};

export type CaseResponse = IncidentPossibleRelation[];

const getRelations = (api: AxiosInstance) => (facilityId: string, residentMedicalRecordNumber: string): Promise<AxiosResponse<CaseResponse>> => {
  const path = `/get-possible-relations?facility_id=${facilityId}&medical_record_number=${residentMedicalRecordNumber}`;
  return api.get<CaseResponse>(path);
}

export default getRelations;