import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import isHighRisk from '@utils/isHighRisk';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import { yesNoOptions } from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.SelfInflictedInjury], undefined>;

function getSelfInflictedInjuryFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = <T extends keyof RequiredFields>(field: T) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.SelfInflictedInjury}.${field}` as T;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.SelfInflictedInjury;
    }

    return form.watch('typeOfIncident') === IncidentType.SelfInflictedInjury;
  };

  return {
    fields: [
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didResidentHaveHistoryOfSelfHarm'),
          label: 'Did the resident have a history of self-harm or making statements of wanting to self-harm in the past?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasResidentReceivingMentalHealthServices'),
          label: 'Was the resident receiving mental health services?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didTheResidentRequireHospitalization'),
          label: 'Did the resident require hospitalization?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didEventIncidentResultInPermanentInjuryOrDeath'),
          label: 'Did the event/incident result in permanent injury or death?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
    ],
    fileFields: [
      [
        {
          type: 'file',
          required: false,
          name: getFieldName('gdsFiles'),
          label: 'GDS',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
      [
        {
          type: 'file',
          required: false,
          name: getFieldName('phq9Files'),
          label: 'PHQ9',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
      [
        {
          type: 'file',
          required: false,
          name: getFieldName('bimsFiles'),
          label: 'BIMS',
          condition: makeCondition,
          hidden: params.mode === FormMode.Create || !isHighRisk(params.form.watch('riskPoints')),
        },
      ],
    ],
  };
}

export default getSelfInflictedInjuryFields;
