import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from 'react';
import { Typography } from '@mui/material';

function yesNoRender({ value }: GridRenderCellParams) {
  return value 
    ? <Typography color="green" fontSize={14}>Yes</Typography>
    : <Typography color="red" fontSize={14}>No</Typography>
}

export default yesNoRender;