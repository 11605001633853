import React, { useState } from 'react';
import { IconButton, Stack, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { UploadedFile } from '@declarations/common/uploadedFile';
import FileService from '@api/FileService';
import CircularProgressWithLabel from '@components/filesUpload/CircularProgressWithLabel';

interface Props {
  file: File | UploadedFile;
  removeFile: (filename: string, fileHash?: string, isUploadedFile?: boolean) => void;
  disabled: boolean;
}

function FileItem(props: Props) {
  const {file, removeFile, disabled} = props;
  const isUploadedFile = !(file instanceof File);
  const [downloadProgress, setDownloadProgress]: [number, ((value: (((prevState: number) => number) | number)) => void)] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const handleDownload = async () => {
    setDownloading(true);
    try {
      setDownloadProgress(0.1);
      await FileService.handleDownload((file as UploadedFile).hash, setDownloadProgress);
    } finally {
      setDownloadProgress(0);
      setDownloading(false);
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="body1">{file.name}</Typography>
        <IconButton disabled={disabled} onClick={() => removeFile(file.name, (file as any).hash, isUploadedFile)}>
          <CloseIcon style={{color: disabled ? '#757575' : 'red'}}/>
        </IconButton>
      {
        isUploadedFile && (
          <>
            {!downloading && (
              <IconButton onClick={handleDownload}>
                <DownloadOutlinedIcon style={{color: 'blue'}}/>
              </IconButton>
            )}
            {downloading && (<CircularProgressWithLabel value={downloadProgress}/>)}
          </>
        )
      }
    </Stack>
  );
}

export default FileItem;
