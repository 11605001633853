import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import {
  burnSources, SourceBurn, yesNoOptions,
} from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.Burns], undefined>;

function getBurnsFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = <T extends keyof RequiredFields>(field: T) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.Burns}.${field}` as T;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.Burns;
    }

    return form.watch('typeOfIncident') === IncidentType.Burns;
  };

  return {
    fields: [
      [
        {
          type: 'select',
          fullWidth: true,
          name: getFieldName('sourceOfBurn'),
          label: 'What was the source of the burn?',
          options: burnSources,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didTheBurnOriginateWhileCareWasBeingProvided'),
          label: 'Did the burn originate while care was being provided?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const sourceOfBurn = conditionParams.form.watch(getFieldName('sourceOfBurn'));

            result = result && sourceOfBurn === SourceBurn.ShowerBath;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didStaffRespondAccordingToFacilityEmergencyProtocols'),
          label: 'Did staff respond according to facility emergency protocols?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const sourceOfBurn = conditionParams.form.watch(getFieldName('sourceOfBurn'));

            result = result && sourceOfBurn === SourceBurn.FireSmoke;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasEquipmentAndWiringInGoodWorkingOrder'),
          label: 'Was equipment and wiring in good working order and within regulations to be used in the facility?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const sourceOfBurn = conditionParams.form.watch(getFieldName('sourceOfBurn'));

            result = result && [SourceBurn.Electrical, SourceBurn.HotSurface].includes(sourceOfBurn);

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didTheBurnOriginateWhileAssistanceWasBeingProvided'),
          label: 'Did the burn originate while assistance was being provided?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const sourceOfBurn = conditionParams.form.watch(getFieldName('sourceOfBurn'));

            result = result && sourceOfBurn === SourceBurn.HotLiquid;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasHandlingOfHotLiquidsConsidered'),
          label: 'Was the handling of the hot liquids considered within the resident\'s current plan of care and within their physical and cognitive ability?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const sourceOfBurn = conditionParams.form.watch(getFieldName('sourceOfBurn'));

            result = result && sourceOfBurn === SourceBurn.HotLiquid;

            result = result && conditionParams.form.watch(getFieldName('didTheBurnOriginateWhileAssistanceWasBeingProvided')) === false;

            return result;
          },
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didResidentRequireHospitalization'),
          label: 'Did the resident require hospitalization?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasIncidentResultedInPermanentInjuryOrDeath'),
          label: 'Did the event/incident result in permanent injury or death?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
    ],
    fileFields: [],
  };
}

export default getBurnsFields;
