import React, { ChangeEvent, useMemo } from 'react';
import { FormControl, FormHelperText, FormLabel, Stack } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useDisableFormProvider } from '../providers/DisableFormProvider';
import { FieldError } from 'react-hook-form';

export type RadioValue = number | string | boolean | null;

interface Option {
  label: string
  value: RadioValue
}

interface Props<T> {
  label: string
  name: string
  options: Option[]
  value: RadioValue
  onChange: (name: string, value: RadioValue) => void
  isVisible?: boolean
  error?: FieldError | boolean
  required?: boolean
  disabled?: boolean
}

function RadioComponent<T>(props: Props<T>) {
  const {
    label, name, options, value, onChange, isVisible = true, required,
  } = props;

  const { disabled } = useDisableFormProvider();

  const fixedOptions = useMemo(() => options.map((it, idx) => ({
    id: String(idx),
    label: it.label,
    value: it.value,
  })), [options]);

  const selectedId = fixedOptions.find((it) => it.value === value)?.id || null;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value: id } = event.target;
    const option = fixedOptions.find((it) => it.id === id);
    if (!option) {
      return;
    }
    onChange(name, option.value);
  };

  const renderError = () => {
    if (typeof props.error === 'object' && 'message' in props.error) return props.error.message;

    return undefined;
  };

  if (!isVisible) {
    return null;
  }

  return (
    <FormControl
      variant="filled"
      error={!!props.error && !disabled}
      required={required}
      disabled={disabled || props.disabled}
    >
      <Stack spacing={0.5}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup row name={name} value={selectedId} onChange={handleChange}>
          {fixedOptions.map((it) => (
            <FormControlLabel key={it.id} value={it.id} control={<Radio />} label={it.label} />
          ))}
        </RadioGroup>
        <FormHelperText>{renderError()}</FormHelperText>
      </Stack>
    </FormControl>
  );
}

export default React.memo(RadioComponent);
