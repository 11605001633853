import React, { useMemo } from 'react';
import {
  Button, Divider, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import routes from '@constants/routes';
import PageTitle from '../../components/page/PageTitle';
import Loader from '../../components/Loader';
import { UserTypeName } from '@declarations/common/enums/UserType';
import { ExpertRoleName } from '@declarations/common/enums/ExpertRole';

interface LineProps {
  label: string;
  value: string;
}

function Line(props: LineProps) {
  const { label, value } = props;
  return (
    <Stack direction="row" spacing={4} sx={{ mb: 1 }}>
      <Typography variant="body1" color="textSecondary" style={{ width: '130px', minWidth: '130px' }}>
        {label}
      </Typography>
      <Typography variant="body1" color="info.dark" fontStyle="italic">
        {value}
      </Typography>
    </Stack>
  );
}

function UserProfilePage() {
  const navigate = useNavigate();
  const user = useSelector(UserSelectors.user);

  const onClickClose = () => {
    navigate(routes.dashboard);
  };

  const loading = false;
  const organizations = useMemo(() => user.organizations.map((organization) => organization.name).join(', '), [user.organizations]);
  const facilities = useMemo(() => user.facilities.map((facility) => facility.name).join(', '), [user.facilities]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle mb={2}>User Profile</PageTitle>

      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        {user.firstname}
        {' '}
        {user.lastname}
      </Typography>

      <Divider sx={{ mt: 2, mb: 2, maxWidth: 320 }} />

      <Line label="Position" value={user.position} />
      <Line label="Role" value={UserTypeName[user.type]} />
      {user.expertRole && <Line label="Expert Role" value={ExpertRoleName[user.expertRole]} />}
      <Line label="Organization" value={organizations} />
      <Line label="Facility" value={facilities} />

      <Divider sx={{ mt: 2, mb: 2, maxWidth: 320 }} />

      <Line label="Email" value={user.email} />
      <Line label="Contact number" value={user.contactNumber} />

      <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
        <Button onClick={onClickClose} variant="outlined" color="info" sx={{ minWidth: '150px' }}>
          Close
        </Button>
        {/* <Button variant="outlined" color="error" sx={{ minWidth: '150px' }}>
          Change Password
        </Button> */}
      </Stack>
    </>
  );
}

export default UserProfilePage;
