import React from 'react';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface Props {
  children: React.ReactNode
  mb?: number
  mt?: number
  variant?: Variant
}

function PageTitle(props: Props) {
  const {
    children, mt = 0, mb = 3, variant = 'h4',
  } = props;

  return (
    <Typography variant={variant} color="black" align="left" sx={{ mt, mb, fontWeight: 400 }}>
      {children}
    </Typography>
  );
}

export default React.memo(PageTitle);
