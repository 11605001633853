import React from 'react';
import { GridColumnMenuProps, GridColumnMenu } from '@mui/x-data-grid-pro';
import ColumnMenuHardResetItem from '@components/DataGrid/components/ColumnMenu/ColumnMenuHardResetItem';
import ColumnMenuAutosizeItem from '@components/DataGrid/components/ColumnMenu/ColumnMenuAutosizeItem';

function ColumnMenu(props: GridColumnMenuProps) {

  return (
    <GridColumnMenu
      {...props}
      slots={{
        ColumnMenuAutosizeItem,
        // ColumnMenuResetItem,
        ColumnMenuHardResetItem,
      }}
      slotProps={{
        // ColumnMenuResetItem: {
        //   displayOrder: 30,
        // },
        ColumnMenuAutosizeItem: {
          displayOrder: 30,
        },
        ColumnMenuHardResetItem: {
          displayOrder: 31,
        },
      }}
    />
  );
}

export default ColumnMenu;
