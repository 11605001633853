import React from 'react';
import { Stack } from '@mui/material';
import StatBox from '@components/StatBox';
import { LawsuitTotalsByFacilities } from '@api/LawsuitService/getTotalsByFacilities';
import { MRRTotalsByFacilities } from '@api/MedicalRRService/getTotalsByFacilities';

export type MedicalRRTotalChipsProps = {
  totals: MRRTotalsByFacilities;
}

function MedicalRRTotalChips({totals}: MedicalRRTotalChipsProps) {
  return (
    <Stack direction="row" spacing={2} sx={{marginBottom: 5}}>
      <StatBox
        title="Total MRR"
        loading={Number.isNaN(totals.medical)}
        count={totals.medical}
      />
      <StatBox
        title="Total Incomplete"
        loading={Number.isNaN(totals.incomplete)}
        count={totals.incomplete}
      />
      <StatBox
        title="Total High Risk MRR"
        loading={Number.isNaN(totals.highRisk)}
        count={totals.highRisk}
      />
    </Stack>
  );
}

export default React.memo(MedicalRRTotalChips);