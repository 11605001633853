import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, useGridModels } from '@components/DataGrid';
import { Box } from '@mui/system';
import useGridData from '@components/DataGrid/hooks/useGridData';
import routes from '../../constants/routes';
import PageTitle from '../../components/page/PageTitle';
import usersColumns from './usersColumns';
import UserService from '../../api/userService';
import TableToolbar from '@components/TableToolbar';

function UserManagementPage() {
  const gridModels = useGridModels();
  const { data, loading } = useGridData({ fetchMethod: UserService.list, gridModels });

  return (
    <>
      <PageTitle>User Management</PageTitle>

      <TableToolbar
        showRemove={false}
        removalModalTitle=""
        removalModalContent=""
        exportCSVFile={{
          fileName: () => `users-${new Date().toLocaleDateString('en-US')}.csv`,
          fetchMethod: UserService.exportCSV,
          fetchParams: gridModels.getFetchListParams(),
        }}
      >
        <Button component={Link} variant="contained" to={routes.userForm}>
          New user
        </Button>
      </TableToolbar>

      <DataGrid
        loading={loading}
        rows={data}
        columns={usersColumns}
        stateName="users"
        {...gridModels}
      />
    </>
  );
}

export default React.memo(UserManagementPage);
