import { ReportType } from '@declarations/common/reportType';
import initInstance from './axios';

const api = initInstance('/clinical-log-record');

type CreateClinicalLogRecord = {
  reportId: number;
  reportType: ReportType;
  legalReviewRecommended: boolean;
  note?: string;
}

type QueryClinicalLogRecords = {
  reportId: number;
  reportType: ReportType;
}

const ClinicalLogRecordService = {
  createRecord: (data: CreateClinicalLogRecord | FormData) => api.post('/create-record', data),
  completedReview: (params: QueryClinicalLogRecords) => api.get('/completed-review', { params }),
  get: (params: QueryClinicalLogRecords) =>
    api.get<any[]>('', { params }),
};

export default ClinicalLogRecordService;