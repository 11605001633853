export enum IncidentType {
  FallMajorInjury = 'fallMajorInjury',
  Wound = 'wound',
  MedicationError = 'medicationError',
  AbuseOrNeglect = 'abuseOrNeglect',
  Elopement = 'elopement',
  Choking = 'choking',
  Entrapment = 'entrapment',
  Burns = 'burns',
  SelfInflictedInjury = 'selfInflictedInjury',
  UnplannedDeath = 'unplannedDeath',
  Other = 'other',
}
