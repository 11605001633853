import initInstance from '@api/axios';
import authWithAzure from './azure';

const api = initInstance('/auth');

const AuthService = {
  links: {
    authWithAzure: authWithAzure(api),
    logout: () => api.getUri({ url: '/logout' }),
  },
};

export default AuthService;
