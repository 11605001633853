import { Option } from '@declarations/common/option';
import { IncidentType } from '@declarations/common/enums/IncidentType';

export const IncidentTypeLabels = {
  [IncidentType.FallMajorInjury]: 'Fall w/ Major Injury',
  [IncidentType.Wound]: 'Wound',
  [IncidentType.MedicationError]: 'Medication Error',
  [IncidentType.AbuseOrNeglect]: 'Abuse / Neglect',
  [IncidentType.Elopement]: 'Elopement',
  [IncidentType.Choking]: 'Choking',
  [IncidentType.Entrapment]: 'Entrapment',
  [IncidentType.Burns]: 'Burns',
  [IncidentType.SelfInflictedInjury]: 'Self-Inflicted Injury',
  [IncidentType.UnplannedDeath]: 'Unplanned Death',
  [IncidentType.Other]: 'Other',
};

const IncidentTypeOptions: Option<IncidentType>[] = [
  { label: IncidentTypeLabels[IncidentType.FallMajorInjury], value: IncidentType.FallMajorInjury },
  { label: IncidentTypeLabels[IncidentType.Wound], value: IncidentType.Wound },
  { label: IncidentTypeLabels[IncidentType.MedicationError], value: IncidentType.MedicationError },
  { label: IncidentTypeLabels[IncidentType.AbuseOrNeglect], value: IncidentType.AbuseOrNeglect },
  { label: IncidentTypeLabels[IncidentType.Elopement], value: IncidentType.Elopement },
  { label: IncidentTypeLabels[IncidentType.Choking], value: IncidentType.Choking },
  { label: IncidentTypeLabels[IncidentType.Entrapment], value: IncidentType.Entrapment },
  { label: IncidentTypeLabels[IncidentType.Burns], value: IncidentType.Burns },
  { label: IncidentTypeLabels[IncidentType.SelfInflictedInjury], value: IncidentType.SelfInflictedInjury },
  { label: IncidentTypeLabels[IncidentType.UnplannedDeath], value: IncidentType.UnplannedDeath },
  { label: IncidentTypeLabels[IncidentType.Other], value: IncidentType.Other },
];

export const IncidentTypeColors = {
  [IncidentType.FallMajorInjury]: '#136594',
  [IncidentType.Wound]: '#2d90c8',
  [IncidentType.MedicationError]: '#28afd9',
  [IncidentType.AbuseOrNeglect]: '#69bdec',
  [IncidentType.Elopement]: '#97e0ff',
  [IncidentType.Choking]: '#1fcbfa',
  [IncidentType.Entrapment]: '#27d2cd',
  [IncidentType.Burns]: '#4bd784',
  [IncidentType.SelfInflictedInjury]: '#86d29b',
  [IncidentType.UnplannedDeath]: '#d3e07c',
  [IncidentType.Other]: '#ffde7a'
}

export default IncidentTypeOptions;
