import {Incident} from '@declarations/models/reports/Incident';
import moment from 'moment';
import {
  object, date,
} from 'yup';
import Errors from '@constants/locale/errors';
import baseReportValidationSchema from '../../../../utils/validation-schemas/baseReportValidationSchema';
import {string} from 'yup';

// TODO: add SchemaOf<Incident> for all fields
const incidentFormValidationSchema = object({
  ...baseReportValidationSchema,

  admitDate: date()
    .nullable()
    .test({
      message: 'The Admission date can not be after the Date of Incident',
      test: (value, context) => {
        const parent = context.parent as Incident;

        if (!parent.incidentDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrBefore(parent.incidentDate, 'day');
      },
    }).typeError(Errors.VALID_DATE),

  incidentDate: date()
    .test({
      message: 'The Incident date can not be prior to the Admission date',
      test: (value, context) => {
        const parent = context.parent as Incident;

        if (!parent.admitDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrAfter(parent.admitDate, 'day');
      },
    }).typeError(Errors.VALID_DATE),

  dischargeDate: date()
    .when('isCurrentResident', (isCurrentResident, schema) => !isCurrentResident ? schema.required(Errors.REQUIRED_FIELD) : schema.nullable())
    .test({
      message: 'The Discharge date can not be prior to the Date of Incident',
      test: (value, context) => {
        const parent = context.parent as Incident;

        if (parent.isCurrentResident) {
          return true;
        }

        if (!parent.incidentDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrAfter(parent.incidentDate, 'day');
      },
    })
    .transform(v => (v instanceof Date && !isNaN(v as any) ? v : null))
    .typeError(Errors.VALID_DATE),

  typeOfIncident: string().required(Errors.REQUIRED_FIELD),

  chanceOfLegalRepresentation: string().nullable().transform(v => v || null),
});

export default incidentFormValidationSchema;
