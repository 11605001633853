import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import HeaderBar from './HederBar';
import { useSearchParams } from 'react-router-dom';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { ReportType } from '@declarations/common/reportType';
import { Box } from '@mui/material';
import userFormatter from '@components/DataGrid/renders/userFormatter';
import dateFormatter from '@components/DataGrid/renders/dateFormatter';
import ProviderLogRecordService from '@api/providerLogRecordService';
import attachmentsRender from '@components/DataGrid/renders/attachmentsRender';
import LogRecordContainer from './LogRecordContainer';

type Props = {
  reportType: ReportType;
};

const columns: GridColDef<any>[] = [
  {
    field: 'subject',
    headerName: 'Subject',
    flex: 0.5,
  },
  {
    field: 'note',
    headerName: 'Notes',
    flex: 1,
  },
  {
    field: 'author',
    headerName: 'Author',
    flex: 0.5,
    valueFormatter: userFormatter,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    flex: 0.5,
    valueFormatter: dateFormatter,
  },
  {
    field: 'attachments',
    headerName: 'Attachments',
    flex: 0.5,
    sortable: false,
    renderCell: attachmentsRender,
  },
];

const ProviderLogRecord = forwardRef<{ refresh: () => void }, Props>(({ reportType }: Props, ref) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const id = Number(searchParams.get('id'));

  const fetchRecord = async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    const response = await ProviderLogRecordService.get({ reportId: Number(id), reportType });
    setData(response.data);
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    refresh: () => { fetchRecord(); }
  }), []);

  useEffect(() => {
    fetchRecord();
  }, [searchParams]);

  return (
    <>
      <Box sx={{ mt: '32px' }}>
        <HeaderBar title="Log Record" />
        <LogRecordContainer rows={data.length}>          
          <DataGridPro
            rows={data}
            autoHeight={data.length <= 5}
            rowSelection={false}
            disableColumnMenu
            disableColumnFilter
            columns={columns}
            loading={loading}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
                whiteSpace: 'pre-line',
              },
            }}
            getRowHeight={() => 'auto'}
          />
        </LogRecordContainer>
      </Box>
    </>
  );
});

export default memo(ProviderLogRecord);
