export default function downloadFile(file: Blob, fileName: string) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
