import debounce from 'lodash/debounce';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { ListWithPagination, PaginationParams } from '@declarations/common/pagination';
import { useGridModels } from '@components/DataGrid';

type UseGridDataParams<T> = {
  fetchMethod: (params: any) => Promise<AxiosResponse<ListWithPagination<T>>>;
  gridModels: ReturnType<typeof useGridModels>;
  fetchParams?: any;
};

const useGridData = <T>({ fetchMethod, gridModels, fetchParams }: UseGridDataParams<T>) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = useCallback(async (_gridModels: ReturnType<typeof useGridModels>, params: any = {}) => {
    try {
      setData([]);
      setLoading(true);

      const paginationParams: PaginationParams = {
        skip: _gridModels.paginationModel.pageSize * _gridModels.paginationModel.page,
        take: _gridModels.paginationModel.pageSize,
      };

      const { data } = await fetchMethod({
        ...params,
        pagination: paginationParams,
        filter: _gridModels.filterModel,
        order: _gridModels.sortModel,
      });

      if (data) {
        setData(data.items || []);
        _gridModels.setRowCount(data.total ?? 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMethod]);

  const debounced = useCallback(debounce(getData, 300), [getData]);

  useEffect(() => {
    if (gridModels.stateLoaded) {
      debounced(gridModels, fetchParams);
    }
  }, [
    debounced,
    fetchParams,
    gridModels.paginationModel.pageSize,
    gridModels.paginationModel.page,
    gridModels.setRowCount,
    gridModels.filterModel,
    gridModels.sortModel,
    gridModels.stateLoaded,
  ]);

  return { data, loading, getData: debounced.bind(undefined, gridModels) };
};

export default useGridData;
