import ExpertUserService from '@api/ExpertUserService';
import routes from '@constants/routes';
import useApi from '@hooks/useApi';
import { Badge, Button, CircularProgress, Skeleton } from '@mui/material';
import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

function DashboardExpert() {
  const { data, isLoading, fetch } = useApi({
    method: ExpertUserService.notReviewedReportsCount,
    initialData: {} as any,
  });

  useEffect(() => {
    fetch();
  }, [])

  const color = isLoading ? undefined : 'error';
  const getValue = (key: 'incidents' | 'mrrs' | 'lawsuits') => isLoading
    ? <Skeleton variant="circular" width={22} height={22} />
    : data[key];

  return (
    <div>
      <h1>Welcome!</h1>
      <div>Click on the reports to complete your review for submissions you're assigned to.</div>
      <div style={{ display: 'flex', justifyContent: 'start', gap: '40px', marginTop: '50px' }}>
        <Badge badgeContent={getValue('incidents')} color={color}>
          <Button
            component={Link}
            to={routes.incidents}
            variant="contained"
            sx={{ width: '170px' }}
          >
            Assigned Incidents
          </Button>
        </Badge>
        <Badge badgeContent={getValue('mrrs')} color={color}>
          <Button
            component={Link}
            to={routes.medicalRecordRequests}
            variant="contained"
            sx={{ width: '170px' }}
          >
            Assigned MRR
          </Button>
        </Badge>
        <Badge badgeContent={getValue('lawsuits')} color={color}>
          <Button component={Link} to={routes.lawsuits} variant="contained" sx={{ width: '170px' }}>
            Assigned Lawsuits
          </Button>
        </Badge>
      </div>
    </div>
  );
}

export default memo(DashboardExpert);
