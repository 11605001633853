/* eslint-disable no-underscore-dangle */
import { ServerError } from '@declarations/common/serverError';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import asyncDebounce from '../utils/asyncDebounce';

const setTokenToConfig = (config: AxiosRequestConfig, token?: string | null) => {
  if (!token) {
    token = localStorage.getItem('accessToken');
  }

  if (token && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const refresh = asyncDebounce(async () => {
  const {data} = await axios.get('/auth/azure/refresh', {
    params: {
      refreshToken: localStorage.getItem('refreshToken'),
    },
    baseURL: process.env.REACT_APP_BASE_URL || '',
  });

  const {accessToken, refreshToken} = data;
  localStorage.setItem('accessToken', accessToken as string);
  localStorage.setItem('refreshToken', refreshToken as string);
  return accessToken;
}, 1000);

export default function initInstance(basePath = '') {
  const baseUrl = process.env.REACT_APP_BASE_URL || '';

  const instance = axios.create({
    baseURL: `${baseUrl}${basePath}`,
  });

  instance.interceptors.request.use(setTokenToConfig);

  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError<ServerError>) => {

      const {response} = error;

      if ((response?.data.status === 401 && response?.data.needsRefresh) || response?.status === 424) {
        try {
          const accessToken = await refresh();

          if (error.config) {
            error.config.headers = Object.assign({}, error.config.headers);
            error.config = setTokenToConfig(error.config, accessToken);

            return await axios(error.config);
          }

          return await Promise.reject(error);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      return Promise.reject(error);
    },
  );

  return instance;
}
