import { Option } from '@declarations/common/option';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';

const ReportStatusOptions: Option<ReportStatus>[] = [
  { value: ReportStatus.Completed, label: 'Completed' },
  { value: ReportStatus.Incomplete, label: 'Incomplete' },
  { value: ReportStatus.InProgress, label: 'In progress' },
];

export default ReportStatusOptions;
