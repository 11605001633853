import { IncidentCountsByHighRiskTypes } from '@api/IncidentService/getCountsByHighRiskTypes';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import ChartContainer from '@components/charts/ChartContainer';
import React, { useEffect, useState } from 'react';
import IncidentTypeOptions, { IncidentTypeColors } from '@constants/options/IncidentTypeOptions';
import { ChartData } from 'chart.js/dist/types';

const options: ChartProps<'doughnut'>['options'] = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'High Risk Incident Type',
      font: {
        size: 20,
      },
    },
    legend: {
      display: true,
    },
  },
};

type IncidentsHighRiskTypesProps = {
  data: IncidentCountsByHighRiskTypes[] | null;
};

function IncidentsHighRiskTypes({data}: IncidentsHighRiskTypesProps) {
  const [chartData, setChartData] = useState<ChartData<'doughnut'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: IncidentTypeOptions.map(itc => itc.label),
        datasets: [
          {
            data: IncidentTypeOptions.map(({value}) => data.find(d => d.type === value)?.count || 0),
            backgroundColor: Object.values(IncidentTypeColors),
          },
        ],
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  return (
    <ChartContainer loading={!chartData}>
      <Doughnut data={chartData!} options={options}/>
    </ChartContainer>
  );
}

export default React.memo(IncidentsHighRiskTypes);
