import React, { useMemo } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { getPalette } from './getPalette';
import { getTypography } from './getTypography';
import { getCustomShadows } from './shadows';
import componentsOverrides from './overrides/componentsOverrides';

interface Props {
  children: React.ReactNode
}

export default function ThemeCustomization(props: Props) {
  const { children } = props;
  const theme = getPalette();
  const themeTypography = getTypography();
  const themeCustomShadows = useMemo(() => getCustomShadows(theme), [theme]);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [theme, themeTypography, themeCustomShadows],
  );

  const themes = createTheme(themeOptions);
  themes.components = componentsOverrides(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
