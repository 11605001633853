import React from 'react';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Facility } from '@declarations/models/Facility';
import facilityTypeOptions from '@constants/options/FacilityTypeOptions';
import { FacilityType } from '@declarations/common/enums/FacilityType';
import IdLink from '../../components/IdLink';
import routes from '../../constants/routes';
import getOptionLabel from '../../utils/getOptionLabel';
import { filterRequiredOperators } from '@components/DataGrid/utils/gridOperators';

const facilitiesColumns: GridColDef<Facility>[] = [
  {
    field: 'name',
    align: 'left',
    headerName: 'Facility Name',
    minWidth: 160,
    renderCell: (params) => (
      <IdLink id={params.row.id} href={routes.facilityForm} value={params.row.name} />
    ),
    filterOperators: filterRequiredOperators(getGridStringOperators()),
  },
  {
    field: 'organization',
    align: 'left',
    headerName: 'Organization',
    minWidth: 140,
    filterOperators: filterRequiredOperators(getGridStringOperators()),
  },
  {
    field: 'type',
    align: 'left',
    headerName: 'Facility type',
    minWidth: 160,
    renderCell: (params: GridRenderCellParams<Facility, FacilityType>) => getOptionLabel(
      facilityTypeOptions,
      params.value,
    ),
    type: 'singleSelect',
    valueOptions: facilityTypeOptions,
    filterOperators: filterRequiredOperators(getGridSingleSelectOperators()),
  },
  {
    field: 'primaryContact',
    align: 'left',
    headerName: 'Primary Contact',
    minWidth: 140,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'contactNumber',
    align: 'left',
    headerName: 'Contact Number',
    minWidth: 160,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'city',
    align: 'left',
    headerName: 'City',
    minWidth: 100,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'state',
    align: 'left',
    headerName: 'State',
    minWidth: 80,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'additionalInfo',
    align: 'left',
    headerName: 'Additional Information',
    minWidth: 160,
  },
];

export default facilitiesColumns;
