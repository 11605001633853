import { AxiosInstance } from 'axios';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';

export type MRRStatusCountsByRiskLevel = {
  riskLevel: RiskLevel;
  countsByStatus: {
    [R in ReportStatus]?: number;
  };
};

export default function getMRRStatusByRiskLevel(api: AxiosInstance) {
  return (facilityId: number, yearOfMrrs?: number) => api.get<MRRStatusCountsByRiskLevel[]>('/status-by-risk-level', {
    params: { facility_id: facilityId, year_of_mrrs: yearOfMrrs },
  });
}
