import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import _ from 'lodash';

export const clearFormByVisibility = (form: object, visibility: object) => {
  for (const key in visibility) {
    // @ts-ignore
    if (visibility[key] === false) {
      // @ts-ignore
      form[key] = null;
    }
  }
};

export const registerChartJSDependencies = (): void => {
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  ChartJS.register({
    id: 'NoData',
    afterDraw: chart => {
      const isNoData = chart.data.datasets
        .map((d) => _.sum(d.data))
        .every(e => e === 0);
      if (isNoData) {
        const {ctx, data, chartArea: {top, bottom, left, right, width, height}} = chart;
        ctx.fillStyle = 'rgba(27, 127, 213, 0.75)';
        ctx.fillRect(left, top, width, height);
        ctx.fillStyle = 'rgb(221, 198, 106)';
        ctx.textAlign = 'center';
        ctx.font = `bold 1rem 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`;
        ctx.fillText('No data to display', left + width / 2, top + height / 2);
      }
    },
  });
}
