import React, { useEffect, useState } from 'react';
import { IncidentStatusCountsByMonth } from '@api/IncidentService/getStatusByMonth';
import { ChartData } from 'chart.js';
import ChartContainer from '@components/charts/ChartContainer';
import { Bar, ChartProps } from 'react-chartjs-2';
import { months } from '@components/charts/common';
import getOptionLabel from '@utils/getOptionLabel';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import calculateAxisMaxValue from '@utils/calculateAxisMaxValue';
import moment from 'moment';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Monthly Incident',
      font: {
        size: 20,
      },
    },
  },
});

type IncidentStatusByMonthProps = {
  data: IncidentStatusCountsByMonth[] | null;
};

function IncidentStatusByMonth({data}: IncidentStatusByMonthProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  useEffect(() => {
    if (data) {
      setChartData({
        labels: months,
        datasets: Object.values(ReportStatus).map((status) => ({
          label: getOptionLabel(ReportStatusOptions, status),
          data: months.map(m => {
            const currentMonthData: IncidentStatusCountsByMonth | undefined =
              data.find(d => moment(m, 'MMM').isSame(moment(d.month, 'M')));
            return currentMonthData ? currentMonthData.countsByStatus[status] ?? 0 : null;
          }),
          backgroundColor: ReportStatusColors[status],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const maxYValue: number = data ? calculateAxisMaxValue(data, 'countsByStatus') : 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.memo(IncidentStatusByMonth);
