import React, { useEffect, useImperativeHandle, useState } from 'react';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import IncidentService from '@api/IncidentService';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import _ from 'lodash';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import getOptionLabel from '@utils/getOptionLabel';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import { useSelector } from 'react-redux';
import { SettingsSelectors } from '@store/slices/settings';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number) => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'High Risk Completion Status',
      font: {
        size: 20,
      },
    },
  },
});

type HighRiskIncidentsCountByFacilityAndTypeHandle = {
  refresh: () => Promise<void>;
};

function HighRiskIncidentsCountByFacilityAndType({}, ref: React.Ref<HighRiskIncidentsCountByFacilityAndTypeHandle>) {
  const [data, setData] = useState<ChartData<'bar'>>();
  const [maxYValue, setMaxYValue] = useState<number>(0);
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);

  const fetchData = async () => {
    setData(undefined);
    try {
      const {data} = await IncidentService.getFacilitiesIncidentsByStatus(RiskLevel.HighRisk, selectedOrganization?.id, selectedYear);
      const flattenSum = data.map(d => _.sumBy(d.reports, 'count'));
      setMaxYValue(_.max(flattenSum) || 0);

      setData({
        labels: getFacilityLabels(data),
        datasets: Object.values(ReportStatus).map((status) => ({
          label: getOptionLabel(ReportStatusOptions, status),
          backgroundColor: ReportStatusColors[status],
          data: data.map((incident) => {
            const incidentsCount = incident.reports.find(r => r.status === status);
            return incidentsCount?.count ?? 0;
          }),
          stack: 'x',
          barPercentage: 0.4,
        })),
      });
    } catch (e) {
      console.log(e);
      setData({datasets: []});
    }

  };

  useEffect(() => {
    fetchData();
  }, [selectedOrganization, selectedYear]);

  useImperativeHandle(ref, () => ({
    refresh: fetchData,
  }));

  return (
    <ChartContainer loading={!data}>
      <Bar data={data!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.forwardRef(HighRiskIncidentsCountByFacilityAndType);
