import React from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { useDisableFormProvider } from '../providers/DisableFormProvider';
import TextFieldItem from './TextFieldItem';

type Props<TInputDate, TDate = TInputDate> = Omit<DatePickerProps<TInputDate, TDate>, 'renderInput'> & {
  error?: any
};

const DatePicker = React.forwardRef(<TInputDate, TDate = TInputDate>(props: Props<TInputDate, TDate>, ref: any) => {
  const { disabled } = useDisableFormProvider();

  return (
    <MuiDatePicker
      inputFormat="MM/DD/YYYY"
      {...props}
      value={props.value ?? null}
      renderInput={(params: any) => (
        <TextFieldItem
          fullWidth
          {...params}
          error={props.error || undefined}
          helperText={props.error?.message || ''}
          ref={ref}
        />
      )}
      disabled={disabled || props.disabled}
    />
  );
});

DatePicker.displayName = 'DatePicker';

export default DatePicker;
