import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

type GridContextType = {
  api: GridApiPro | null;
  stateName?: string;
};

const defaultContext: GridContextType = {
  api: null,
  stateName: undefined,
};

const GridContext = createContext(defaultContext);

export const useGridContext = () => useContext(GridContext);

type Props = {
  api: GridApiPro;
  stateName?: string;
};

function GridContextProvider(props: PropsWithChildren<Props>) {
  const { children, api, stateName } = props;

  const value = useMemo(() => ({
    api,
    stateName,
  }), [api, stateName]);

  return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
}

export default GridContextProvider;
