import React, { useCallback, useEffect } from 'react';
import useQuery from '@hooks/useQuery';
import AuthService from '@api/AuthService';
import Loader from '@components/Loader';
import routes from '@constants/routes';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, UserSelectors } from '@store/slices/user';

function Login() {
  const query = useQuery();
  const dispatch = useDispatch();
  const isAuthorized = useSelector(UserSelectors.isAuthorized);

  const authWithAzure = useCallback(() => {
    if (!query.get('accessToken') && !query.get('errorMessage')) {
      window.location.replace(
        AuthService.links.authWithAzure(window.location.origin),
      );
    }
  }, [query]);

  useEffect(() => {
    authWithAzure();
  }, [authWithAzure, query]);

  useEffect(() => {
    if (query.get('accessToken')) {
      const accessToken = query.get('accessToken') as string;
      const refreshToken = query.get('refreshToken') as string;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      dispatch(UserActions.setIsAuthorized(true));
    }
  }, [query, dispatch]);

  if (isAuthorized) {
    return <Navigate to={routes.dashboard} />;
  }

  return <Loader />;
}

export default Login;
