import React from 'react';

import { Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface Props {
  title: string;
  description?: string;
}

function EditableGridHeader({title}: Props) {
  return (
    <Tooltip className="MuiDataGrid-columnHeaderTitle" title={title || 'Editable Column'}>
      <div style={{
        fontWeight: 'var(--unstable_DataGrid-headWeight)',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        textOverflow: 'ellipsis', overflow: 'hidden',
      }}>
        <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{`${title}`}</span>
        <EditOutlinedIcon sx={{ml: 1, fontSize: 14, opacity: 0.5, mr: 1}}/>
      </div>
    </Tooltip>
  );
}

export default EditableGridHeader;
