import { AxiosInstance } from 'axios';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type MRRTotalByFacilities = {
  facilityId: number;
  facilityName: string;
  count: number;
};

function getCountsByFacilities(api: AxiosInstance) {
  return (riskLevel?: RiskLevel, organizationId?: number, yearOfMrrs?: number) => api.get<MRRTotalByFacilities[]>(`/counts-by-facilities`, {
    params: {risk_level: riskLevel, organization_id: organizationId, year_of_mrrs: yearOfMrrs},
  });
}

export default getCountsByFacilities;
