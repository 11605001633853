import { AxiosInstance } from 'axios';

export type LawsuitsCountsByMonth = {
  month: number; // 0-11
  count: number;
};

export default function getCountsByMonth(api: AxiosInstance) {
  return (facilityId: number, yearOfLawsuits?: number) => api.get<LawsuitsCountsByMonth[]>('/counts-by-month', {
    params: { facility_id: facilityId, year_of_lawsuits: yearOfLawsuits },
  });
}
