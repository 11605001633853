import { AxiosInstance } from 'axios';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type IncidentByFacilityAndStatus =
  {
    facilityId: number;
    facilityName: string;
    reports: {
      status: ReportStatus;
      count: number;
    }[]
  };

export default function getFacilitiesIncidentsByStatus(api: AxiosInstance) {
  return (riskLevel?: RiskLevel, organizationId?: number, yearOfIncidents?: number) => api.get<IncidentByFacilityAndStatus[]>('/by-facility-and-status', {
    params: {risk_level: riskLevel, organization_id: organizationId, year_of_incidents: yearOfIncidents},
  });
}
