import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js/dist/types';
import ChartContainer from '@components/charts/ChartContainer';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import getOptionLabel from '@utils/getOptionLabel';
import { LawsuitsCountsByLawsuitStatus } from '@api/LawsuitService/getCountsByLawsuitStatus';
import { LawsuitStatus, LawsuitStatusColors } from '@declarations/common/enums/LawsuitStatus';
import { LawsuitStatusOptions } from '@constants/options/LawsuitStatusOptions';

const options: ChartProps<'doughnut'>['options'] = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Lawsuits',
      font: {
        size: 20,
      },
    },
    legend: {
      display: true,
    },
  },
};

type LawsuitsByLawsuitStatusProps = {
  data: LawsuitsCountsByLawsuitStatus[] | null;
};

function LawsuitsByLawsuitStatus({ data }: LawsuitsByLawsuitStatusProps) {
  const [chartData, setChartData] = useState<ChartData<'doughnut'> | null>(null);

  useEffect(() => {
    if (data) {
      setChartData({
        labels: Object.values(LawsuitStatus).map((status) => getOptionLabel(LawsuitStatusOptions, status)),
        datasets: [{
          data: Object.values(LawsuitStatus).map((status) => {
            const found = data.find((d) => d.status === status);
            return found?.count || 0;
          }),
          backgroundColor: Object.values(LawsuitStatus).map((status) => LawsuitStatusColors[status]),
        }],
      });
    } else {
      setChartData(null)
    }
  }, [data]);

  return (
    <ChartContainer loading={!chartData}>
      <Doughnut data={chartData!} options={options} />
    </ChartContainer>
  );
}

export default LawsuitsByLawsuitStatus;
