import React from 'react';
import { getGridStringOperators, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import NoAccountsRoundedIcon from '@mui/icons-material/NoAccountsRounded';
import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';
import IdLink from '@components/IdLink';
import { User } from '@declarations/models/User';
import routes from '@constants/routes';
import { filterRequiredOperators } from '@components/DataGrid/utils/gridOperators';
import roleFormatter from '@components/DataGrid/renders/roleFormatter';
import expertRoleFormatter from '@components/DataGrid/renders/expertRoleFormatter';

const usersColumns: GridColDef<User>[] = [
  {
    field: 'firstname',
    align: 'left',
    headerName: 'First name',
    minWidth: 100,
    filterOperators: filterRequiredOperators(getGridStringOperators()),
    renderCell: (params: GridRenderCellParams<User, string>) => (
      <IdLink
        id={params.id as number}
        href={routes.userForm}
        value={(
          <Box display="flex" alignItems="center" gap={1}>
            {params.row.firstname}
            {
              params.row.disabled && (
                <Tooltip title="Disabled user">
                  <NoAccountsRoundedIcon fontSize="small" color="error" />
                </Tooltip>
              )
            }
          </Box>
        )}
      />
    ),
  },
  {
    field: 'lastname',
    align: 'left',
    headerName: 'Last name',
    minWidth: 100,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'position',
    align: 'left',
    headerName: 'Position',
    minWidth: 100,
    filterOperators: filterRequiredOperators(getGridStringOperators())
  },
  {
    field: 'organizations',
    align: 'left',
    headerName: 'Organization',
    minWidth: 140,
    renderCell: (params: GridRenderCellParams<User, string[]>) => params.value?.join(', '),
  },
  {
    field: 'facilities',
    align: 'left',
    headerName: 'Facility',
    minWidth: 140,
    renderCell: (params: GridRenderCellParams<User, string[]>) => params.value?.join(', '),
  },
  {
    field: 'preferredContactNumber',
    align: 'left',
    headerName: 'Phone',
    minWidth: 100,
  },
  {
    field: 'email',
    align: 'left',
    headerName: 'Email',
    minWidth: 180,
  },
  {
    field: 'additionalInfo',
    align: 'left',
    headerName: 'Additional Info',
    width: 100,
    sortable: false,
  },
  {
    field: 'type',
    align: 'left',
    headerName: 'Role',
    width: 140,
    valueFormatter: roleFormatter,
  },
  {
    field: 'expertRole',
    align: 'left',
    headerName: 'Expert Role',
    width: 140,
    valueFormatter: expertRoleFormatter,
  },
];

export default usersColumns;
