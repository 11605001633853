import React from 'react';
import { Stack } from '@mui/material';
import StatBox from '@components/StatBox';
import { LawsuitTotalsByFacilities } from '@api/LawsuitService/getTotalsByFacilities';

export type LawsuitsTotalChipsProps = {
  totals: LawsuitTotalsByFacilities;
}

function LawsuitsTotalChips({totals}: LawsuitsTotalChipsProps) {
  return (
    <Stack direction="row" spacing={2} sx={{marginBottom: 5}}>
      <StatBox
        title="Total Lawsuits"
        loading={Number.isNaN(totals.lawsuit)}
        count={totals.lawsuit}
      />
      <StatBox
        title="Lawsuits Incomplete"
        loading={Number.isNaN(totals.incomplete)}
        count={totals.incomplete}
      />
      <StatBox
        title="Closed Lawsuits"
        loading={Number.isNaN(totals.closed)}
        count={totals.closed}
      />
    </Stack>
  );
}

export default React.memo(LawsuitsTotalChips);