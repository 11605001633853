import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import { yesNoOptions } from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.FallMajorInjury], undefined>;

function getFallMajorInjuryFields(params: Params): {
  fields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = (field: keyof RequiredFields) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.FallMajorInjury}.${field}`;
  };

  const makeCondition = ({ form }: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.FallMajorInjury;
    }

    return form.watch('typeOfIncident') === IncidentType.FallMajorInjury;
  };

  return {
    fields: [
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasEventWitnessed'),
          label: 'Was the event witnessed?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didEncounterWhileStaffAssistance'),
          label: 'Did the event occur while staff were providing assistance?',
          options: yesNoOptions,
          condition: (conditionParams) => makeCondition(conditionParams)
            && conditionParams.form.watch(getFieldName('wasEventWitnessed') as any) === true,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasAssistPerPlanWithFunctionalEquipment'),
          label: 'Was assistance provided per the resident\'s plan of care utilizing assistive equipment in good operating order?',
          options: yesNoOptions,
          condition: (conditionParams) => makeCondition(conditionParams)
            && conditionParams.form.watch(getFieldName('wasEventWitnessed') as any) === true
            && conditionParams.form.watch(getFieldName('didEncounterWhileStaffAssistance') as any) === true,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wereAppropriateInterventionsUtilized'),
          label: 'Were appropriate interventions utilized and/or in place per the resident\'s plan of care?',
          options: yesNoOptions,
          condition: (conditionParams) => makeCondition(conditionParams)
            && (
              conditionParams.form.watch(getFieldName('wasEventWitnessed') as any) === false
              || (
                conditionParams.form.watch(getFieldName('wasEventWitnessed') as any) === true
                && conditionParams.form.watch(getFieldName('didEncounterWhileStaffAssistance') as any) === false
              )
            ),
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didTheResidentRequireHospitalization'),
          label: 'Did the resident require hospitalization?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('didEventIncidentResultInPermanentInjuryOrDeath'),
          label: 'Did the event/incident result in permanent injury or death?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
        },
      ],
    ]
  };
}

export default getFallMajorInjuryFields;
