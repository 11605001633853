import { AxiosInstance } from 'axios';
import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type MRRRiskLevelCountsByRequester = {
  requester: RequestedMedicalRecordsPerson;
  countsByRiskLevel: {
    [R in RiskLevel]?: number;
  };
};

export default function getMRRRiskLevelByRequester(api: AxiosInstance) {
  return (facilityId: number, yearOfMrrs?: number) => api.get<MRRRiskLevelCountsByRequester[]>('/risk-level-by-requester', {
    params: { facility_id: facilityId, year_of_mrrs: yearOfMrrs },
  });
}
