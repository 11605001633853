import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { UserType } from '@declarations/common/enums/UserType';
import {
  abuserOptions, AbuseType, abuseTypes, yesNoOptions,
} from '../../../const';

type Params = {
  name?: string;
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.AbuseOrNeglect], undefined>;

function getAbuseOrNeglectFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const calculatedStatus = params.form.getValues('calculatedStatus');
  const disabledField = calculatedStatus === ReportStatus.Completed && params.userType !== UserType.SuperAdmin;

  const getFieldName = <T extends keyof RequiredFields>(field: T) => {
    const prefix = params?.name ? `${params.name}.` : '';
    return `${prefix}${IncidentType.AbuseOrNeglect}.${field}` as T;
  };

  const makeCondition = ({form}: any) => {
    if (params && 'name' in params) {
      return form.watch('typeOfIncident') === params.name
        && form.watch(`${params.name}.causeOfUnplannedDeath`) === IncidentType.AbuseOrNeglect;
    }

    return form.watch('typeOfIncident') === IncidentType.AbuseOrNeglect;
  };

  return {
    fields: [
      [
        {
          type: 'select',
          fullWidth: true,
          name: getFieldName('typeOfAbuseOrNeglectIsAlleged'),
          label: 'What type of Abuse or Neglect is alleged?',
          options: abuseTypes,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: {maxWidth: 292},
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'select',
          fullWidth: true,
          name: getFieldName('whoIsAllegedToHaveAbusedOrNeglectedAResident'),
          label: 'Who is alleged to have abused or neglected a resident?',
          options: abuserOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: {maxWidth: 292},
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('hasAllegationSubstantiatedByInvestigation'),
          label: 'Has the allegation been substantiated by an internal investigation or an outside regulatory body?',
          options: yesNoOptions,
          condition: makeCondition,
          props: {
            inputLabel: false,
            sx: {maxWidth: 292},
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasIncidentCauseSignificantMentalAnguish'),
          label: 'Did the event/incident result in significant mental anguish?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            // Abuser type is include in this
            result = result && [AbuseType.VerbalPsychological, AbuseType.Misappropriation]
              .includes(
                conditionParams.form.watch(getFieldName('typeOfAbuseOrNeglectIsAlleged')),
              );

            return result;
          },
          props: {
            inputLabel: false,
            sx: {maxWidth: 292},
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasIncidentResultedInSeriousHarmNeedingHospital'),
          label: 'Did the event/incident result in significant mental anguish or serious bodily harm that required hospitalization?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            // Abuser type is include in this
            result = result && [AbuseType.Physical, AbuseType.Sexual, AbuseType.NeglectIsolation]
              .includes(
                conditionParams.form.watch(getFieldName('typeOfAbuseOrNeglectIsAlleged')),
              );

            return result;
          },
          props: {
            inputLabel: false,
            sx: {maxWidth: 292},
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          fullWidth: true,
          name: getFieldName('wasIncidentResultedInUnplannedDeath'),
          label: 'Did the event result in an unplanned death?',
          options: yesNoOptions,
          condition: (conditionParams) => {
            let result = makeCondition(conditionParams);

            const abuseType = conditionParams.form.watch(getFieldName('typeOfAbuseOrNeglectIsAlleged'));

            result = result && [AbuseType.Physical, AbuseType.Sexual, AbuseType.NeglectIsolation]
              .includes(abuseType);

            // just for hospitalization
            if ([AbuseType.Sexual, AbuseType.NeglectIsolation].includes(abuseType)) {
              result = result && conditionParams.form.watch(
                getFieldName('wasIncidentResultedInSeriousHarmNeedingHospital'),
              ) === true;
            }

            return result;
          },
          props: {
            inputLabel: false,
            sx: {maxWidth: 292},
            disabled: disabledField,
          },
        },
      ],
    ],
    fileFields: [],
  };
}

export default getAbuseOrNeglectFields;
