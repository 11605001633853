import { Option } from '@declarations/common/option';
import { Incident } from '@declarations/models/reports/Incident';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { DEFAULT_BIRTHDAY } from '@constants/index';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import IncidentTypeOptions from '@constants/options/IncidentTypeOptions';
import { UserType } from '@declarations/common/enums/UserType';
import { likeOptions, sexOptions, yesNoOptions } from '../../const';
import getFieldsByIncidentType from './incidentType';
import isHighRisk from '../../../utils/isHighRisk';
import MedicalRRService from '@api/MedicalRRService';
import LawsuitService from '@api/LawsuitService';
import { RequestingPersonLabels } from '@constants/options/RequestingPersonOptions';
import { RequestedMedicalRecordsPerson } from '@declarations/common/enums/RequestedMedicalRecordsPerson';

type GenerateFieldParams = {
  facilities: Option[];
  userType: UserType;
};

function generateFields({ facilities, userType }: GenerateFieldParams) {
  return (
    form: UseFormReturn<Incident>,
    mode: FormMode,
  ): Fields<Incident> => {
    const incidentTypeFields = getFieldsByIncidentType(form, mode, userType);
    const calculatedStatus = form.getValues('calculatedStatus');
    const disabledField = calculatedStatus === ReportStatus.Completed && userType !== UserType.SuperAdmin;
    const disabledLinkedReports = !form.watch('facility') || !form.watch('residentMedicalRecordNumber');

    const fields = [
      [
        {
          type: 'select',
          required: true,
          name: 'facility',
          label: 'Facility',
          options: facilities,
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          required: true,
          name: 'residentMedicalRecordNumber',
          label: 'Resident Medical Record Number',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'selectWithDialog',
          name: 'linkedReports.medicalReport',
          label: 'Link Medical Record Request',
          props: {
            disabled: disabledField || disabledLinkedReports,
            id: form.watch('id'),
            reportType: 'incident',
            facility: form.watch('facility'),
            residentMedicalRecordNumber: form.watch('residentMedicalRecordNumber'),
            linkPrefix: '/medical-record-request-form/?id=',
            dateField: 'requestDate',
            getReports: MedicalRRService.getRelations,
            getAdditionalField: (report: any) => RequestingPersonLabels[report.requestedRecordsPerson as RequestedMedicalRecordsPerson],
            onChange: (e: any) => {
              if (e) {
                form.setValue('residentFirstName', e.residentFirstName);
                form.setValue('residentLastName', e.residentLastName);
                form.setValue('residentBirthdate', e.residentBirthdate);
                form.setValue('residentGender', e.residentGender);
                form.setValue('linkedReports.medicalReport', {
                  id: e.id,
                  reportNumber: e.reportNumber,
                  requestDate: e.requestDate,
                });
                if (e.linkedReports?.lawsuit) {
                  form.setValue('linkedReports.lawsuit', {
                    id: e.linkedReports.lawsuit.id,
                    reportNumber: e.linkedReports.lawsuit.reportNumber,
                    noticeDate: e.linkedReports.lawsuit.noticeDate,
                  });
                }
              } else {
                form.setValue('linkedReports.medicalReport', '');
              }
            },
          },
        },
        {
          type: 'selectWithDialog',
          name: 'linkedReports.lawsuit',
          label: 'Link Lawsuit',
          props: {
            disabled: disabledField || disabledLinkedReports,
            id: form.watch('id'),
            reportType: 'incident',
            facility: form.watch('facility'),
            residentMedicalRecordNumber: form.watch('residentMedicalRecordNumber'),
            linkPrefix: '/lawsuit-form/?id=',
            dateField: 'noticeDate',
            getReports: LawsuitService.getRelations,
            getAdditionalField: (report: any) => report.caseName,
            onChange: (e: any) => {
              if (e) {
                form.setValue('residentFirstName', e.residentFirstName);
                form.setValue('residentLastName', e.residentLastName);
                form.setValue('residentBirthdate', e.residentBirthdate);
                form.setValue('residentGender', e.residentGender);
                form.setValue('linkedReports.lawsuit', {
                  id: e.id,
                  reportNumber: e.reportNumber,
                  noticeDate: e.noticeDate,
                });
                if (e.linkedReports?.medicalReport) {
                  form.setValue('linkedReports.medicalReport', {
                    id: e.linkedReports.medicalReport.id,
                    reportNumber: e.linkedReports.medicalReport.reportNumber,
                    requestDate: e.linkedReports.medicalReport.requestDate,
                  });
                }
              } else {
                form.setValue('linkedReports.lawsuit', '');
              }
            },
          },
        },
      ],
      [
        {
          type: 'text',
          required: true,
          name: 'residentFirstName',
          label: 'Resident First Name',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'text',
          required: true,
          name: 'residentLastName',
          label: 'Resident Last Name',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'residentBirthdate',
          label: 'Resident Date of Birth',
          props: {
            defaultCalendarMonth: DEFAULT_BIRTHDAY,
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          required: true,
          name: 'residentGender',
          label: 'Resident sex',
          options: sexOptions,
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'radio',
          required: true,
          name: 'isCurrentResident',
          label: 'Current Resident?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'admitDate',
          label: 'Admit Date',
          props: {
            disabled: disabledField,
          },
        },
        {
          type: 'date',
          required: false,
          name: 'dischargeDate',
          label: 'Discharge Date',
          condition: () => form.watch('isCurrentResident') === false,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'date',
          required: true,
          name: 'incidentDate',
          label: 'Date of Incident',
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          name: 'isReportedToStateAgency',
          label: 'Reported to State Agency?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          name: 'isReportedToPolice',
          label: 'Reported to Police/Law Enforcement?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'radio',
          name: 'isReportedToFamily',
          label: 'Reported to Family/POA/Guardian?',
          options: yesNoOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      [
        {
          type: 'select',
          required: true,
          name: 'typeOfIncident',
          label: 'Type of incident',
          options: IncidentTypeOptions,
          props: {
            disabled: disabledField,
          },
        },
      ],
      ...incidentTypeFields.fields,
      [
        {
          fullWidth: true,
          props: {
            inputLabel: false,
            sx: { maxWidth: 292 },
            disabled: disabledField,
          },
          type: 'select',
          name: 'chanceOfLegalRepresentation',
          label: 'Based on the circumstances surrounding the incident and the resident and family dynamics, how likely is it that this resident or family gets legal representation?',
          options: likeOptions,
        },
      ],
    ];

    const fileFields = [
      ...incidentTypeFields.fileFields,
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'incidentReport',
          label: 'Incident Report',
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'witnessStatement',
          label: 'Witness Statement',
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'copyOfPrior72HourNursesNotes',
          label: '72HR Nurses Notes',
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'mostRecentProgressNote',
          label: 'Recent Physician H&P Progress Note',
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
      [
        {
          fullWidth: true,
          type: 'file',
          name: 'mostRecentCarePlanFiles',
          label: 'Most Recent Care Plan',
          hidden: mode === FormMode.Create || !isHighRisk(form.watch('riskPoints')),
        },
      ],
    ];

    return (fields as any).concat(fileFields);
  };
}

export default generateFields;
