import React from 'react';
import clsx from 'clsx';
import { GridRow as MuiGridRow, GridRowProps } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

const CustomizedGridRow = styled(MuiGridRow)(
  ({ theme }) => ({
    '&.tree-row': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  }),
);

const isThreeChild = (row: GridRowProps['row']) => {
  if (!row) {
    return false;
  }

  if (typeof row.id === 'string') {
    return row.id.split('/').length > 1;
  }

  return false;
};

function GridRow(props: GridRowProps) {
  const { row, className } = props;
  let redefinedClassName;

  if (row && isThreeChild(row)) {
    redefinedClassName = clsx(className, 'tree-row');
  }

  return <CustomizedGridRow {...props} className={redefinedClassName} />;
}

export default GridRow;
