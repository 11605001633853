import { AxiosInstance } from 'axios';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';

export type LawsuitsCountsByStatus = {
  status: ReportStatus;
  count: number;
};

export default function getCountsByStatus(api: AxiosInstance) {
  return (facilityId: number, yearOfLawsuits?: number) => api.get<LawsuitsCountsByStatus[]>('/counts-by-facility-and-status', {
    params: { facility_id: facilityId, year_of_lawsuits: yearOfLawsuits },
  });
}
