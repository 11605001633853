import React, { memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export type LinkedReportsModalProps = {
  open: boolean;
  reportType?: string;
  onResult?: (result: boolean) => void;
};

const LinkedReportsModal = ({ open, reportType, onResult }: LinkedReportsModalProps) => {
  const handleSave = () => {
    onResult && onResult(true);
  };

  const handleBack = () => {
    onResult && onResult(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{reportType}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          There are {reportType} found that might have a link to the current report. Are you sure
          these reports are not related?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack}>BACK</Button>
        <Button onClick={handleSave} autoFocus>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(LinkedReportsModal);
