import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Box, TextField, Typography, FormLabel, Paper, FormControl,
} from '@mui/material';
import axiosInstance from '../../api/axios';
import './SignForm.css';
import UserService from '../../api/userService';

function Forgot() {
  const userRef = useRef();
  const errRef = useRef();

  const [email, setUser] = useState('');

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email);
    try {
      const response = await UserService.forgotPassword(email);
      console.log(response.data);
      console.log(response.accessToken);
      setSuccess(true);
      localStorage.setItem('passwordResetToken', response.data);
      navigate('/');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response.data === 'Not verified!') {
        navigate('/thanks');
      } else if (err.response?.status === 400) {
        console.log(err.response);
        setErrMsg('Username Taken');
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="middle-form">
      <section>
        <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
          {errMsg}
        </p>
        <form className="centerMyForm" onSubmit={handleSubmit}>
          <Paper sx={{ padding: 4, justifyContent: 'center', alignitems: 'center' }}>
            <Typography
              variant="h5"
              color="textSecondary"
              component="h2"
              align="center"
              gutterBottom
            >
              <h1>Forgot Password</h1>
            </Typography>

            <FormControl variant="filled">
              <FormLabel htmlFor="email">Email:</FormLabel>
              <Box>
                <TextField
                  type="text"
                  id="email"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  required
                  aria-describedby="uidnote"
                />
                <p id="uidnote" />
              </Box>
              <Box>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </FormControl>
          </Paper>
        </form>
      </section>
    </div>
  );
}

export default Forgot;
