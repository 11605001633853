export enum UserType {
  SuperAdmin = 'SUPER_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  General = 'GENERAL',
  FacilityUser = 'FACILITY_USER',
  External = 'EXTERNAL',
}

export const UserTypeName = {
  [UserType.SuperAdmin]: 'Super Admin',
  [UserType.OrganizationAdmin]: 'Organizational User',
  [UserType.General]: 'Regional User',
  [UserType.FacilityUser]: 'Facility User',
  [UserType.External]: 'Expert User',
}