import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import { AxiosInstance } from 'axios';

export type MRRRiskLevelCountsByMonth = {
  month: number; // 0-11
  countsByRiskLevel: {
    [R in RiskLevel]?: number;
  };
};

function getMRRRiskLevelByMonth(api: AxiosInstance) {
  return (facilityId: number, yearOfMrrs?: number ) => api.get<MRRRiskLevelCountsByMonth[]>('/risk-level-by-month', {
    params: { facility_id: facilityId, year_of_mrrs: yearOfMrrs },
  });
}

export default getMRRRiskLevelByMonth;
