import { MedicalRecordRequest } from '@declarations/models/reports/MedicalRecordRequest';
import moment from 'moment';
import {
  object, date, string,
} from 'yup';
import Errors from '@constants/locale/errors';
import baseReportValidationSchema from '../../../utils/validation-schemas/baseReportValidationSchema';

// TODO: add SchemaOf<MedicalRecordRequest> for all fields
const medicalRecordFormValidationSchema = object({
  ...baseReportValidationSchema,

  admitDate: date()
    .nullable()
    .test({
      message: 'The Admission date can not be after the Medical Record Request date',
      test: (value, context) => {
        const parent = context.parent as MedicalRecordRequest;

        if (!parent.requestDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrBefore(parent.requestDate, 'day');
      },
    }).typeError(Errors.VALID_DATE),

  noticeDate: date()
    .test({
      message: 'The Medical Record Request date can not be prior to the Admission date',
      test: (value, context) => {
        const parent = context.parent as MedicalRecordRequest;

        if (!parent.admitDate) {
          return true;
        }

        if (!value) {
          return true;
        }

        return moment(value).isSameOrAfter(parent.admitDate, 'day');
      },
    }).typeError(Errors.VALID_DATE),

  dischargeDate: date()
    .when('isCurrentResident', (isCurrentResident, schema) => !isCurrentResident ? schema.required(Errors.REQUIRED_FIELD) : schema.nullable())
    .transform(v => (v instanceof Date && !isNaN(v as any) ? v : null))
    .typeError(Errors.VALID_DATE),

  releasedPursuantDate: date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v as any) ? v : null)),

  requestedRecordsPerson: string().required(Errors.REQUIRED_FIELD),
});

export default medicalRecordFormValidationSchema;
