import React, { ChangeEvent } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import moment from 'moment';
import { Typography } from '@mui/material';
import Checkbox from '@components/Checkbox';
import MultipleSelect from '../select/MultipleSelect';
import SingleSelect from '../select/SingleSelect';
import TextFieldItem from '../TextFieldItem';
import DatePicker from '../DatePicker';
import { FormField } from './declarations/FormField';
import RadioComponent from '../RadioComponent';
import UploadComponent from '../filesUpload/UploadComponent';
import SelectWithDialog from './SelectWithDialog';
import Dropzone from '@components/filesUpload/Dropzone';

type Props<FormValues extends FieldValues = any> = {
  field: FormField<FormValues>
  form: UseFormReturn<FormValues>,
};

const getError = <FormValues extends FieldValues = any>(
  form: UseFormReturn<FormValues>,
  field: FormField<FormValues>,
) => {
  if (field.name && form.formState.errors[field.name]) {
    return form.formState.errors[field.name];
  }

  if (field.required) {
    const value = form.watch(field.name as any);

    return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
  }

  return undefined;
};

function Field<FormValues extends FieldValues = any>({ form, field }: Props<FormValues>) {
  if ('condition' in field && field.condition?.({ form }) === false) {
    return null;
  }

  if (field.hidden) {
    return null;
  }

  if (field.type === 'label') {
    return <Typography variant="subtitle2">{field.label}</Typography>;
  }

  if (field.type === 'text') {
    return (
      <TextFieldItem
        fullWidth
        label={field.label}
        error={getError(form, field)}
        required={field.required || false}
        type={field.format || 'text'}
        value={form.watch(field.name as any) || ''}
        onChange={(e) => form.setValue(field.name as any, e.target.value as any)}
        {...field.props || {}}
      />
    );
  }

  if (field.type === 'select') {
    if (field.multiple) {
      return (
        <MultipleSelect
          label={field.label}
          options={field.options}
          value={form.watch(field.name as any)}
          required={field.required || false}
          error={getError(form, field)}
          {...form.register(field.name as any)}
          {...field.props || {}}
        />
      );
    }

    return (
      <SingleSelect
        label={field.label}
        options={field.options}
        required={field.required || false}
        value={form.watch(field.name as any)}
        error={getError(form, field)}
        {...form.register(field.name as any)}
        {...field.props || {}}
      />
    );
  }

  if (field.type === 'selectWithDialog') {
    return (<SelectWithDialog
      label={field.label}
      value={form.watch(field.name as any)}
      onChange={(e) => form.setValue(field.name as any, e.target.value as any)}
      {...field.props || {}}
    />);
  }

  if (field.type === 'date') {
    const register = form.register(field.name as any);

    return (
      <DatePicker
        label={field.label}
        required={field.required || false}
        value={form.watch(field.name as any)}
        onChange={(value) => form.setValue(field.name as any, moment(value as any).toDate() as any)}
        error={getError(form, field)}
        {...field.props || {}}
      />
    );
  }

  if (field.type === 'radio') {
    const register = form.register(field.name as any);

    return (
      <RadioComponent
        label={field.label}
        required={field.required || false}
        options={field.options}
        value={form.watch(field.name as any)}
        error={getError(form, field)}
        onChange={(name, value) => form.setValue(field.name as any, value as any)}
        {...field.props || {}}
      />
    );
  }

  if (field.type === 'file') {
    const uploadHandler = (event: ChangeEvent<any>) => {
      const files: File[] = Array.from(event.target.files);
      const currentFiles: any[] = form.watch(field.name as any) || [];

      form.setValue(field.name as any, files.concat(currentFiles) as any);
    };

    const removeFile = (filename: string, fileHash?: string, isUploadedFile?: boolean) => {
      form.setValue(
        field.name as any,
        (form.watch(field.name as any) as any).filter((file: File) => file.name !== filename || (file as any).hash !== fileHash),
      );
      if (isUploadedFile) {
        form.setValue(
          'files' as any,
          (form.watch('files' as any) as any).filter((file: any) => file.file.hash !== fileHash),
        );
      }
    };

    return (
      <UploadComponent
        label={field.label}
        required={field.required || false}
        uploadHandler={uploadHandler}
        fileUploadIdentifier={field.name}
        files={form.watch(field.name as any)}
        removeFile={removeFile}
        {...field.props || {}}
      />
    );
  }

  if (field.type === 'dropzone') {
    const register = form.register(field.name as any);

    return (
      <Dropzone field={field} form={form} {...field.props || {}} />
    );
  }

  if (field.type === 'checkbox') {
    const register = form.register(field.name as any);

    return (
      <Checkbox
        label={field.label}
        required={field.required || false}
        checked={form.watch(field.name as any)}
        onChange={(event) => form.setValue(field.name as any, event.target.checked as any)}
        {...field.props || {}}
      />
    );
  }

  return null;
}

export default Field;
