import React, { useState, memo } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import moment from 'moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDisableFormProvider } from '../../providers/DisableFormProvider';
import './SelectWithDialog.css'


type Props = {
  label: string;
  id?: number;
  reportType: string;
  facility: string;
  residentMedicalRecordNumber: string;
  disabled?: boolean;
  value: any;
  linkPrefix: string;
  dateField: string;
  hintText?: string;
  getReports: (facilityId: string, residentMedicalRecordNumber: string) => Promise<any>;
  onChange: (e: SelectChangeEvent) => void;
  getAdditionalField: (report: any) => string;
};

const ExternalLink = ({link}: {link: string}) => (
  <a href={link} target="_blank" onClick={e => e.stopPropagation()} style={{display: 'flex', pointerEvents: 'auto'}}>
    <Tooltip title="Open in a new tab">
      <OpenInNewIcon />
    </Tooltip>
  </a>
);

const HelpIcon = () => (
  <Tooltip title="To choose this option please unlink it">
    <HelpOutlineIcon sx={{color: '#262626', marginLeft: '5px'}} />
  </Tooltip>
);

function SelectWithDialog({
  label,
  id,
  reportType,
  value,
  facility,
  residentMedicalRecordNumber,
  linkPrefix,
  dateField,
  disabled,
  hintText,
  getReports,
  onChange,
  getAdditionalField,
}: Props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const { disabled: disabledFromProvider } = useDisableFormProvider();

  const loadOptions = () => {
    setLoading(true);
    getReports(facility, residentMedicalRecordNumber).then(res => {
      setReports(res.data);
    }).finally(() => setLoading(false));
  };

  const handleClickOpen = () => {
    if (disabled || disabledFromProvider) {
      return;
    }
    setOpen(true);
    setSelectedId(value?.id || '');
    loadOptions();
  };

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    setOpen(false);
  };
  
  const handleOk = () => {
    const report = reports.find(r => r.id === Number(selectedId));
    onChange(report as any);
    setOpen(false);
  }

  const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedId(value);
  }

  const renderOptions = () => {
    const options = reports.map(r => {
      const disabledOption = r.linkedReports?.[reportType]?.id && id !== r.linkedReports[reportType].id;

      const optionLabel = (
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '15px', width: '470px'}}>
          <div style={{display: 'flex'}}>
            <div style={{display: 'flex', minWidth: '150px'}}>
              <span>{r.reportNumber}</span>
              {disabledOption && <HelpIcon />}
            </div>
            <div style={{minWidth: '150px', maxWidth: '140px', marginRight: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              {getAdditionalField(r)}
            </div>
            <span style={{color: '#8c8c8c'}}>{moment(r[dateField]).format('M/D/YYYY')}</span>
          </div>
          <ExternalLink link={linkPrefix + r.id} />
        </div>
      );
   
      return (
        <FormControlLabel
          value={r.id}
          disabled={disabledOption}
          key={r.reportNumber}
          control={<Radio />}
          label={optionLabel} />
      );
    });

    options.unshift(<FormControlLabel
      key="None"
      value={''}
      control={<Radio />}
      label="None" />);

    return options;
  }

  const showTooltip = !disabledFromProvider && (!facility || !residentMedicalRecordNumber || Boolean(hintText));
  const displayedHintText = hintText || 'Please fill out Facility and Resident Medical Record Number to link the report.';
  
  const displayedLabel = showTooltip
    ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span>{label}</span>
        <Tooltip title={displayedHintText}>
          <HelpOutlineIcon />
        </Tooltip>
      </Box>
    )
    : label;

  const displayedValue = !value ? '' : `${value.reportNumber} ${moment(value[dateField])?.format('M/D/YYYY')}`;

  const endAdornment = !value ? null : <ExternalLink link={linkPrefix + value.id} />;

  const handleReload = () => {
    loadOptions();
  }

  return <div style={{width: '100%'}}>
    <TextField id="outlined-basic" label={displayedLabel} 
      fullWidth
      variant="outlined"
      onClick={handleClickOpen}
      value={displayedValue}
      disabled={disabled || disabledFromProvider}
      className="select-with-dialog-input"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: true,
        endAdornment: endAdornment,
      }}
    />
    <Dialog disableEscapeKeyDown open={open}>
      {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '510px', minHeight: '200px' }}>
        <CircularProgress />
      </Box>}
      {!loading && <DialogContent>
        <Box component="form" sx={{ display: 'flex', minWidth: '510px', minHeight: '200px' }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={selectedId}
              onChange={handleOnchange}
            >
              {renderOptions()}
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>}
      <DialogActions sx={{justifyContent: 'space-between'}}>
        <Button onClick={handleReload}>REFRESH</Button>
        <Box>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleOk}>OK</Button>
        </Box>
      </DialogActions>
    </Dialog>
  </div>
}

export default memo(SelectWithDialog);
