import { OrganizationType } from '@declarations/common/enums/OrganizationType';
import { Gender } from '../declarations/common/enums/Gender';
import { PrimaryComplain } from '../declarations/common/enums/PrimaryComplain';
import { RequestedMedicalRecordsPerson } from '../declarations/common/enums/RequestedMedicalRecordsPerson';

export const organizationTypes = [
  {
    value: OrganizationType.HealthcareProvider,
    label: 'Healthcare provider',
  },
  {
    value: OrganizationType.InsuranceProvider,
    label: 'Insurance provider',
  },
];

export const providerTypes = [
  {
    value: 'SKILLED_NURSING',
    label: 'Skilled Nursing',
  },
  {
    value: 'ASSISTED_LIVING',
    label: 'Assisted Living',
  },
  {
    value: 'CCRC',
    label: 'CCRC',
  },
  {
    value: 'INDEPENDENT_LIVING',
    label: 'Independent Living',
  },
  {
    value: 'MEMORY_CARE',
    label: 'Memory Care / Alzheimer’s Specialty',
  },
];

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const yesNoUnknownOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
  { label: 'Unknown', value: 2 },
];

export const sexOptions = [
  { label: 'Male', value: Gender.Male },
  { label: 'Female', value: Gender.Female },
  { label: 'Other', value: Gender.Other },
];

export const primaryComplaintETCOptions = [
  { label: 'Billing/Financial', value: PrimaryComplain.BillingFinancial },
  { label: 'Abuse/Neglect', value: PrimaryComplain.AbuseNeglect },
  { label: 'Injury/Accident', value: PrimaryComplain.InjuryAccident },
  { label: 'Service/Quality of Care', value: PrimaryComplain.ServiceQualityOfCare },
];

export enum UnplannedDeathType {
  FallMajorInjury = 'fallMajorInjury',
  Wound = 'wound',
  MedicationError = 'medicationError',
  AbuseOrNeglect = 'abuseOrNeglect',
  Elopement = 'elopement',
  Choking = 'choking',
  Entrapment = 'entrapment',
  Burns = 'burns',
  SelfInflictedInjury = 'selfInflictedInjury',
  RapidHealthDecline = 'rapidHealthDecline',
}

export const unplannedDeathTypes = [
  { label: 'Fall w/ Major Injury', value: UnplannedDeathType.FallMajorInjury },
  { label: 'Wound', value: UnplannedDeathType.Wound },
  { label: 'Medication Error', value: UnplannedDeathType.MedicationError },
  { label: 'Abuse / Neglect', value: UnplannedDeathType.AbuseOrNeglect },
  { label: 'Elopement', value: UnplannedDeathType.Elopement },
  { label: 'Choking', value: UnplannedDeathType.Choking },
  { label: 'Entrapment', value: UnplannedDeathType.Entrapment },
  { label: 'Burns', value: UnplannedDeathType.Burns },
  { label: 'Self-Inflicted Injury', value: UnplannedDeathType.SelfInflictedInjury },
  { label: 'Rapid Health Decline', value: UnplannedDeathType.RapidHealthDecline },
];

export enum AbuseType {
  VerbalPsychological = 'VERBAL_PSYCHOLOGICAL',
  Physical = 'PHYSICAL',
  Sexual = 'SEXUAL',
  NeglectIsolation = 'NEGLECT_ISOLATION',
  Misappropriation = 'MISAPPROPRIATION',
}

export const abuseTypes = [
  { label: 'Verbal/Psychological', value: AbuseType.VerbalPsychological },
  { label: 'Physical', value: AbuseType.Physical },
  { label: 'Sexual', value: AbuseType.Sexual },
  { label: 'Neglect/Isolation', value: AbuseType.NeglectIsolation },
  { label: 'Misappropriation', value: AbuseType.Misappropriation },
];

export enum Abuser {
  StaffVisitorOther = 'STAFF_VISITOR_OTHER',
  ResidentToResident = 'RESIDENT_TO_RESIDENT',
  FamilyMember = 'FAMILY_MEMBER',
}

export const abuserOptions = [
  { label: 'Staff/Visitor/Other', value: Abuser.StaffVisitorOther },
  { label: 'Resident-to-Resident', value: Abuser.ResidentToResident },
  { label: 'Family Member', value: Abuser.FamilyMember },
];

export enum SourceBurn {
  ShowerBath = 'SHOWER_BATH',
  FireSmoke = 'FIRE_SMOKE',
  Electrical = 'ELECTRICAL',
  HotSurface = 'HOT_SURFACE',
  HotLiquid = 'HOT_LIQUID',
}

export const burnSources = [
  { label: 'Shower/Bath', value: SourceBurn.ShowerBath },
  { label: 'Fire/Smoke', value: SourceBurn.FireSmoke },
  { label: 'Electrical', value: SourceBurn.Electrical },
  { label: 'Hot Surface (hot plate, steam table, iron, etc) ', value: SourceBurn.HotSurface },
  { label: 'Hot Liquid (Coffee, Tea, etc.)', value: SourceBurn.HotLiquid },
];

export enum LikeEnum {
  VeryUnlikely = 'VERY_UNLIKELY',
  Unlikely = 'UNLIKELY',
  Neutral = 'NEUTRAL',
  Likely = 'LIKELY',
  VeryLikely = 'VERY_LIKELY',
}

export const likeOptions = [
  { label: 'Very Likely', value: LikeEnum.VeryLikely },
  { label: 'Likely', value: LikeEnum.Likely },
  { label: 'Neutral', value: LikeEnum.Neutral },
  { label: 'Unlikely', value: LikeEnum.Unlikely },
  { label: 'Very Unlikely', value: LikeEnum.VeryUnlikely },
];
