import React from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

type Props = {
  loading?: boolean,
} & ButtonProps;

function Button({ loading, children, ...props }: Props) {
  return (
    <MuiButton
      {...props}
      disabled={loading || props.disabled}
    >
      {
        loading
          ? <CircularProgress size={20} style={{ color: 'currentcolor' }} />
          : children
      }
    </MuiButton>
  );
}

export default Button;
