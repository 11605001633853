import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { AxiosInstance } from 'axios';

export type IncidentStatusCountsByMonth = {
  month: number; // 0-11
  countsByStatus: {
    [S in ReportStatus]?: number;
  };
};

function getStatusByMonth(api: AxiosInstance) {
  return (facilityId: number, yearOfIncidents?: number) => api.get<IncidentStatusCountsByMonth[]>('/status-by-month', {
    params: { facility_id: facilityId, year_of_incidents: yearOfIncidents },
  });
}

export default getStatusByMonth;
