import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import initInstance from '../axios';
import { ReportType } from '@declarations/common/reportType';
import { AxiosResponse } from 'axios';

const api = initInstance('/expert-user');

type UpdateReportAssignments = {
  reportId: number;
  reportType: ReportType;
  expertUsers: number[];
  note?: string;
}

type NotReviewedReportsCount = {
  incidents: number;
  mrrs: number;
  lawsuits: number;
}

const ExpertUserService = {
  get: (filter?: string, expertRole?: ExpertRole) =>
    api.get<any[]>('', {
      params: { filter, role: expertRole },
    }),
  upsertReportAssignments: (updateReportAssignments: UpdateReportAssignments) =>
    api.post('/upsert-report-assignments', updateReportAssignments),
  notReviewedReportsCount: () => api.get<NotReviewedReportsCount>('/not-reviewed-reports-count'),
};

export default ExpertUserService;