import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { Incident, IncidentTypes } from '@declarations/models/reports/Incident';
import { UserType } from '@declarations/common/enums/UserType';
import { unplannedDeathTypes } from '../../../const';
import getAbuseOrNeglectFields from './abuseOrNeglect';
import getBurnsFields from './burns';
import getChokingFields from './choking';
import getElopementFields from './elopement';
import getEntrapmentFields from './entrapment';
import getFallMajorInjuryFields from './fallMajorInjury';
import getMedicationErrorFields from './medicationError';
import getRapidHealthDeclineFields from './rapidHealthDecline';
import getSelfInflictedInjuryFields from './selfInflictedInjury';
import getWoundFields from './wound';

type Params = {
  form: UseFormReturn<Incident>;
  mode: FormMode;
  userType: UserType;
};

type RequiredFields = Exclude<IncidentTypes[IncidentType.UnplannedDeath], undefined>;

function getUnplannedDeathFields(params: Params): {
  fields: Fields<RequiredFields>;
  fileFields: Fields<RequiredFields>;
} {
  const getFieldName = (field: keyof RequiredFields) => `${IncidentType.UnplannedDeath}.${field}`;
  const makeCondition = ({ form }: any) => form.watch('typeOfIncident') === IncidentType.UnplannedDeath;

  const fields: Fields<RequiredFields> = [[{
    type: 'select',
    fullWidth: true,
    name: getFieldName('causeOfUnplannedDeath'),
    label: 'What is the nature of incident or health condition that led to the unplanned death?',
    options: unplannedDeathTypes,
    condition: makeCondition,
    required: true,
    props: {inputLabel: false, sx: { maxWidth: 292 } },
  }]];
  const fileFields: Fields<RequiredFields> = [];

  const fieldParams = { ...params, name: IncidentType.UnplannedDeath };
  const fallMajorInjuryFields = getFallMajorInjuryFields(fieldParams);
  fields.push(...fallMajorInjuryFields.fields as any[]);

  const woundFields = getWoundFields(fieldParams);
  fields.push(...woundFields.fields as any[]);
  fileFields.push(...woundFields.fileFields as any[]);

  const medicationErrorFields = getMedicationErrorFields(fieldParams);
  fields.push(...medicationErrorFields.fields as any[]);
  fileFields.push(...medicationErrorFields.fileFields as any[]);

  const abuseOrNeglectFields = getAbuseOrNeglectFields(fieldParams);
  fields.push(...abuseOrNeglectFields.fields as any[]);
  fileFields.push(...abuseOrNeglectFields.fileFields as any[]);

  const elopementFields = getElopementFields(fieldParams);
  fields.push(...elopementFields.fields as any[]);
  fileFields.push(...elopementFields.fileFields as any[]);

  const chokingFields = getChokingFields(fieldParams);
  fields.push(...chokingFields.fields as any[]);
  fileFields.push(...chokingFields.fileFields as any[]);

  const entrapmentFields = getEntrapmentFields(fieldParams);
  fields.push(...entrapmentFields.fields as any[]);
  fileFields.push(...entrapmentFields.fileFields as any[]);

  const burnsFields = getBurnsFields(fieldParams);
  fields.push(...burnsFields.fields as any[]);
  fileFields.push(...burnsFields.fileFields as any[]);

  const selfInflictedInjuryFields = getSelfInflictedInjuryFields(fieldParams);
  fields.push(...selfInflictedInjuryFields.fields as any[]);
  fileFields.push(...selfInflictedInjuryFields.fileFields as any[]);

  fields.push(...getRapidHealthDeclineFields(fieldParams) as any[]);

  return {
    fields,
    fileFields,
  };
}

export default getUnplannedDeathFields;
