import EditableGridHeader from '@components/EditableGridHeader';
import { UserType } from '@declarations/common/enums/UserType';
import { Tooltip } from '@mui/material';
import React from 'react';

const expertUserHeaderRender = (inlineEditEnabled: boolean) => (params: any) => {
  let title = '';
  if (params.field === 'clinicalReviewer') {
    title = 'Clinical Reviewer';
  }
  if (params.field === 'legalReviewer') {
    title = 'Legal Reviewer';
  }
  return inlineEditEnabled
    ? <EditableGridHeader title={title} description={title}/>
    : <Tooltip style={{textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'var(--unstable_DataGrid-headWeight)'}}
               className="MuiDataGrid-columnHeaderTitle" title={title}>
      <span>{title}</span>
    </Tooltip>;
};

export default expertUserHeaderRender;