import { AxiosInstance } from 'axios';
import { ReportTypeEnum } from '@declarations/common/reportType';

const path = '/totals';

export type Totals = {
  [ReportTypeEnum.Incident]: number;
  incidentHighRisk: number;
  [ReportTypeEnum.MedicalRR]: number;
  medicalHighRisk: number;
  [ReportTypeEnum.Lawsuit]: number;
  lawsuitOpen: number;
};

export default function getTotals(api: AxiosInstance) {
  return (organizationId?: number, yearOfReports?: number) => api.get<Totals>(path, {
    params: {organization_id: organizationId, year_of_reports: yearOfReports},
  });
}
