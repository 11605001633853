import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MedicalRRService from '@api/MedicalRRService';
import moment from 'moment';
import { toast } from 'react-toastify';
import CRUDForm from '@components/CRUDForm';
import { MedicalRecordRequest } from '@declarations/models/reports/MedicalRecordRequest';
import routes from '@constants/routes';
import { FormMode } from '@hooks/useFormEdit';
import { UseFormReturn } from 'react-hook-form';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { UserType } from '@declarations/common/enums/UserType';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import generateFields from './MedicalRRForm.fields';
import medicalRecordFormValidationSchema from './validationSchemas/medicalRRForm.validation-schema';
import isHighRisk from '../../utils/isHighRisk';
import useFacilityOptions from '@hooks/useFacilityOptions';
import ReportService from '@api/ReportService';
import { ReportTypeEnum } from '@declarations/common/reportType';
import LinkedReportsModal, { LinkedReportsModalProps } from '@components/modals/LinkedReportsModal';
import IncidentService from '@api/IncidentService';
import LawsuitService from '@api/LawsuitService';
import UserLogRecord from '@components/SubmissionTabs/UserLogRecord';
import RiskLevel from '@components/SubmissionTabs/RiskLevel';
import SubmissionTabs from '@components/SubmissionTabs/SubmissionTabs';
import HeaderBar from '@components/SubmissionTabs/HederBar';

function MedicalRRForm() {
  const userType = useSelector(UserSelectors.type);
  const navigate = useNavigate();
  const { facilities, selectedFacilityId } =  useFacilityOptions();
  const [dialogState, setDialogState] = useState<LinkedReportsModalProps>({open: false});

  const fields = useMemo(
    () => generateFields({ facilities, userType }),
    [facilities],
  );

  const defaultValues = useMemo(() => ({
    facility: selectedFacilityId,
    medicalRRCopyFiles: [],
    releasedPursuantFiles: [],
    ltcDocumentsFiles: [],
  }), [selectedFacilityId]);

  const onSubmit = async (
    data: MedicalRecordRequest | FormData,
    form: UseFormReturn<MedicalRecordRequest>,
  ) => {
    if (data instanceof FormData && !data.get('linkedReports[incident][id]')) {
      const relations = (await IncidentService.getRelations(data.get('facility') as string, data.get('residentMedicalRecordNumber') as string)).data;
      const availableRelations = relations?.filter(r => r.linkedReports?.medicalReport?.id !== Number(data.get('id')));
      if (availableRelations?.length) {
        const dialog = new Promise((resolve) => {
          setDialogState({
            open: true,
            reportType: 'Incidents',
            onResult: resolve
          });
        });
        const result = await dialog;
        setDialogState({open: false});
        if (!result) { return; }
      }
    }

    if (data instanceof FormData && !data.get('linkedReports[lawsuit][id]')) {
      const relations = (await LawsuitService.getRelations(data.get('facility') as string, data.get('residentMedicalRecordNumber') as string)).data;
      const availableRelations = relations?.filter(r => r.linkedReports?.medicalReport?.id !== Number(data.get('id')));
      if (availableRelations?.length) {
        const dialog = new Promise((resolve) => {
          setDialogState({
            open: true,
            reportType: 'Lawsuits',
            onResult: resolve
          });
        });
        const result = await dialog;
        setDialogState({open: false});
        if (!result) { return; }
      }
    }

    const { data: responseData } = await MedicalRRService.upsert(data);

    if (responseData.id && data instanceof FormData) {
      await ReportService.setFacilityReportsRelation({
        sourceReport: ReportTypeEnum.MedicalRR,
        incidentId: Number(data.get('linkedReports[incident][id]')) || null,
        lawsuitId: Number(data.get('linkedReports[lawsuit][id]')) || null,
        mrrId: responseData.id,
      });
    }

    if (data instanceof FormData) {
      const oldRiskPoints = Number(data.get('riskPoints'));
      const newRiskPoints = responseData.riskPoints;

      if (!isHighRisk(oldRiskPoints) && isHighRisk(newRiskPoints)) {
        navigate(`${routes.medicalRecordRequestForm}/?id=${responseData.id}&mode=${FormMode.Edit}`);
        toast.warn('The situation has a HIGH-risk level. Please provide additional info.');

        form.setValue('riskPoints', newRiskPoints);
      } else {
        navigate(routes.medicalRecordRequests);

        if (responseData.calculatedStatus === ReportStatus.Completed) {
          toast.success((
            <>
              Medical Record Request Report has been saved.
              <br />
              The report is now completed.
            </>
          ));
        } else {
          toast.warn((
            <>
              Medical Record Request Report has been saved.
              <br />
              Please provide all information to complete the report.
            </>
          ));
        }
      }
    }
  };

  return (
    <>
      <SubmissionTabs active="" prefix={routes.medicalRecordRequestForm} />
      <HeaderBar title="Facility User" description="Complete the following" />
      <CRUDForm
        useFormData
        defaultValues={defaultValues}
        validationSchema={medicalRecordFormValidationSchema}
        fetchEntityMethod={MedicalRRService.getById}
        renderTitle={(form, mode) => (
          mode === FormMode.Create
            ? <>New Medical Record Request Report</>
            : (
              <>
                Medical Record Request:
                {' '}
                {form.watch('reportNumber')}
                {' '}
                (
                {moment(form.watch('createdAt')).format('MM/DD/YYYY')}
                )
                {' '}
                <RiskLevel risk={form.watch('riskPoints')} />
              </>
            )
        )}
        onSubmit={onSubmit}
        fields={fields}
        backRoute={routes.medicalRecordRequests}
        editDisabled={(form) => moment(form.watch('createdAt')).add(14, 'days').isBefore(moment()) && userType !== UserType.SuperAdmin}
      />
      <UserLogRecord reportType={ReportTypeEnum.MedicalRR} userType={userType} />
      <LinkedReportsModal {...dialogState} />
    </>
  );
}

export default MedicalRRForm;
