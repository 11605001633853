import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useGridContext } from '../../providers/GridContext';
import { WidthNormal } from '@mui/icons-material';
import { GridColumnMenuItemProps } from '@mui/x-data-grid-pro';

function ColumnMenuAutosizeItem(props: GridColumnMenuItemProps) {
  const {api} = useGridContext();
  return (
    <MenuItem onClick={() => {
      api?.autosizeColumns({
        columns: [props.colDef.field],
        includeHeaders: true,
        includeOutliers: true,
      });
      api?.hideColumnMenu();
    }}>
      <ListItemIcon>
        <WidthNormal fontSize="small"/>
      </ListItemIcon>
      <ListItemText>Autosize column</ListItemText>
    </MenuItem>
  );
}

export default ColumnMenuAutosizeItem;
