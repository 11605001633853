import { useSelector } from 'react-redux';
import FacilityIncidentReports from './FacilityIncidentReports';
import TotalIncidentReports from './TotalIncidentReports';
import { SettingsSelectors } from '@store/slices/settings';
import { UserSelectors } from '@store/slices/user';
import { UserType } from '@declarations/common/enums/UserType';
import IncidentReportsGrid from './components/IncidentReportsGrid';

function IncidentReports() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const user = useSelector(UserSelectors.user);
  if (user.expertRole) {
    return <IncidentReportsGrid incidentReportGridKey={'facility-incidents'} gridFilterItem={undefined}/>
  }
  return facility ? <FacilityIncidentReports/> : <TotalIncidentReports/>;
}

export default IncidentReports;
