import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Stack } from '@mui/system';
import { Grid } from '@mui/material';
import Field from './Field';
import { FormField } from './declarations/FormField';

type Props<FormValues extends FieldValues = any> = {
  fields: FormField[]
  form: UseFormReturn<FormValues>
};

function FieldStack<FormValues extends FieldValues = any>({ fields, form }: Props<FormValues>) {
  if (fields.every((field) => 'condition' in field && field.condition?.({ form }) === false)) {
    return null;
  }

  return (
    <Grid item container direction="row" spacing={2}>
      {
        fields.map((field, idx: number) => {
          if (Array.isArray(field)) {
            return <FieldStack key={idx} fields={field} form={form} />;
          }

          if ('condition' in field && field.condition?.({ form }) === false) {
            return null;
          }

          if (field.hidden) {
            return null;
          }

          return (
            <Grid key={field.name} item xs={12} sm={field.fullWidth ? 12 : 6}>
              <Field field={field} form={form} />
            </Grid>
          );
        })
      }
    </Grid>
  );
}

export default FieldStack;
