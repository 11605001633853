import { styled } from '@mui/system';

type Props = {
  title: string;
  description?: string | JSX.Element;
  actions?: JSX.Element;
}

const Container = styled('div')`
  ${({ theme }) => `
    background-color: #1976d2;
    color: #FFF;
    padding: 16px 8px;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    & .title {
      font-weight: 700;
    }
  `}
`

export default function HeaderBar({ title, description, actions }: Props) {
  return (
    <Container>
      <div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      <div>
        {actions}
      </div>
    </Container>
  );
}