import { AxiosInstance } from 'axios';
import { IncidentType } from '@declarations/common/enums/IncidentType';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';

export type IncidentsByType = {
  type: IncidentType;
  count: number;
};

export default function getFacilitiesIncidentsByType(api: AxiosInstance) {
  return (riskLevel?: RiskLevel, organizationId?: number, yearOfIncidents?: number) => api.get<IncidentsByType[]>('/counts-by-type', {
    params: {risk_level: riskLevel, organization_id: organizationId, year_of_incidents: yearOfIncidents},
  });
}
