import React, { createContext, useContext, useMemo } from 'react';

const defaultValue = {
  disabled: false,
};

const Context = createContext(defaultValue);

interface Props {
  children: React.ReactNode
  disabled: boolean
}

export const useDisableFormProvider = () => useContext(Context);

function DisableFormProvider(props: Props) {
  const { children, disabled = false } = props;

  const value = useMemo(() => ({
    disabled,
  }), [disabled]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default DisableFormProvider;
