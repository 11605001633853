import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import ChartContainer from '@components/charts/ChartContainer';
import { Bar, ChartProps } from 'react-chartjs-2';
import getOptionLabel from '@utils/getOptionLabel';
import RiskLevelOptions from '@constants/options/RiskLeveOptions';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import { MRRStatusCountsByRiskLevel } from '@api/MedicalRRService/getMRRStatusByRiskLevel';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import calculateAxisMaxValue from '@utils/calculateAxisMaxValue';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'MRR Completion Status',
      font: {
        size: 20,
      },
    },
  },
});

type MRRStatusByRiskLevelProps = {
  data: MRRStatusCountsByRiskLevel[] | null;
};

function MRRStatusByRiskLevel({data}: MRRStatusByRiskLevelProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  useEffect(() => {
    if (data) {
      setChartData({
        labels: Object.values(RiskLevel).map((riskLevel) => getOptionLabel(RiskLevelOptions, riskLevel)),
        datasets: Object.values(ReportStatus).map((value) => ({
          label: getOptionLabel(ReportStatusOptions, value),
          data: data.map(({countsByStatus}) => countsByStatus[value] ?? 0),
          backgroundColor: ReportStatusColors[value],
          stack: 'x',
          barPercentage: 0.4,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const maxYValue: number = data ? calculateAxisMaxValue(data, 'countsByStatus') : 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default MRRStatusByRiskLevel;
