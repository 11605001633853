import { styled } from '@mui/material/styles';
import { Menu, MenuProps } from '@mui/material';

const MoreActionsList = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: theme.spacing(20),
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export default MoreActionsList;
