import { Bar, ChartProps } from 'react-chartjs-2';
import ChartContainer from '@components/charts/ChartContainer';
import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import { MedicalCountsByHighRiskRequesters } from '@api/MedicalRRService/getCountsByHighRiskRequesters';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import _ from 'lodash';
import { RequestingPersonOptions } from '@constants/options/RequestingPersonOptions';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'MRR Requested By',
      font: {
        size: 20,
      },
    },
    legend: {
      display: false,
    },
  },
});

type MRRHighRiskRequestersProps = {
  data: MedicalCountsByHighRiskRequesters[] | null;
};

function MRRHighRiskRequesters({data}: MRRHighRiskRequestersProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  useEffect(() => {
    if (data) {
      setChartData({
        labels: RequestingPersonOptions.map(ito => ito.label),
        datasets: [{
          data: RequestingPersonOptions.map(ito => {
            const requestingPersonOption = data.find(rpo => rpo.requester === ito.value);
            return requestingPersonOption?.count || 0;
          }),
          backgroundColor: '#197CB6',
          barPercentage: 0.4,
        }],
      });
    } else {
      setChartData(null);
    }
  }, [data]);

  const maxYValue: number = _.max(data?.map(d => d.count)) || 0;

  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxYValue)}/>
    </ChartContainer>
  );
}

export default React.memo(MRRHighRiskRequesters);
