import { AxiosInstance } from 'axios';
import { ReportTypeEnum } from '@declarations/common/reportType';

export type Totals = {
  [ReportTypeEnum.Incident]: number;
  incidentHighRisk: number;
  [ReportTypeEnum.MedicalRR]: number;
  medicalHighRisk: number;
  [ReportTypeEnum.Lawsuit]: number;
  lawsuitOpen: number;
};

function getFacilityTotals(api: AxiosInstance) {
  return (facilityId: number, yearOfReports?: number) => api.get<Totals>(`/totals/facility/${facilityId}`, {
    params: { year_of_reports: yearOfReports }
  });
}

export default getFacilityTotals;
