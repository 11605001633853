import { merge } from 'lodash';
import { Theme } from '@mui/material/styles';
import Button from './Button';
import CardContent from './CardContent';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import ListItemIcon from './ListItemIcon';
import OutlinedInput from './OutlinedInput';
import TableCell from './TableCell';
import FormLabel from './FormLabel';

export default function componentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    CardContent(),
    IconButton(theme),
    InputLabel(theme),
    ListItemIcon(),
    OutlinedInput(theme),
    TableCell(theme),
    FormLabel(theme),
  );
}
