import { useEffect, useState } from 'react';

export enum FormMode {
  View = 'view',
  Edit = 'edit',
  Create = 'create',
}

interface Props {
  defaultValues: object
  setFormValues: (form: any) => void
  func: (id: string) => any
}

interface OutProps {
  id: string | null
  mode: FormMode
  setMode: (newMode: FormMode) => void
  startEditHandle: () => void

  loading: boolean
  setLoading: (value: boolean) => void

}

export const useFormEdit = ({ defaultValues, setFormValues, func }: Props): OutProps => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const [mode, setMode] = useState(id !== null ? FormMode.View : FormMode.Create);
  const [loading, setLoading] = useState(!!id);

  const startEditHandle = () => {
    setMode(FormMode.Edit);
  };

  useEffect(() => {
    const func1 = async () => {
      if (id) {
        const responce = await func(id);
        setFormValues((responce.data)[0]);
        setLoading(false);
      }
    };
    func1();
  }, [id]);

  return {
    id, mode, setMode, startEditHandle, loading, setLoading,
  };
};
