import React, { useCallback, useEffect } from 'react';
import UserService from '@api/userService';
import { UserActions, UserSelectors } from '@store/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import routes from '../constants/routes';

function PrivateRoute() {
  const isAuthorized = useSelector(UserSelectors.isAuthorized);

  if (isAuthorized) {
    return <Outlet />;
  }

  return <Navigate to={routes.loginPage} />;
}

export default PrivateRoute;
