import React, { useEffect, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartData } from 'chart.js/dist/types';
import getOptionLabel from '@utils/getOptionLabel';
import ChartContainer from '@components/charts/ChartContainer';
import getAxisMaxValue from '@utils/getAxisMaxValue';
import { FacilitiesMMRSByStatus } from '@api/MedicalRRService/getMMRSByFacilityAndStatus';
import { ReportStatus, ReportStatusColors } from '@declarations/common/enums/ReportStatus';
import ReportStatusOptions from '@constants/options/ReportStatusOptions';
import _ from 'lodash';
import getFacilityLabels from '@utils/getFacilityLabels';

const options = (dataLength: number): ChartProps<'bar'>['options'] => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {stepSize: 1},
      max: getAxisMaxValue(dataLength),
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'MRR Completion Status',
      font: {
        size: 20,
      },
    },
  },
});

type MMRsCountByStatusProps = {
  data: FacilitiesMMRSByStatus[] | null;
};

function MMRsCountByStatus({data}: MMRsCountByStatusProps) {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  
  useEffect(() => {
    if (data) {
      setChartData({
        labels: getFacilityLabels(data),
        datasets: Object.values(ReportStatus).map((status) => ({
          label: getOptionLabel(ReportStatusOptions, status),
          data: data.map((mrr) => {
            const mrrCount = mrr.reports.find(r => r.status === status);
            return mrrCount?.count ?? 0;
          }),
          backgroundColor: ReportStatusColors[status],
          stack: 'x',
          barPercentage: 0.5,
        })),
      });
    } else {
      setChartData(null);
    }
  }, [data]);
  
  const flattenSum = data?.map(d => _.sumBy(d.reports, 'count'));
  const maxXValue: number = _.max(flattenSum) || 0;
  
  return (
    <ChartContainer loading={!chartData}>
      <Bar data={chartData!} options={options(maxXValue)}/>
    </ChartContainer>
  );
}

export default React.memo(MMRsCountByStatus);
