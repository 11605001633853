import React, { useState } from 'react';
import {
  Box, Modal, Stack, Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import { defaultModalStyle, minButtonWidth } from '../../consts';
import Button from '../Button';

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: () => void | Promise<void>
  title: string
  content: string
  disableConfirmationToast?: boolean
}

function RemoveRowsModal(props: Props) {
  const {
    isOpen, handleClose, handleConfirm, title, content, disableConfirmationToast,
  } = props;

  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);

      await handleConfirm();
      handleClose();

      if (!disableConfirmationToast) {
        toast.success('Action successful');
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 400 && error.response.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={defaultModalStyle}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" component="span">
            {content}
          </Typography>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </Button>

          <Button variant="outlined" color="error" onClick={onConfirm} sx={minButtonWidth} loading={loading}>
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default RemoveRowsModal;
