import React from 'react';

function Thanks() {
  return (
    <div>
      <p>Thanks for registration. Notification has been sent to LTC</p>
    </div>
  );
}
export default Thanks;
