import { AxiosInstance, AxiosResponse } from "axios";

export type LawsuitPossibleRelation = {
  id: number,
  noticeDate: Date,
  reportNumber: string,
  residentBirthdate: Date,
  residentGender: string,
  residentFirstName: string,
  residentLastName: string,
  isCurrentResident: boolean,
  linkedReports: {
    id: number,
    incident: { id: number, reportNumber: string, incidentDate: Date } | null,
    medicalReport: { id: number, reportNumber: string, requestDate: Date } | null,
  } | null,
};

export type CaseResponse = LawsuitPossibleRelation[];

const getRelations = (api: AxiosInstance) => (facilityId: string, residentMedicalRecordNumber: string): Promise<AxiosResponse<CaseResponse>> => {
  const path = `/get-possible-relations?facility_id=${facilityId}&medical_record_number=${residentMedicalRecordNumber}`;
  return api.get<CaseResponse>(path);
}

export default getRelations;