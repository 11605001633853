import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Box,
  TextField,
  Container,
  Typography,
  FormLabel,
  Paper,
  FormControl,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import './SignForm.css';
import UserService from '../../api/userService';

function NewPass() {
  const userRef = useRef();
  const errRef = useRef();

  const [confirmPassword, setConfirm] = useState('');

  const [password, setPwd] = useState('');

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const query = getQueryVariable('token');
        const response = UserService.register(query);
        console.log(response.data);
        console.log(response.accessToken);
        setSuccess(true);
      } catch (err) {
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          console.log(err.response);
          setErrMsg(err.response.data);
          return <div />;
        }
      }
    }
    fetchData();
  }, []);
  // TODO: токен в локал стораж и сравнение
  const navigate = useNavigate();
  // useEffect(()=>{
  //     const result =
  // })
  const handleSubmit = async (e) => {
    e.preventDefault();
    const query = getQueryVariable('token');
    try {
      const response = await userService.resetPassword(query, password, confirmPassword);
      console.log(response.data);
      console.log(response.accessToken);
      setSuccess(true);

      navigate('/login');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        console.log(err.response);
        setErrMsg(err.response.data);
      }
      errRef.current.focus();
    }
  };

  return (
    <Container>
      <div className="middle-form">
        <section>
          <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
            {errMsg}
          </p>

          <form className="centerMyForm" onSubmit={handleSubmit}>
            <Paper sx={{ padding: 4, justifyContent: 'center', alignitems: 'center' }}>
              <Typography
                variant="h5"
                color="textSecondary"
                component="h2"
                align="center"
                gutterBottom
              >
                <h1>Password recovery</h1>
              </Typography>
              <FormControl variant="filled">
                <FormLabel htmlFor="password">Password:</FormLabel>
                <Box>
                  <TextField
                    type="password"
                    id="password1"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setPwd(e.target.value)}
                    required
                    aria-describedby="uidnote"
                  />
                  <p id="uidnote" />
                </Box>
                <FormLabel htmlFor="password">Confirm Password:</FormLabel>
                <Box>
                  <TextField
                    className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                    type="password"
                    id="password"
                    onChange={(e) => setConfirm(e.target.value)}
                    autoComplete="off"
                    required
                    aria-describedby="pwddnote"
                  />
                  <p id="pwdMatchdnote" />
                </Box>
                <Box>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </FormControl>
            </Paper>
          </form>
        </section>
      </div>
    </Container>
  );
}

export default NewPass;
