import React from 'react';
import { Container, Link as MuiLink } from '@mui/material';
import { config } from '../../config';
import brandLogo from '../../assets/images/LTC-Risk-brand-no-background.png';

const BrandLogoBox: () => JSX.Element = () => (
  <MuiLink
    referrerPolicy="same-origin"
    width='90%'
    target="_blank"
    rel="noopener noreferrer"
    href={config.landingURL}
    sx={{minHeight: '100px', display: 'flex', overflow: 'hidden', alignItems: 'center'}}
  >
    <Container component="img" disableGutters src={brandLogo} alt="LTC Risk & Management Logo" />
  </MuiLink>
);

export default React.memo(BrandLogoBox);
