import moment from 'moment';
import RiskLevel from './RiskLevel';
import PageTitle from '@components/page/PageTitle';
import { Skeleton } from '@mui/material';

type Props = {
  name: string;
  reportNumber: string;
  createdAt: string;
  riskPoints: number;
  loading?: boolean;
};

export default function ReportHeader({
  name,
  reportNumber,
  createdAt,
  riskPoints,
  loading,
}: Props) {
  const text = loading ? (
    <Skeleton variant="text" width="100%" />
  ) : (
    <>
      {name}: {reportNumber} ({moment(createdAt).format('MM/DD/YYYY')}){' '}
      <RiskLevel risk={riskPoints} />
    </>
  );

  return <PageTitle mt={2}>{text}</PageTitle>;
}
