import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, useGridModels } from '@components/DataGrid';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import routes from '../../constants/routes';
import PageTitle from '../../components/page/PageTitle';
import { DefaultRowsPerPageOptions } from '../../consts';
import { organizationsColumns } from './organizationsColumns';
import OrganizationService from '../../api/organizationService';
import useGridData from '@components/DataGrid/hooks/useGridData';
import TableToolbar from '../../components/TableToolbar';

function OrganizationsPage() {
  const { hasAccess: hasSuperAdminAccess } = useAccess({ types: [UserType.SuperAdmin] });
  const gridModels = useGridModels();
  const { data, getData, loading } = useGridData({
    fetchMethod: OrganizationService.list,
    gridModels,
  });

  const onRemove = async () => {
    const ids = gridModels.rowSelectionModel as number[];
    await OrganizationService.remove(ids);
    gridModels.setPage(0);
    gridModels.setSelection([]);

    await getData();
  };

  return (
    <>
      <PageTitle>Organizations</PageTitle>

      {
        hasSuperAdminAccess && (
          <TableToolbar
            onRemove={onRemove}
            showRemove={gridModels.getSelectionCount() > 0}
            removalModalTitle="Are you sure you want to delete selected organizations?"
            removalModalContent={`You selected ${gridModels.getSelectionCount()} organization(s) to delete.`}
            exportCSVFile={{
              fileName: () => `organizations-${new Date().toLocaleDateString('en-US')}.csv`,
              fetchMethod: OrganizationService.exportCSV,
              fetchParams: gridModels.getFetchListParams(),
            }}
          >
            <Button component={Link} variant="contained" to={routes.organizationForm}>
              New Organization
            </Button>
          </TableToolbar>
        )
      }

      <DataGrid
        loading={loading}
        rows={data}
        columns={organizationsColumns}
        checkboxSelection={hasSuperAdminAccess}
        stateName="organizations"
        {...gridModels}
      />
    </>
  );
}

export default OrganizationsPage;
