import { AxiosInstance } from 'axios';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import { LawsuitStatus } from '@declarations/common/enums/LawsuitStatus';

export type FacilitiesLawsuitsByLawsuitStatus =
  {
    facilityId: number;
    facilityName: string;
    reports: {
      status: LawsuitStatus;
      count: number;
    }[]
  };

export default function getFacilitiesLawsuitsCountsByLawsuitStatus(api: AxiosInstance) {
  return (organizationId?: number, yearOfLawsuits?: number) => api.get<FacilitiesLawsuitsByLawsuitStatus[]>('/facilities-counts-by-lawsuit-status', {
    params: {organization_id: organizationId, year_of_lawsuits: yearOfLawsuits},
  });
}
