import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { User } from '@declarations/models/User';
import { AxiosResponse } from 'axios';

export default function setLogRecordExpertUsers(res: AxiosResponse<any[]>): AxiosResponse<any[]> {
  res.data = res.data?.map((item: any) => {
    const clinicalReviewer = item.reviewersSnapshot.find(
      (u: User) => u.expertRole === ExpertRole.ClinicalReviewer,
    );
    item.clinicalReviewer = clinicalReviewer;
    const legalReviewer = item.reviewersSnapshot.find(
      (u: User) => u.expertRole === ExpertRole.LegalReviewer,
    );
    item.legalReviewer = legalReviewer;
    return item;
  });

  return res;
}
