import React, { useEffect, useImperativeHandle, useState } from 'react';
import sumBy from 'lodash/sumBy';
import { ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import IncidentService from '@api/IncidentService';
import { RiskLevel } from '@declarations/common/enums/RiskLevel';
import getOptionLabel from '@utils/getOptionLabel';
import IncidentTypeOptions, { IncidentTypeColors } from '@constants/options/IncidentTypeOptions';
import ChartContainer from '@components/charts/ChartContainer';
import { useSelector } from 'react-redux';
import { SettingsSelectors } from '@store/slices/settings';

const options = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'High Risk Incident Type',
      font: {
        size: 20,
      },
    },
    tooltip: {
      callbacks: {
        label: (context: any) => ` ${context.parsed}%`,
      },
    },
    legend: {
      display: true,
    },
  },
};

type IncidentsCountByTypeHandle = {
  refresh: () => Promise<void>;
};

function IncidentsCountByType({}, ref: React.Ref<IncidentsCountByTypeHandle>) {
  const [data, setData] = useState<ChartData<'doughnut'>>();
  const selectedOrganization = useSelector(SettingsSelectors.selectedOrganization);
  const selectedYear = useSelector(SettingsSelectors.selectedYearNumber);
  const fetchData = async () => {
    setData(undefined);
    try {
      const response = await IncidentService.getFacilitiesIncidentsByType(RiskLevel.HighRisk, selectedOrganization?.id, selectedYear);
      const totalRecords = sumBy(response.data, 'count');
      setData({
        labels: IncidentTypeOptions.map(itc => itc.label),
        datasets: [{
          data: IncidentTypeOptions.map(({value}) => {
            const count = response.data.find(d => d.type === value)?.count;
            return count ? Number((count / totalRecords * 100).toFixed(2)) : 0;
          }),
          backgroundColor: Object.values(IncidentTypeColors),
        }],
      });
    } catch (e) {
      setData({datasets: []});
      console.error(e);
    }
  };

  useImperativeHandle(ref, () => ({
    refresh: fetchData,
  }));

  useEffect(() => {
    fetchData();
  }, [selectedOrganization, selectedYear]);

  return (
    <ChartContainer loading={!data}>
      <Doughnut data={data!} options={options}/>
    </ChartContainer>
  );
}

export default React.forwardRef(IncidentsCountByType);
