import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack, Typography } from '@mui/material';
import { UploadedFile } from '@declarations/common/uploadedFile';
import FileItem from './FileItem';

interface Props {
  files: (File | UploadedFile)[];
  removeFile: (filename: string, fileHash?: string, isUploadedFile?: boolean) => void;
  disabled: boolean;
}
interface FileWithHash extends File {
  hash: string;
}

function FileList(props: Props) {
  const { files, removeFile, disabled } = props;

  const filesWithHash: (FileWithHash | UploadedFile)[] = useMemo(() => (files || []).map((file) => {
    if (file instanceof File) {
      return Object.assign(file, {hash: uuidv4()});
    }
    return file;
  }), [files]);

  if (!filesWithHash || filesWithHash.length === 0) {
    return (
      <Typography variant="body1" color="gray" align="left">
        No files selected.
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      {filesWithHash.map((f) => (
        <FileItem key={f.hash} file={f} removeFile={removeFile} disabled={disabled} />
      ))}
    </Stack>
  );
}

export default FileList;
