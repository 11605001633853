import { useCallback, useState } from 'react';
import { DataGridProProps, GridRowSelectionModel } from '@mui/x-data-grid-pro';

type UseSelectionModelReturnType = Pick<DataGridProProps, 'rowSelectionModel' | 'onRowSelectionModelChange'> & {
  setSelection: (selection: GridRowSelectionModel) => void;
  getSelectionCount: () => number;
};

export function useSelectionModel(initialState?: GridRowSelectionModel): UseSelectionModelReturnType {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(initialState ?? []);

  const getSelectionCount = useCallback(() => {
    if (Array.isArray(selectionModel)) {
      return selectionModel.length;
    }

    return ['string', 'number'].includes(typeof selectionModel) ? 1 : 0;
  }, [selectionModel]);

  return {
    rowSelectionModel: selectionModel,
    onRowSelectionModelChange: (selection: GridRowSelectionModel) => setSelectionModel(selection),
    setSelection: (selection: GridRowSelectionModel) => setSelectionModel(selection),
    getSelectionCount,
  };
}
