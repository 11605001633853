import IdLink from '@components/IdLink';
import { Tooltip } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import LinkIcon from '@mui/icons-material/Link';

type Params<T> = {
  href: string;
  getId: (row: T) => number;
  getDate: (row: T) => string;
  getReportNumber: (row: T) => string;
  getAdditionalField?: (row: T) => string;
};

function linkedReportRender<T extends GridValidRowModel>({
  href,
  getId,
  getDate,
  getReportNumber,
  getAdditionalField,
}: Params<T>) {
  return ({ row }: GridRenderCellParams<T>) => {
    const reportNumber = getReportNumber(row);
    if (!reportNumber) {
      return null;
    }
    const reportDate = new Date(getDate(row)).toLocaleDateString('en-US');
    const title = getAdditionalField
      ? `${reportNumber}, ${getAdditionalField(row)}, ${reportDate}`
      : `${reportNumber}, ${reportDate}`
    const value = (
      <Tooltip title={title}>
        <LinkIcon />
      </Tooltip>
    );
    return <IdLink id={getId(row)} href={href} value={value} />;
  };
}

export default linkedReportRender;
