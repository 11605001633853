import React, { ReactNode } from 'react';
import {
  Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectProps,
} from '@mui/material';
import { useDisableFormProvider } from '../../providers/DisableFormProvider';

type Option = {
  label: string
  value: string
};

type Props = SelectProps & {
  options: Option[]
  value: string[]
};

const MultipleSelect = React.forwardRef(({ value = [], ...props }: Props, ref) => {
  const { disabled } = useDisableFormProvider();
  const renderValue = (selected: string[]) => selected
    .map((value) => props.options.find((option) => option.value === value)?.label)
    .join(', ');

  return (
    <FormControl fullWidth>
      <InputLabel shrink required={props.required} error={!!props.error}>{props.label}</InputLabel>

      <Select
        multiple
        input={<OutlinedInput label={props.label} />}
        value={value}
        renderValue={renderValue as any}
        {...props}
        ref={ref as any}
        disabled={disabled}
      >
        {
          props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value?.some?.((v) => v === option.value)} />

              <ListItemText primary={option.label} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
});

MultipleSelect.displayName = 'MultipleSelect';

export default MultipleSelect;
