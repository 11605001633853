import { AxiosInstance } from 'axios';

export type MRRTotalsByFacilities = {
  medical: number;
  incomplete: number;
  highRisk: number;
};

function getTotalsByFacilities(api: AxiosInstance) {
  return (organizationId?: number, yearOfMrrs?: number) => api.get<MRRTotalsByFacilities>(`/totals-by-facilities`, {
    params: {organization_id: organizationId, year_of_mrrs: yearOfMrrs},
  });
}

export default getTotalsByFacilities;
